// @flow
import type { PUZZLE_TYPES_TYPE } from '../constants';
import type { TGTAAnswer } from '../puzzles/gta_puzzles';
import type { AnswerUnion, GameSettings } from './game_settings';

import { PUZZLE_TYPES } from '../constants';
import { answers } from '../puzzles/gtac_puzzles';
import { GTASettings } from './gta_settings';

export class GTACSettings extends GTASettings {
    title: string = 'Guess The Album Cover';
    puzzle_start_date: Date = new Date('7/23/2024');
    clipboard_tag: string = '#GuessTheAlbumCover';

    max_guesses: number = 5;
    puzzle_type: PUZZLE_TYPES_TYPE = PUZZLE_TYPES.GTAC;
    root_url: string = '/' + this.puzzle_type;
    storage_keys: { guess: string, game_state: string } = {
        guess: this.puzzle_type + '_guess',
        game_state: this.puzzle_type + '_gamestate',
    };
    answers: AnswerUnion = answers;
    game_url(puzzleId: string): string {
        return `https://${this.website_url}${GTACSettings.PATHS.guess_the_cover.replace(':puzzleId?', puzzleId)}`;
    }
    num_days_from_start_date(): number {
        const today = new Date();
        // $FlowIgnore
        const diffTime = Math.abs(today - this.puzzle_start_date);
        return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    }

    get_hint_text(imageIndex: number, puzzle: TGTAAnswer, settings: GameSettings): string {
        return '';
    }

    progress_ranks: {} = {};

    puzzle_url(): string {
        return GTACSettings.PATHS.guess_the_cover;
    }
    guess_item = 'album';

    TEXTS: any = {
        modal: {
            AboutModal: {
                modalTexts: [
                    `Every day a new ${this.guess_item.toLowerCase()} cover is selected and your job is to guess what it is.`,
                    'We try to have a selection from different genres and eras. We also try to have a mix that are well known and more obscure.',
                ],
            },
            HowToPlayModal: {
                modalTexts: [
                    'You are presented with a cover of an album. Try to guess what it is!',
                    'If you get it wrong or click skip, we will reveal an additional snippet of the cover.',
                    `You get ${this.max_guesses} guesses in total.`,
                ],
            },
        },
    };
}
