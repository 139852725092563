// @flow

import { goToDay } from '../clientUtils';

import { useDispatch, useSelector } from 'react-redux';
import React, { useMemo } from 'react';
import Countdown from 'react-countdown';
import Socials from './Socials';
import { EMAIL, FACEBOOK_URL, INSTAGRAM_URL, TWITTER_URL, YOUTUBE_URL } from '../constants';
import { useTranslation } from 'react-i18next';
import { useSettings } from '../settings/useSettings';
import type { GameSettings } from '../settings/game_settings';
import type { PUZZLE_TYPES_TYPE } from '../constants';
import { setModal } from '../store/slices/uxSlice';
import { DEV_MODE } from '../globals';
import type { State } from '../store/types';
import type { TDispatch } from '../store/store';
import { gameHasBeenPlayed } from '../puzzleUtils';

type TCountdownRender = {
    hours: number,
    minutes: number,
    seconds: number,
    completed: boolean,
};
const countdownRenderer =
    (settings: GameSettings) =>
    ({ hours, minutes, seconds, completed }: TCountdownRender) => {
        if (completed) {
            window.location.assign(settings.paths.puzzle.replace(':puzzleId', ''));
        } else {
            return (
                <span className="countdown-to-next-game">
                    {hours <= 9 ? `${hours}` : hours}h {minutes <= 9 ? `${minutes}` : minutes}m{' '}
                    {seconds <= 9 ? `${seconds}` : seconds}s
                </span>
            );
        }
    };

const Footer = ({
    currentPage,
    puzzleId,
    puzzleType,
}: {
    currentPage: string,
    puzzleId: string,
    puzzleType: PUZZLE_TYPES_TYPE,
}): React$Element<'div'> => {
    const dispatch: TDispatch = useDispatch();
    const { t } = useTranslation();
    const today = new Date();
    const tomorrow = new Date(today);
    const settings = useSettings();

    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);

    const goPreviousDay = () => goToDay(Number(puzzleId) - 1, currentPage, settings);
    const goNextDay = () => goToDay(Number(puzzleId) + 1, currentPage, settings);

    const showInterAdFlag = useSelector((state: State) => state.ux.showInterAd);
    const showInterAd = () => {
        dispatch(setModal('inter-ad'));
        window.globalThis.interAdNextFunc = goNextDay;
    };

    const [showPreviousButton, showNextButton] = useMemo(() => {
        if (DEV_MODE || currentPage !== 'original_images') {
            return [true, true];
        }
        const showPreviousButton = gameHasBeenPlayed(Number(puzzleId) - 1, settings);
        const nextPuzzleId = Number(puzzleId) + 1;
        const showNextButton =
            gameHasBeenPlayed(nextPuzzleId, settings) && nextPuzzleId <= settings.num_days_from_start_date();
        return [showPreviousButton, showNextButton];
    }, [puzzleId, settings, currentPage]);

    return (
        <div className="footer" style={{ marginTop: '10px' }}>
            <div
                className={'countdownControls'}
                style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}
            >
                <button
                    type="button"
                    onClick={goPreviousDay}
                    className="mainButton iconButton"
                    style={{ visibility: showPreviousButton ? 'visible' : 'hidden' }}
                >
                    <span aria-hidden="true" className="icon-backward2"></span>
                </button>
                <div className={'countdown-label'}>
                    <p className="countdown-label">{t('New Game In')}:</p>
                    <Countdown
                        className="countdown-to-next-game"
                        date={tomorrow}
                        renderer={countdownRenderer(settings)}
                    />
                </div>{' '}
                <button
                    type="button"
                    onClick={() => {
                        if (showInterAdFlag) {
                            showInterAd();
                        } else {
                            goNextDay();
                        }
                    }}
                    className="mainButton iconButton"
                    style={{
                        // visibility: showNextButton ? 'visible' : 'hidden',
                        visibility:
                            !DEV_MODE && Number(puzzleId) + 1 > settings.num_days_from_start_date()
                                ? 'hidden'
                                : 'visible',
                    }}
                >
                    <span aria-hidden="true" className="icon-forward3"></span>
                </button>
            </div>
            <Socials
                instagram_url={INSTAGRAM_URL}
                twitter_url={TWITTER_URL}
                facebook_url={FACEBOOK_URL}
                youtube_url={YOUTUBE_URL}
            />

            <p className="countdown-label" style={{ marginTop: '20px' }}>
                Check out our sister sites: <a href={'https://guesstheaudio.com'}>Guess The Audio</a>,{' '}
                <a href={'https://guessthebook.app'}>Guess The Book</a>,{' '}
                <a href={'https://guessthe.game'}>Guess The Game</a>
                {Math.random() > 0.8 && (
                    <>
                        , <a href={'https://guessthelogo.wtf'}>Guess The Logo</a>{' '}
                    </>
                )}{' '}
            </p>
            <br />
            {/* show below if between xam EST */}
            {today.getUTCHours() >= 0 && today.getUTCHours() < 9 && (
                <p className={'countdown-label'}>
                    Had issues with today's puzzle? Contact us via{' '}
                    <a
                        href={`mailto:${EMAIL}?subject=I%20had%20issues%20with%20puzzle%20%23${puzzleId}%20on%20${settings.website_url}`}
                    >
                        Email
                    </a>
                </p>
            )}

            <p style={{ fontSize: '10px', color: 'white' }}>
                Affiliate disclosure: Links may include an affiliate link. We may earn commissions from qualifying
                purchases from sites like amazon.com, greenmangaming.com, etc. This comes at no extra cost to our users.
                You are not obligated to click on any link or buy any products that are advertised. Per FTC guidelines,
                this website may be compensated by companies mentioned through advertising, affiliate programs,
                sponsored posts or otherwise. Apple and Apple Music are trademarks of Apple Inc., registered in the U.S.
                and other countries We do not host any mp3, aac, wav, or audio files. Because of this, daily puzzles may
                have their snippets replaced, changed or all together removed.
            </p>
            <div>
                {/*// HTML for geo depending button for freestar*/}
                <button className={'privacyButton'} id="pmLink">
                    Privacy Manager
                </button>
                <a href={'https://api.guessthe.game/api/privacy-policy'}>
                    <button className={'privacyButton'} id={'privacyPolicy'}>
                        Privacy Policy
                    </button>
                </a>
            </div>
        </div>
    );
};

export default Footer;
