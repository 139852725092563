// @flow

import PreviousPuzzles from '../components/PreviousPuzzles';
import GTMPuzzle from '../components/Puzzle/GTMPuzzle';
import Setting from '../components/Setting';
import Stats from '../components/Stats';
import { type GameSettings } from './game_settings';
import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import ResetPuzzle from '../components/ResetPuzzle';
import { GTG_COMMUNITY_SUBMISSION_URL, PUZZLE_TYPES } from '../constants';
import type { PUZZLE_TYPES_TYPE } from '../constants';
import type { AnswerUnion } from './game_settings';
import { answers } from '../puzzles/gtm_puzzles';
import type { TGTMAnswer } from '../puzzles/gtm_puzzles';
import { BaseSettings } from './BaseSettings';
import GTCPuzzle from '../components/Puzzle/GTCPuzzle';

export class GTMSettings extends BaseSettings {
    static PATHS: {
        email_callback: string,
        guess_the_cover: string,
        original_images: string,
        previous_games: string,
        puzzle: string,
        reset: string,
        setting: string,
        stats: string,
        test_affiliate: string,
        ...
    } = {
        puzzle: '/p/:puzzleId',
        previous_games: '/previous-games',
        reset: '/reset/:puzzleId/:puzzleType', // reset/4/GTM or /reset/4/GTMc
        stats: '/stats/:puzzleId',
        email_callback: '/email_callback/',
        original_images: '/ogi/:puzzleId',
        guess_the_cover: '/gtmc/:puzzleId?',
        setting: '/setting',
        test_affiliate: '/test_affiliate',
    };

    routes(): React$Element<React$FragmentType> {
        return (
            <>
                <Route element={<GTMPuzzle />} path="/" />
                <Route path={GTMSettings.PATHS.puzzle} element={<GTMPuzzle />} />
                <Route path={GTMSettings.PATHS.stats} element={<Stats />} />
                <Route path={GTMSettings.PATHS.previous_games} element={<PreviousPuzzles />} />
                <Route path={GTMSettings.PATHS.reset} element={<ResetPuzzle />} />
                <Route
                    path={GTMSettings.PATHS.guess_the_cover}
                    element={<GTCPuzzle puzzleType={PUZZLE_TYPES.GTMC} answers={this.answers} />}
                />
                <Route path={GTMSettings.PATHS.setting} element={<Setting />} />
                <Route path={GTMSettings.PATHS.email_callback} element={<Setting fromEmailCallback={true} />} />
                <Route path="*" element={<Navigate to="/" />} />
            </>
        );
    }

    puzzle_start_date: Date = new Date('8/31/2024');

    website_url: string = 'GuessTheMovie.Name';
    title: string = 'Guess The Movie Name';
    website_title: string = 'Guess The Movie.Name';
    parent_abbr: string = 'gtm'; // used for submitlogin

    max_guesses: number = 6;
    root_url: string = '/';
    puzzle_type: PUZZLE_TYPES_TYPE = PUZZLE_TYPES.GTM;
    clipboard_tag: string = '#GuessTheMovie';
    storage_keys: { guess: string, game_state: string } = { guess: 'guess', game_state: 'gamestate' };
    answers: AnswerUnion = answers;
    website_emoji: string = '🎥'; // Video game
    guess_item: string = 'Movie';

    progress_ranks: { [string]: string } = {
        '0': 'Rookie Reeler',
        '5': 'Cinema Newbie',
        '10': 'Scene Seeker',
        '20': 'Film Fanatic',
        '40': 'Movie Maven',
        '80': 'Visual Virtuoso',
        '150': 'Plot Prognosticator',
        '200': 'Directorial Detective',
        '250': 'Film Theory Expert',
        '300': 'Cinematic Connoisseur',
        '350': 'Silver Screen Scholar',
        '400': 'Motion Picture Master',
        '430': 'Elite Film Analyst',
        '470': 'Champion of the Cinephile',
        '500': 'Prodigious Picture Guesser',
        '530': 'Masterful Movie Mind',
        '560': 'Superior Scene Strategist',
        '600': 'Grandmaster of the Genre',
        '630': 'Supreme Silver Screen Savant',
        '700': 'Sovereign of the Cinema',
        '750': 'Grand Guru of the Reel',
        '800': 'Ultimate Film Buff',
        '900': 'Eternal Expert of the Epics',
        '1000': 'Omni-Cinephile',
        '1100': 'Galactic Guru of Films',
        '1300': 'Legendary Luminary of the Screen',
    };
    // $FlowIgnore
    get_hint_text(imageIndex: number, puzzle: TGTMAnswer, settings: GameSettings): string {
        const HINT_2 = 'Metacritic Score: ';
        const HINT_3 = 'Genre: ';
        const HINT_4 = 'Original Release Year: ';
        const HINT_5 = 'Notable Actor: ';
        const HINT_6 = 'Director: ';
        switch (imageIndex) {
            case 2:
                return `${HINT_2} ${puzzle.metacritic_score}`;
            case 3:
                return `${HINT_3} ${puzzle.genre}`;
            case 4:
                return `${HINT_4} ${puzzle.release_year}`;
            case 5:
                return `${HINT_5} ${puzzle.notable_actor}`;
            case 6:
                return `${HINT_6} ${puzzle.director}`;
            default:
                return '';
        }
    }

    TEXTS: any = {
        modal: {
            AboutModal: {
                modalTexts: [
                    `Every day a new ${this.guess_item.toLowerCase()} is selected and 6 screenshots from the movie  are presented to you one at a time.`,
                    `We try to have a selection of different movies from different genres and eras. We also try to have a mix of ${this.guess_item.toLowerCase()}s that are well known and ${this.guess_item.toLowerCase()}s that are more obscure. Generally harder ${this.guess_item.toLowerCase()}s are on the weekends, and easier ${this.guess_item.toLowerCase()}s are on weekdays.`,
                    {
                        beforeLink: `Want your favorite ${this.guess_item.toLowerCase()} to be featured as a daily puzzle?`,
                        url: GTG_COMMUNITY_SUBMISSION_URL,
                        linkText: `Suggest A ${this.guess_item} `,
                        afterLink: '',
                    },
                ],
            },
            HowToPlayModal: {
                modalTexts: [
                    `Search for the ${this.guess_item.toLowerCase()} that you think the shown screenshot belongs to.`,
                    `All 6 screenshots are from the same ${this.guess_item.toLowerCase()}.`,
                    `If you get it wrong or click skip, we will reveal an additional screenshot of the ${this.guess_item.toLowerCase()}.`,
                    `You get ${this.max_guesses} guesses in total.`,
                ],
            },
        },
    };
}
