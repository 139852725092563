/* eslint-disable no-useless-escape */
// @flow

export type TGTMCAnswer = {
    content: string,
    submitted_by: string,
    answers: Array<string>,
    affiliate_links: Array<string>,
    franchise: string,
    source: string,
    uncover_order: Array<string>,
};

type TGTMCAnswers = {
    [string]: TGTMCAnswer
};

export const answers: TGTMCAnswers = {
    '1': {
        content: '',
        submitted_by: '',
        answers: ["Inside Out 2"],
        affiliate_links: [],
        franchise: 'Inside Out',
        source: 'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/vpnVM9B6NMmQpWeZvzLvDESb2QY.jpg',
        uncover_order: [0, 2, 3, 5],
    },
    '2': {
        content: '',
        submitted_by: '',
        answers: ["A Matter of Loaf and Death", "Wallace & Gromit: A Matter of Loaf and Death"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/lj7BjaweXQsRkwye9fI0iXRJ9Qi.jpg',
        uncover_order: [6, 5, 4, 1],
    },
    '3': {
        content: '',
        submitted_by: '',
        answers: ["The Shawshank Redemption"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/9cqNxx0GxF0bflZmeSMuL5tnGzr.jpg',
        uncover_order: [2, 4, 1, 7],
    },
    '4': {
        content: '',
        submitted_by: '',
        answers: ["Trinity Is Still My Name"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/oRd5Zb2MJEvBBxvyh3gVe6d4cAl.jpg',
        uncover_order: [5, 6, 4, 2],
    },
    '5': {
        content: '',
        submitted_by: '',
        answers: ["Interstellar"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/gEU2QniE6E77NI6lCU6MxlNBvIx.jpg',
        uncover_order: [6, 4, 1, 2],
    },
    '6': {
        content: '',
        submitted_by: '',
        answers: ["The Promised Land"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/npQgoOWn4fnvOIIyTj4rIV80FXC.jpg',
        uncover_order: [2, 3, 1, 7],
    },
    '8': {
        content: '',
        submitted_by: '',
        answers: ["The Little Mermaid"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/plcZXvI310FkbwIptvd6rqk63LP.jpg',
        uncover_order: [8, 1, 0, 2],
    },
    '9': {
        content: '',
        submitted_by: '',
        answers: ["Braveheart"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/or1gBugydmjToAEq7OZY0owwFk.jpg',
        uncover_order: [2, 3, 1, 8],
    },
    '10': {
        content: '',
        submitted_by: '',
        answers: ["Logan"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/fnbjcRDYn6YviCcePDnGdyAkYsB.jpg',
        uncover_order: [2, 3, 1, 7],
    },
    '11': {
        content: '',
        submitted_by: '',
        answers: ["The Garfield Movie"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/xYduFGuch9OwbCOEUiamml18ZoB.jpg',
        uncover_order: [7, 5, 3, 1],
    },
    '12': {
        content: '',
        submitted_by: '',
        answers: ["To Kill a Mockingbird"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/gZycFUMLx2110dzK3nBNai7gfpM.jpg',
        uncover_order: [0, 3, 2, 5],
    },
    '13': {
        content: '',
        submitted_by: '',
        answers: ["Lawrence of Arabia"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/AiAm0EtDvyGqNpVoieRw4u65vD1.jpg',
        uncover_order: [6, 5, 4, 2],
    },
    '14': {
        content: '',
        submitted_by: '',
        answers: ["The Great Escape"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/gBH4H8UMFxl139HaLz6lRuvsel8.jpg',
        uncover_order: [3, 2, 4, 7],
    },
    '15': {
        content: '',
        submitted_by: '',
        answers: ["Saving Bikini Bottom: The Sandy Cheeks Movie"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/30YnfZdMNIV7noWLdvmcJS0cbnQ.jpg',
        uncover_order: [0, 2, 1, 5],
    },
    '16': {
        content: '',
        submitted_by: '',
        answers: ["Coco"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/gGEsBPAijhVUFoiNpgZXqRVWJt2.jpg',
        uncover_order: [6, 5, 4, 2],
    },
    '17': {
        content: '',
        submitted_by: '',
        answers: ["Witness for the Prosecution"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/iUIo7IVYOf4yHwGmVHyGJcb4QOe.jpg',
        uncover_order: [8, 0, 4, 1],
    },
    '18': {
        content: '',
        submitted_by: '',
        answers: ["Your Name"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/q719jXXEzOoYaps6babgKnONONX.jpg',
        uncover_order: [6, 5, 3, 2],
    },
    '19': {
        content: '',
        submitted_by: '',
        answers: ["Rear Window"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/ILVF0eJxHMddjxeQhswFtpMtqx.jpg',
        uncover_order: [3, 5, 7, 2],
    },
    '20': {
        content: '',
        submitted_by: '',
        answers: ["The Great Dictator"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/1QpO9wo7JWecZ4NiBuu625FiY1j.jpg',
        uncover_order: [2, 3, 1, 7],
    },
    '21': {
        content: '',
        submitted_by: '',
        answers: ["The Handmaiden"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/dLlH4aNHdnmf62umnInL8xPlPzw.jpg',
        uncover_order: [8, 6, 3, 2],
    },
    '22': {
        content: '',
        submitted_by: '',
        answers: ["GoodFellas"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/aKuFiU82s5ISJpGZp7YkIr3kCUd.jpg',
        uncover_order: [6, 4, 1, 7],
    },
    '23': {
        content: '',
        submitted_by: '',
        answers: ["The Legend of 1900"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/4EKnIjT1IGGFyplaW11lTvXrzI0.jpg',
        uncover_order: [0, 2, 4, 7],
    },
    '24': {
        content: '',
        submitted_by: '',
        answers: ["Ikiru"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/dgNTS4EQDDVfkzJI5msKuHu2Ei3.jpg',
        uncover_order: [8, 3, 4, 1],
    },
    '25': {
        content: '',
        submitted_by: '',
        answers: ["Pulp Fiction"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/d5iIlFn5s0ImszYzBPb8JPIfbXD.jpg',
        uncover_order: [6, 5, 4, 2],
    },
    '26': {
        content: '',
        submitted_by: '',
        answers: ["The Lord of the Rings: The Return of the King"],
        affiliate_links: [],
        franchise: 'The Lord of the Rings',
        source: 'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/rCzpDGLbOoPwLjy3OAm5NUPOTrC.jpg',
        uncover_order: [3, 5, 6, 8],
    },
    '27': {
        content: '',
        submitted_by: '',
        answers: ["Seven Samurai"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/8OKmBV5BUFzmozIC3pPWKHy17kx.jpg',
        uncover_order: [7, 0, 4, 3],
    },
    '28': {
        content: '',
        submitted_by: '',
        answers: ["Cinema Paradiso"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/gCI2AeMV4IHSewhJkzsur5MEp6R.jpg',
        uncover_order: [7, 2, 4, 1],
    },
    '29': {
        content: '',
        submitted_by: '',
        answers: ["The Empire Strikes Back", "Star Wars: Episode V"],
        affiliate_links: [],
        franchise: 'Star Wars',
        source: 'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/nNAeTmF4CtdSgMDplXTDPOpYzsX.jpg',
        uncover_order: [0, 5, 4, 7],
    },
    '30': {
        content: '',
        submitted_by: '',
        answers: ["Parasite"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/7IiTTgloJzvGI1TAYymCfbfl3vT.jpg',
        uncover_order: [2, 0, 3, 6],
    },
    '31': {
        content: '',
        submitted_by: '',
        answers: ["The Shining"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/xazWoLealQwEgqZ89MLZklLZD3k.jpg',
        uncover_order: [6, 5, 3, 0],
    },
    '32': {
        content: '',
        submitted_by: '',
        answers: ["The Good, the Bad and the Ugly"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/bX2xnavhMYjWDoZp1VM6VnU1xwe.jpg',
        uncover_order: [2, 3, 1, 5],
    },
    '33': {
        content: '',
        submitted_by: '',
        answers: ["Green Book"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/7BsvSuDQuoqhWmU2fL7W2GOcZHU.jpg',
        uncover_order: [8, 3, 4, 1],
    },
    '34': {
        content: '',
        submitted_by: '',
        answers: ["L\u00e9on: The Professional"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/yI6X2cCM5YPJtxMhUd3dPGqDAhw.jpg',
        uncover_order: [0, 3, 7, 8],
    },
    '35': {
        content: '',
        submitted_by: '',
        answers: ["The Legend of Hei"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/aLv87NgRJUPkQ6sVLP72IisDdt4.jpg',
        uncover_order: [6, 5, 3, 1],
    },
    '36': {
        content: '',
        submitted_by: '',
        answers: ["Labyrinth"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/hbSdA1DmNA9IlfVoqJkIWYF2oYm.jpg',
        uncover_order: [3, 2, 4, 8],
    },
    '37': {
        content: '',
        submitted_by: '',
        answers: ["The Man from Earth"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://www.themoviedb.org/t/p/w600_and_h900_bestv2/V086R82gNgWrotaXZFO4JhdgB1.jpg',
        uncover_order: [8, 3, 4, 1],
    },
};
