/* eslint-disable no-useless-escape */
// @flow

export type TGTMAnswer = {
    content: string,
    submitted_by: string,
    answers: Array<string>,
    affiliate_links: Array<string>,
    franchise: string,
    metacritic_score: string,
    genre: string,
    release_year: string,
    notable_actor: string,
    director: string,
};

type TGTMAnswers = {
    [string]: TGTMAnswer
};

export const answers: TGTMAnswers = {
    '1': {
        content: '',
        submitted_by: '',
        answers: ["Forrest Gump"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 82%; User: 7.8',
        genre: 'Drama, Romance',
        release_year: '1994',
        notable_actor: 'Tom Hanks',
        director: 'Robert Zemeckis',
    },
    '2': {
        content: '',
        submitted_by: '',
        answers: ["The Departed"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 85%; User: 6.8',
        genre: 'Drama, Thriller, Crime',
        release_year: '2006',
        notable_actor: 'Leonardo DiCaprio',
        director: 'Martin Scorsese',
    },
    '3': {
        content: '',
        submitted_by: '',
        answers: ["Ant-Man and the Wasp: Quantumania"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 48%; User: 5.5',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '2023',
        notable_actor: 'Paul Rudd',
        director: 'Peyton Reed',
    },
    '4': {
        content: '',
        submitted_by: '',
        answers: ["The Super Mario Bros. Movie"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 46%; User: 8.2',
        genre: 'Animation, Adventure, Family, Fantasy, Comedy',
        release_year: '2023',
        notable_actor: 'Chris Pratt',
        director: 'Aaron Horvath, Michael Jelenic, Pierre Leduc, Fabien Polack',
    },
    '5': {
        content: '',
        submitted_by: '',
        answers: ["Dungeons & Dragons: Honor Among Thieves"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 72%; User: 7.5',
        genre: 'Adventure, Fantasy, Comedy',
        release_year: '2023',
        notable_actor: 'Chris Pine',
        director: 'John Francis Daley, Jonathan Goldstein',
    },
    '6': {
        content: '',
        submitted_by: '',
        answers: ["The Last Kingdom: Seven Kings Must Die"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 48%; User: 6.2',
        genre: 'Action, Adventure, History, Drama, War',
        release_year: '2023',
        notable_actor: 'Mark Rowley',
        director: 'Edward Bazalgette',
    },
    '7': {
        content: '',
        submitted_by: '',
        answers: ["Avatar: The Way of Water"],
        affiliate_links: [],
        franchise: 'James Cameron\'s Avatar',
        metacritic_score: 'Critic: 67%; User: 7.1',
        genre: 'Science Fiction, Adventure, Action',
        release_year: '2022',
        notable_actor: 'Sam Worthington',
        director: 'James Cameron',
    },
    '8': {
        content: '',
        submitted_by: '',
        answers: ["Guardians of the Galaxy Volume 3"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 64%; User: 7.9',
        genre: 'Science Fiction, Adventure, Action',
        release_year: '2023',
        notable_actor: 'Chris Pratt',
        director: 'James Gunn',
    },
    '9': {
        content: '',
        submitted_by: '',
        answers: ["Scream VI", "Scream 6"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 61%; User: 6.5',
        genre: 'Horror, Mystery, Thriller',
        release_year: '2023',
        notable_actor: 'Courteney Cox',
        director: 'Matt Bettinelli-Olpin, Tyler Gillett',
    },
    '10': {
        content: '',
        submitted_by: '',
        answers: ["Creed III"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 73%; User: 6.8',
        genre: 'Drama, Action',
        release_year: '2023',
        notable_actor: 'Michael B. Jordan',
        director: 'Michael B. Jordan',
    },
    '11': {
        content: '',
        submitted_by: '',
        answers: ["The Shining"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 68%; User: 8.7',
        genre: 'Horror, Thriller',
        release_year: '1980',
        notable_actor: 'Jack Nicholson',
        director: 'Stanley Kubrick',
    },
    '12': {
        content: '',
        submitted_by: '',
        answers: ["Rogue One: A Star Wars Story"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 65%; User: 7.6',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '2016',
        notable_actor: 'Felicity Jones',
        director: 'Gareth Edwards',
    },
    '13': {
        content: '',
        submitted_by: '',
        answers: ["The Dark Knight"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 84%; User: 8.9',
        genre: 'Drama, Action, Crime, Thriller',
        release_year: '2008',
        notable_actor: 'Christian Bale',
        director: 'Christopher Nolan',
    },
    '14': {
        content: '',
        submitted_by: '',
        answers: ["John Wick: Chapter 4"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 78%; User: 8.5',
        genre: 'Action, Thriller, Crime',
        release_year: '2023',
        notable_actor: 'Keanu Reeves',
        director: 'Chad Stahelski',
    },
    '15': {
        content: '',
        submitted_by: '',
        answers: ["The Matrix"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 73%; User: 8.9',
        genre: 'Action, Science Fiction',
        release_year: '1999',
        notable_actor: 'Keanu Reeves',
        director: 'Lana Wachowski, Lilly Wachowski',
    },
    '16': {
        content: '',
        submitted_by: '',
        answers: ["Cocaine Bear"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 54%; User: 5.3',
        genre: 'Thriller, Comedy, Crime',
        release_year: '2023',
        notable_actor: 'Keri Russell',
        director: 'Elizabeth Banks',
    },
    '17': {
        content: '',
        submitted_by: '',
        answers: ["Puss in Boots: The Last Wish"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 73%; User: 8.5',
        genre: 'Animation, Family, Fantasy, Adventure, Comedy, Drama',
        release_year: '2022',
        notable_actor: 'Antonio Banderas',
        director: 'Joel Crawford, Januel Mercado',
    },
    '18': {
        content: '',
        submitted_by: '',
        answers: ["Se7en", "Seven"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 65%; User: 8.8',
        genre: 'Crime, Mystery, Thriller',
        release_year: '1995',
        notable_actor: 'Morgan Freeman',
        director: 'David Fincher',
    },
    '19': {
        content: '',
        submitted_by: '',
        answers: ["John Wick: Chapter 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 82%; User: 8.1',
        genre: 'Action, Thriller, Crime',
        release_year: '2017',
        notable_actor: 'Keanu Reeves',
        director: 'Chad Stahelski',
    },
    '20': {
        content: '',
        submitted_by: '',
        answers: ["Black Panther: Wakanda Forever"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 67%; User: 5.2',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '2022',
        notable_actor: 'Letitia Wright',
        director: 'Ryan Coogler',
    },
    '21': {
        content: '',
        submitted_by: '',
        answers: ["Titanic"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 75%; User: 8.4',
        genre: 'Drama, Romance',
        release_year: '1997',
        notable_actor: 'Leonardo DiCaprio',
        director: 'James Cameron',
    },
    '22': {
        content: '',
        submitted_by: '',
        answers: ["Jurassic World"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 59%; User: 6.8',
        genre: 'Action, Adventure, Science Fiction, Thriller',
        release_year: '2015',
        notable_actor: 'Chris Pratt',
        director: 'Colin Trevorrow',
    },
    '23': {
        content: '',
        submitted_by: '',
        answers: ["Skyfall"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 81%; User: 7.7',
        genre: 'Action, Adventure, Thriller',
        release_year: '2012',
        notable_actor: 'Daniel Craig',
        director: 'Sam Mendes',
    },
    '24': {
        content: '',
        submitted_by: '',
        answers: ["M3GAN"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 72%; User: 6.6',
        genre: 'Science Fiction, Horror, Comedy',
        release_year: '2022',
        notable_actor: 'Allison Williams',
        director: 'Gerard Johnstone',
    },
    '25': {
        content: '',
        submitted_by: '',
        answers: ["Aladdin"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 86%; User: 8.6',
        genre: 'Animation, Family, Adventure, Fantasy, Romance',
        release_year: '1992',
        notable_actor: 'Scott Weinger',
        director: 'Ron Clements, John Musker',
    },
    '26': {
        content: '',
        submitted_by: '',
        answers: ["Kill Bill: Vol. 1"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 69%; User: 8.5',
        genre: 'Action, Crime',
        release_year: '2003',
        notable_actor: 'Uma Thurman',
        director: 'Quentin Tarantino',
    },
    '27': {
        content: '',
        submitted_by: '',
        answers: ["Guardians of the Galaxy Vol. 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 67%; User: 7.8',
        genre: 'Adventure, Action, Science Fiction',
        release_year: '2017',
        notable_actor: 'Chris Pratt',
        director: 'James Gunn',
    },
    '28': {
        content: '',
        submitted_by: '',
        answers: ["The Fate of the Furious"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 56%; User: 6.9',
        genre: 'Action, Crime, Thriller',
        release_year: '2017',
        notable_actor: 'Vin Diesel',
        director: 'F. Gary Gray',
    },
    '29': {
        content: '',
        submitted_by: '',
        answers: ["Iron Man"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 79%; User: 8.4',
        genre: 'Action, Science Fiction, Adventure',
        release_year: '2008',
        notable_actor: 'Robert Downey Jr.',
        director: 'Jon Favreau',
    },
    '30': {
        content: '',
        submitted_by: '',
        answers: ["Home Alone 2: Lost in New York"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 46%; User: 7.0',
        genre: 'Comedy, Family, Adventure',
        release_year: '1992',
        notable_actor: 'Macaulay Culkin',
        director: 'Chris Columbus',
    },
    '31': {
        content: '',
        submitted_by: '',
        answers: ["Renfield"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 53%; User: 6.4',
        genre: 'Horror, Comedy, Fantasy',
        release_year: '2023',
        notable_actor: 'Nicholas Hoult',
        director: 'Chris McKay',
    },
    '32': {
        content: '',
        submitted_by: '',
        answers: ["Joker"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 59%; User: 8.8',
        genre: 'Crime, Thriller, Drama',
        release_year: '2019',
        notable_actor: 'Joaquin Phoenix',
        director: 'Todd Phillips',
    },
    '33': {
        content: '',
        submitted_by: '',
        answers: ["Grease"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Romance, Comedy',
        release_year: '1978',
        notable_actor: '',
        director: 'Grease, 1978 director',
    },
    '34': {
        content: '',
        submitted_by: '',
        answers: ["Top Gun: Maverick"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 78%; User: 7.5',
        genre: 'Action, Drama',
        release_year: '2022',
        notable_actor: 'Tom Cruise',
        director: 'Joseph Kosinski',
    },
    '35': {
        content: '',
        submitted_by: '',
        answers: ["The Thing"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 57%; User: 8.6',
        genre: 'Horror, Mystery, Science Fiction',
        release_year: '1982',
        notable_actor: 'Kurt Russell',
        director: 'John Carpenter',
    },
    '36': {
        content: '',
        submitted_by: '',
        answers: ["Wild Card"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Crime, Action',
        release_year: '2015',
        notable_actor: '',
        director: 'Wild Card, 2015 director',
    },
    '37': {
        content: '',
        submitted_by: '',
        answers: ["The Grey"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 64%; User: 5.9',
        genre: 'Drama, Thriller, Action, Adventure',
        release_year: '2012',
        notable_actor: 'Liam Neeson',
        director: 'Joe Carnahan',
    },
    '38': {
        content: '',
        submitted_by: '',
        answers: ["Capernaum"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '2018',
        notable_actor: '',
        director: 'Capernaum, 2018 director',
    },
    '39': {
        content: '',
        submitted_by: '',
        answers: ["Rocky III"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 57%; User: 7.5',
        genre: 'Drama',
        release_year: '1982',
        notable_actor: 'Sylvester Stallone',
        director: 'Sylvester Stallone',
    },
    '40': {
        content: '',
        submitted_by: '',
        answers: ["Ghostbusters"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'tbd',
        genre: 'Action, Fantasy, Comedy',
        release_year: '2016',
        notable_actor: '53',
        director: 'Ghostbusters, 2016 director',
    },
    '41': {
        content: '',
        submitted_by: '',
        answers: ["Idiocracy"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 66%; User: 7.2',
        genre: 'Comedy, Science Fiction, Adventure',
        release_year: '2006',
        notable_actor: 'Luke Wilson',
        director: 'Mike Judge',
    },
    '42': {
        content: '',
        submitted_by: '',
        answers: ["Ghost"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Drama, Thriller, Mystery, Romance',
        release_year: '1990',
        notable_actor: '',
        director: 'Ghost, 1990 director',
    },
    '43': {
        content: '',
        submitted_by: '',
        answers: ["Scream"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'tbd19965',
        genre: 'Horror, Mystery, Thriller',
        release_year: '1996',
        notable_actor: 'tbd',
        director: 'Scream, 1996',
    },
    '44': {
        content: '',
        submitted_by: '',
        answers: ["Jumper"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 35%; User: 5.8',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '2008',
        notable_actor: 'Hayden Christensen',
        director: 'Doug Liman',
    },
    '45': {
        content: '',
        submitted_by: '',
        answers: ["Total Recall"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '1990',
        notable_actor: '',
        director: 'Total Recall, 1990 director',
    },
    '46': {
        content: '',
        submitted_by: '',
        answers: ["Zodiac"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'tbd',
        genre: 'Crime, Drama, Mystery, Thriller',
        release_year: '2007',
        notable_actor: 'tbd',
        director: 'Zodiac, 2007 director',
    },
    '47': {
        content: '',
        submitted_by: '',
        answers: ["Police Academy"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 41%; User: 7.2',
        genre: 'Comedy, Crime',
        release_year: '1984',
        notable_actor: 'Steve Guttenberg',
        director: 'Hugh Wilson',
    },
    '48': {
        content: '',
        submitted_by: '',
        answers: ["The Boy Who Harnessed the Wind"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 68%; User: 7.7',
        genre: 'Drama, History, Family',
        release_year: '2019',
        notable_actor: 'Chiwetel Ejiofor',
        director: 'Chiwetel Ejiofor',
    },
    '49': {
        content: '',
        submitted_by: '',
        answers: ["The Woman King"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Drama, History',
        release_year: '2022',
        notable_actor: '',
        director: 'The Woman King, 2022 director',
    },
    '50': {
        content: '',
        submitted_by: '',
        answers: ["Angels & Demons"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Mystery',
        release_year: '2009',
        notable_actor: '',
        director: 'Angels & Demons, 2009 director',
    },
    '51': {
        content: '',
        submitted_by: '',
        answers: ["Doctor Strange in the Multiverse of Madness"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Action, Adventure',
        release_year: '2022',
        notable_actor: '',
        director: 'Doctor Strange in the Multiverse of Madness, 2022 director',
    },
    '52': {
        content: '',
        submitted_by: '',
        answers: ["The Sorcerer's Apprentice"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Adventure, Action',
        release_year: '2010',
        notable_actor: '',
        director: 'The Sorcerer\'s Apprentice, 2010 director',
    },
    '53': {
        content: '',
        submitted_by: '',
        answers: ["Blade"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Action',
        release_year: '1998',
        notable_actor: '',
        director: 'Blade, 1998 director',
    },
    '54': {
        content: '',
        submitted_by: '',
        answers: ["We Can Be Heroes"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Fantasy, Family, Comedy',
        release_year: '2020',
        notable_actor: '',
        director: 'We Can Be Heroes, 2020 director',
    },
    '55': {
        content: '',
        submitted_by: '',
        answers: ["The Italian Job"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Crime, Comedy, Thriller',
        release_year: '1969',
        notable_actor: '',
        director: 'The Italian Job, 1969 director',
    },
    '56': {
        content: '',
        submitted_by: '',
        answers: ["The Batman"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Crime, Mystery, Thriller',
        release_year: '2022',
        notable_actor: '',
        director: 'The Batman, 2022 director',
    },
    '57': {
        content: '',
        submitted_by: '',
        answers: ["The Kissing Booth 3"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Romance, Comedy',
        release_year: '2021',
        notable_actor: '',
        director: 'The Kissing Booth 3, 2021 director',
    },
    '58': {
        content: '',
        submitted_by: '',
        answers: ["Togo"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Family',
        release_year: '2019',
        notable_actor: '',
        director: 'Togo, 2019 director',
    },
    '59': {
        content: '',
        submitted_by: '',
        answers: ["The Revenant"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Western, Drama, Adventure',
        release_year: '2015',
        notable_actor: '',
        director: 'The Revenant, 2015 director',
    },
    '60': {
        content: '',
        submitted_by: '',
        answers: ["A Man Called Otto"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Drama',
        release_year: '2022',
        notable_actor: '',
        director: 'A Man Called Otto, 2022 director',
    },
    '61': {
        content: '',
        submitted_by: '',
        answers: ["Fantastic Beasts: The Crimes of Grindelwald"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'tbd',
        genre: 'Fantasy, Adventure, Family',
        release_year: '2018',
        notable_actor: 'tbd',
        director: 'Fantastic Beasts: The Crimes of Grindelwald, 2018 director',
    },
    '62': {
        content: '',
        submitted_by: '',
        answers: ["Avengers: Infinity War"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Science Fiction',
        release_year: '2018',
        notable_actor: '',
        director: 'Avengers: Infinity War, 2018 director',
    },
    '63': {
        content: '',
        submitted_by: '',
        answers: ["Mad Max Beyond Thunderdome", "Mad Max 3"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'tbd',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '1985',
        notable_actor: 'tbd',
        director: 'Mad Max Beyond Thunderdome, 1985 director',
    },
    '64': {
        content: '',
        submitted_by: '',
        answers: ["The Fox and the Hound"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Animation, Drama, Family',
        release_year: '1981',
        notable_actor: '',
        director: 'The Fox and the Hound, 1981 director',
    },
    '65': {
        content: '',
        submitted_by: '',
        answers: ["Obi-Wan Kenobi: A Jedi's Return"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Documentary',
        release_year: '2022',
        notable_actor: '',
        director: 'Obi-Wan Kenobi: A Jedi\'s Return, 2022 director',
    },
    '66': {
        content: '',
        submitted_by: '',
        answers: ["Dawn of the Dead"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Action',
        release_year: '2004',
        notable_actor: '',
        director: 'Dawn of the Dead, 2004 director',
    },
    '67': {
        content: '',
        submitted_by: '',
        answers: ["Flushed Away"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Animation, Comedy, Family',
        release_year: '2006',
        notable_actor: '',
        director: 'Flushed Away, 2006 director',
    },
    '68': {
        content: '',
        submitted_by: '',
        answers: ["Insidious: Chapter 3"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller, Mystery',
        release_year: '2015',
        notable_actor: '',
        director: 'Insidious: Chapter 3, 2015 director',
    },
    '69': {
        content: '',
        submitted_by: '',
        answers: ["Watchmen"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 56%; User: 8.4',
        genre: 'Action, Mystery, Science Fiction',
        release_year: '2009',
        notable_actor: 'Jackie Earle Haley',
        director: 'Zack Snyder',
    },
    '70': {
        content: '',
        submitted_by: '',
        answers: ["King Richard"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, History',
        release_year: '2021',
        notable_actor: '',
        director: 'King Richard, 2021 director',
    },
    '71': {
        content: '',
        submitted_by: '',
        answers: ["Lone Survivor"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '5345',
        genre: 'War, Action, Drama, History',
        release_year: '2013',
        notable_actor: '533',
        director: 'Lone Survivor, 2013 director',
    },
    '72': {
        content: '',
        submitted_by: '',
        answers: ["The Longest Yard"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2005',
        notable_actor: '',
        director: 'The Longest Yard, 2005 director',
    },
    '73': {
        content: '',
        submitted_by: '',
        answers: ["The Whale"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '2022',
        notable_actor: '',
        director: 'The Whale, 2022 director',
    },
    '74': {
        content: '',
        submitted_by: '',
        answers: ["Mission: Impossible II"],
        affiliate_links: [],
        franchise: 'Mission: Impossible',
        metacritic_score: '50',
        genre: 'Adventure, Action, Thriller',
        release_year: '2000',
        notable_actor: '50',
        director: 'Mission: Impossible II, 2000 director',
    },
    '75': {
        content: '',
        submitted_by: '',
        answers: ["First Blood"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Thriller, War',
        release_year: '1982',
        notable_actor: '',
        director: 'First Blood, 1982 director',
    },
    '76': {
        content: '',
        submitted_by: '',
        answers: ["Uncharted"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure',
        release_year: '2022',
        notable_actor: '',
        director: 'Uncharted, 2022 director',
    },
    '77': {
        content: '',
        submitted_by: '',
        answers: ["V for Vendetta"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'tbd',
        genre: 'Action, Thriller, Science Fiction',
        release_year: '2006',
        notable_actor: 'tbd',
        director: 'V for Vendetta, 2006 director',
    },
    '78': {
        content: '',
        submitted_by: '',
        answers: ["Stuart Little 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Adventure, Animation, Comedy',
        release_year: '2002',
        notable_actor: '',
        director: 'Stuart Little 2, 2002 director',
    },
    '79': {
        content: '',
        submitted_by: '',
        answers: ["Nanny McPhee"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'tbd',
        genre: 'Fantasy, Comedy, Family',
        release_year: '2005',
        notable_actor: 'tbd',
        director: 'Nanny McPhee, 2005 director',
    },
    '80': {
        content: '',
        submitted_by: '',
        answers: ["Mad Max"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'asfda',
        genre: 'Adventure, Action, Thriller, Science Fiction',
        release_year: '1979',
        notable_actor: 'fsadf',
        director: 'Mad Max, 1979 director',
    },
    '81': {
        content: '',
        submitted_by: '',
        answers: ["The Chronicles of Narnia: The Lion, the Witch and the Wardrobe"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Family, Fantasy',
        release_year: '2005',
        notable_actor: '',
        director: 'The Chronicles of Narnia: The Lion, the Witch and the Wardrobe, 2005 director',
    },
    '82': {
        content: '',
        submitted_by: '',
        answers: ["Ballerina"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Adventure, Comedy, Family',
        release_year: '2016',
        notable_actor: '',
        director: 'Ballerina, 2016 director',
    },
    '83': {
        content: '',
        submitted_by: '',
        answers: ["Shrek"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Fantasy, Adventure, Family',
        release_year: '2001',
        notable_actor: '',
        director: 'Shrek, 2001 director',
    },
    '84': {
        content: '',
        submitted_by: '',
        answers: ["The Greatest Showman"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'tbd',
        genre: 'Drama',
        release_year: '2017',
        notable_actor: 'tb',
        director: 'The Greatest Showman, 2017 director',
    },
    '85': {
        content: '',
        submitted_by: '',
        answers: ["Lethal Weapon"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'tbd',
        genre: 'Adventure, Action, Comedy, Thriller, Crime',
        release_year: '1987',
        notable_actor: 'tbd',
        director: 'Lethal Weapon, 1987 director',
    },
    '86': {
        content: '',
        submitted_by: '',
        answers: ["Furious 7"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller, Crime',
        release_year: '2015',
        notable_actor: '',
        director: 'Furious 7, 2015 director',
    },
    '87': {
        content: '',
        submitted_by: '',
        answers: ["The Hunchback of Notre Dame"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'tbd',
        genre: 'Drama, Animation, Family',
        release_year: '1996',
        notable_actor: 'tbd',
        director: 'The Hunchback of Notre Dame, 1996 director',
    },
    '88': {
        content: '',
        submitted_by: '',
        answers: ["1917"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'tbd',
        genre: 'War, Drama, History',
        release_year: '2019',
        notable_actor: 'tbd',
        director: '1917, 2019 director',
    },
    '89': {
        content: '',
        submitted_by: '',
        answers: ["John Wick"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller',
        release_year: '2014',
        notable_actor: '',
        director: 'John Wick, 2014 director',
    },
    '90': {
        content: '',
        submitted_by: '',
        answers: ["CODA"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'tbd',
        genre: 'Drama, Music, Romance',
        release_year: '2021',
        notable_actor: 'tbd',
        director: 'CODA, 2021 director',
    },
    '91': {
        content: '',
        submitted_by: '',
        answers: ["Hacksaw Ridge"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, History, War',
        release_year: '2016',
        notable_actor: '',
        director: 'Hacksaw Ridge, 2016 director',
    },
    '92': {
        content: '',
        submitted_by: '',
        answers: ["Traffic"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Drama, Crime',
        release_year: '2000',
        notable_actor: '',
        director: 'Traffic, 2000 director',
    },
    '93': {
        content: '',
        submitted_by: '',
        answers: ["Bee Movie"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '54tbd',
        genre: 'Family, Animation, Adventure, Comedy',
        release_year: '2007',
        notable_actor: '54',
        director: 'Bee Movie, 2007 director',
    },
    '94': {
        content: '',
        submitted_by: '',
        answers: ["Resident Evil: Vendetta"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Horror, Adventure, Action, Science Fiction',
        release_year: '2017',
        notable_actor: '',
        director: 'Resident Evil: Vendetta, 2017 director',
    },
    '95': {
        content: '',
        submitted_by: '',
        answers: ["Indiana Jones and the Temple of Doom"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'tbd',
        genre: 'Adventure, Action',
        release_year: '1984',
        notable_actor: 'tbd',
        director: 'Indiana Jones and the Temple of Doom, 1984 director',
    },
    '96': {
        content: '',
        submitted_by: '',
        answers: ["Bullet Train"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Comedy, Thriller',
        release_year: '2022',
        notable_actor: '',
        director: 'Bullet Train, 2022 director',
    },
    '97': {
        content: '',
        submitted_by: '',
        answers: ["Godzilla vs. Kong"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Fantasy, Science Fiction',
        release_year: '2021',
        notable_actor: '',
        director: 'Godzilla vs. Kong, 2021 director',
    },
    '98': {
        content: '',
        submitted_by: '',
        answers: ["Harry Potter and the Philosopher's Stone"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy',
        release_year: '2001',
        notable_actor: '',
        director: 'Harry Potter and the Philosopher\'s Stone, 2001 director',
    },
    '99': {
        content: '',
        submitted_by: '',
        answers: ["The Blair Witch Project"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery',
        release_year: '1999',
        notable_actor: '',
        director: 'The Blair Witch Project, 1999 director',
    },
    '100': {
        content: '',
        submitted_by: '',
        answers: ["The Ring"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery',
        release_year: '2002',
        notable_actor: '',
        director: 'The Ring, 2002 director',
    },
    '101': {
        content: '',
        submitted_by: '',
        answers: ["Super Mario Bros."],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy, Comedy, Family, Science Fiction',
        release_year: '1993',
        notable_actor: '',
        director: 'Super Mario Bros., 1993 director',
    },
    '102': {
        content: '',
        submitted_by: '',
        answers: ["Cruella"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Crime',
        release_year: '2021',
        notable_actor: '',
        director: 'Cruella, 2021 director',
    },
    '103': {
        content: '',
        submitted_by: '',
        answers: ["Ace Ventura: Pet Detective"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'tbd',
        genre: 'Comedy, Adventure',
        release_year: '1994',
        notable_actor: 'tbd',
        director: 'Ace Ventura: Pet Detective, 1994 director',
    },
    '104': {
        content: '',
        submitted_by: '',
        answers: ["The Lost City"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Comedy',
        release_year: '2022',
        notable_actor: '',
        director: 'The Lost City, 2022 director',
    },
    '105': {
        content: '',
        submitted_by: '',
        answers: ["The Polar Express"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Adventure, Fantasy, Comedy',
        release_year: '2004',
        notable_actor: '',
        director: 'The Polar Express, 2004 director',
    },
    '106': {
        content: '',
        submitted_by: '',
        answers: ["The Outsiders"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '51',
        genre: 'tbd',
        release_year: '1983',
        notable_actor: '51',
        director: 'The Outsiders, 1983 director',
    },
    '107': {
        content: '',
        submitted_by: '',
        answers: ["Fantastic Beasts: The Secrets of Dumbledore"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Adventure, Family',
        release_year: '2022',
        notable_actor: '',
        director: 'Fantastic Beasts: The Secrets of Dumbledore, 2022 director',
    },
    '108': {
        content: '',
        submitted_by: '',
        answers: ["Dune"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Adventure',
        release_year: '2021',
        notable_actor: '',
        director: 'Dune, 2021 director',
    },
    '109': {
        content: '',
        submitted_by: '',
        answers: ["Zack Snyder's Justice League"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Fantasy, Science Fiction',
        release_year: '2021',
        notable_actor: '',
        director: 'Zack Snyder\'s Justice League, 2021 director',
    },
    '110': {
        content: '',
        submitted_by: '',
        answers: ["Senior Year"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2022',
        notable_actor: '',
        director: 'Senior Year, 2022 director',
    },
    '111': {
        content: '',
        submitted_by: '',
        answers: ["Minions"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Animation, Adventure, Comedy',
        release_year: '2015',
        notable_actor: '',
        director: 'Minions, 2015 director',
    },
    '112': {
        content: '',
        submitted_by: '',
        answers: ["The A-Team"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Action, Adventure, Comedy, Crime',
        release_year: '2010',
        notable_actor: '',
        director: 'The A-Team, 2010 director',
    },
    '113': {
        content: '',
        submitted_by: '',
        answers: ["Athena"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '2022',
        notable_actor: '',
        director: 'Athena, 2022 director',
    },
    '114': {
        content: '',
        submitted_by: '',
        answers: ["Gonjiam: Haunted Asylum"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery',
        release_year: '2018',
        notable_actor: '',
        director: 'Gonjiam: Haunted Asylum, 2018 director',
    },
    '115': {
        content: '',
        submitted_by: '',
        answers: ["The Avengers"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Action, Adventure',
        release_year: '2012',
        notable_actor: '',
        director: 'The Avengers, 2012 director',
    },
    '116': {
        content: '',
        submitted_by: '',
        answers: ["The Black Phone"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2022',
        notable_actor: '',
        director: 'The Black Phone, 2022 director',
    },
    '117': {
        content: '',
        submitted_by: '',
        answers: ["Coraline"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Fantasy',
        release_year: '2009',
        notable_actor: '',
        director: 'Coraline, 2009 director',
    },
    '118': {
        content: '',
        submitted_by: '',
        answers: ["Glass Onion: A Knives Out Mystery"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Crime, Mystery',
        release_year: '2022',
        notable_actor: '',
        director: 'Glass Onion: A Knives Out Mystery, 2022 director',
    },
    '119': {
        content: '',
        submitted_by: '',
        answers: ["Fifty Shades of Grey"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Romance, Thriller',
        release_year: '2015',
        notable_actor: '',
        director: 'Fifty Shades of Grey, 2015 director',
    },
    '120': {
        content: '',
        submitted_by: '',
        answers: ["Blade of the 47 Ronin"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy',
        release_year: '2022',
        notable_actor: '',
        director: 'Blade of the 47 Ronin, 2022 director',
    },
    '121': {
        content: '',
        submitted_by: '',
        answers: ["Ocean's Eleven"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'tbd',
        genre: 'Thriller, Crime',
        release_year: '2001',
        notable_actor: 'tbd',
        director: 'Ocean\'s Eleven, 2001 director',
    },
    '122': {
        content: '',
        submitted_by: '',
        answers: ["Harry Potter and the Half-Blood Prince"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy',
        release_year: '2009',
        notable_actor: '',
        director: 'Harry Potter and the Half-Blood Prince, 2009 director',
    },
    '123': {
        content: '',
        submitted_by: '',
        answers: ["Gifted"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Comedy',
        release_year: '2017',
        notable_actor: '',
        director: 'Gifted, 2017 director',
    },
    '124': {
        content: '',
        submitted_by: '',
        answers: ["Fifty Shades Freed"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Romance',
        release_year: '2018',
        notable_actor: '',
        director: 'Fifty Shades Freed, 2018 director',
    },
    '125': {
        content: '',
        submitted_by: '',
        answers: ["365 Days: This Day"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Romance, Drama',
        release_year: '2022',
        notable_actor: '',
        director: '365 Days: This Day, 2022 director',
    },
    '126': {
        content: '',
        submitted_by: '',
        answers: ["Abandoned"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery, Thriller',
        release_year: '2022',
        notable_actor: '',
        director: 'Abandoned, 2022 director',
    },
    '127': {
        content: '',
        submitted_by: '',
        answers: ["Mortal Kombat"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Fantasy, Adventure',
        release_year: '2021',
        notable_actor: '',
        director: 'Mortal Kombat, 2021 director',
    },
    '128': {
        content: '',
        submitted_by: '',
        answers: ["The Fallout"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '2021',
        notable_actor: '',
        director: 'The Fallout, 2021 director',
    },
    '129': {
        content: '',
        submitted_by: '',
        answers: ["The Last Witch Hunter"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Action, Adventure',
        release_year: '2015',
        notable_actor: '',
        director: 'The Last Witch Hunter, 2015 director',
    },
    '130': {
        content: '',
        submitted_by: '',
        answers: ["Savage Salvation"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller',
        release_year: '2022',
        notable_actor: '',
        director: 'Savage Salvation, 2022 director',
    },
    '131': {
        content: '',
        submitted_by: '',
        answers: ["RRR"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Drama',
        release_year: '2022',
        notable_actor: '',
        director: 'RRR, 2022 director',
    },
    '132': {
        content: '',
        submitted_by: '',
        answers: ["Meet the Robinsons"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Family',
        release_year: '2007',
        notable_actor: '',
        director: 'Meet the Robinsons, 2007 director',
    },
    '133': {
        content: '',
        submitted_by: '',
        answers: ["The School for Good and Evil"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy',
        release_year: '2022',
        notable_actor: '',
        director: 'The School for Good and Evil, 2022 director',
    },
    '134': {
        content: '',
        submitted_by: '',
        answers: ["The Infernal Machine"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Mystery',
        release_year: '2022',
        notable_actor: '',
        director: 'The Infernal Machine, 2022 director',
    },
    '135': {
        content: '',
        submitted_by: '',
        answers: ["Creed"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '2015',
        notable_actor: '',
        director: 'Creed, 2015 director',
    },
    '136': {
        content: '',
        submitted_by: '',
        answers: ["The Ice Age Adventures of Buck Wild"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Adventure, Family',
        release_year: '2022',
        notable_actor: '',
        director: 'The Ice Age Adventures of Buck Wild, 2022 director',
    },
    '137': {
        content: '',
        submitted_by: '',
        answers: ["The Wolf of Wall Street"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Crime, Drama, Comedy',
        release_year: '2013',
        notable_actor: '',
        director: 'The Wolf of Wall Street, 2013 director',
    },
    '138': {
        content: '',
        submitted_by: '',
        answers: ["Harry Potter and the Chamber of Secrets"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy',
        release_year: '2002',
        notable_actor: '',
        director: 'Harry Potter and the Chamber of Secrets, 2002 director',
    },
    '139': {
        content: '',
        submitted_by: '',
        answers: ["Monsters, Inc."],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Family',
        release_year: '2001',
        notable_actor: '',
        director: 'Monsters, Inc., 2001 director',
    },
    '140': {
        content: '',
        submitted_by: '',
        answers: ["The Ledge"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Adventure',
        release_year: '2022',
        notable_actor: '',
        director: 'The Ledge, 2022 director',
    },
    '141': {
        content: '',
        submitted_by: '',
        answers: ["The Evil Dead"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror',
        release_year: '1981',
        notable_actor: '',
        director: 'The Evil Dead, 1981 director',
    },
    '142': {
        content: '',
        submitted_by: '',
        answers: ["Hocus Pocus 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Comedy, Family',
        release_year: '2022',
        notable_actor: '',
        director: 'Hocus Pocus 2, 2022 director',
    },
    '143': {
        content: '',
        submitted_by: '',
        answers: ["Tangled"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family',
        release_year: '2010',
        notable_actor: '',
        director: 'Tangled, 2010 director',
    },
    '144': {
        content: '',
        submitted_by: '',
        answers: ["Interstellar"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Drama, Science Fiction',
        release_year: '2014',
        notable_actor: '',
        director: 'Interstellar, 2014 director',
    },
    '145': {
        content: '',
        submitted_by: '',
        answers: ["Moonfall"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Adventure, Action',
        release_year: '2022',
        notable_actor: '',
        director: 'Moonfall, 2022 director',
    },
    '146': {
        content: '',
        submitted_by: '',
        answers: ["The Little Mermaid"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Fantasy',
        release_year: '1989',
        notable_actor: '',
        director: 'The Little Mermaid, 1989 director',
    },
    '147': {
        content: '',
        submitted_by: '',
        answers: ["Harry Potter and the Prisoner of Azkaban"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy',
        release_year: '2004',
        notable_actor: '',
        director: 'Harry Potter and the Prisoner of Azkaban, 2004 director',
    },
    '148': {
        content: '',
        submitted_by: '',
        answers: ["Jujutsu Kaisen 0"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Action, Fantasy',
        release_year: '2021',
        notable_actor: '',
        director: 'Jujutsu Kaisen 0, 2021 director',
    },
    '149': {
        content: '',
        submitted_by: '',
        answers: ["Nope"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery, Science Fiction',
        release_year: '2022',
        notable_actor: '',
        director: 'Nope, 2022 director',
    },
    '150': {
        content: '',
        submitted_by: '',
        answers: ["Monstrous"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery, Thriller',
        release_year: '2022',
        notable_actor: '',
        director: 'Monstrous, 2022 director',
    },
    '151': {
        content: '',
        submitted_by: '',
        answers: ["On the Line"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller',
        release_year: '2022',
        notable_actor: '',
        director: 'On the Line, 2022 director',
    },
    '152': {
        content: '',
        submitted_by: '',
        answers: ["Harry Potter and the Deathly Hallows: Part 1"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy',
        release_year: '2010',
        notable_actor: '',
        director: 'Harry Potter and the Deathly Hallows: Part 1, 2010 director',
    },
    '153': {
        content: '',
        submitted_by: '',
        answers: ["A Quiet Place Part II"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Thriller, Horror',
        release_year: '2021',
        notable_actor: '',
        director: 'A Quiet Place Part II, 2021 director',
    },
    '154': {
        content: '',
        submitted_by: '',
        answers: ["Harry Potter and the Goblet of Fire"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy, Family',
        release_year: '2005',
        notable_actor: '',
        director: 'Harry Potter and the Goblet of Fire, 2005 director',
    },
    '155': {
        content: '',
        submitted_by: '',
        answers: ["Harry Potter and the Order of the Phoenix"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy, Mystery',
        release_year: '2007',
        notable_actor: '',
        director: 'Harry Potter and the Order of the Phoenix, 2007 director',
    },
    '156': {
        content: '',
        submitted_by: '',
        answers: ["After We Fell"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Romance, Drama',
        release_year: '2021',
        notable_actor: '',
        director: 'After We Fell, 2021 director',
    },
    '157': {
        content: '',
        submitted_by: '',
        answers: ["Harry Potter and the Deathly Hallows: Part 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Adventure',
        release_year: '2011',
        notable_actor: '',
        director: 'Harry Potter and the Deathly Hallows: Part 2, 2011 director',
    },
    '158': {
        content: '',
        submitted_by: '',
        answers: ["Creed II"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '2018',
        notable_actor: '',
        director: 'Creed II, 2018 director',
    },
    '159': {
        content: '',
        submitted_by: '',
        answers: ["The Northman"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Fantasy',
        release_year: '2022',
        notable_actor: '',
        director: 'The Northman, 2022 director',
    },
    '160': {
        content: '',
        submitted_by: '',
        answers: ["Girl in the Basement"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Crime, Thriller, TV Movie',
        release_year: '2021',
        notable_actor: '',
        director: 'Girl in the Basement, 2021 director',
    },
    '161': {
        content: '',
        submitted_by: '',
        answers: ["Toy Story"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Adventure, Family, Comedy',
        release_year: '1995',
        notable_actor: '',
        director: 'Toy Story, 1995 director',
    },
    '162': {
        content: '',
        submitted_by: '',
        answers: ["Transformers: Age of Extinction"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Action, Adventure',
        release_year: '2014',
        notable_actor: '',
        director: 'Transformers: Age of Extinction, 2014 director',
    },
    '163': {
        content: '',
        submitted_by: '',
        answers: ["After"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Romance, Drama',
        release_year: '2019',
        notable_actor: '',
        director: 'After, 2019 director',
    },
    '164': {
        content: '',
        submitted_by: '',
        answers: ["Babylon"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Comedy',
        release_year: '2022',
        notable_actor: '',
        director: 'Babylon, 2022 director',
    },
    '165': {
        content: '',
        submitted_by: '',
        answers: ["Luca"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Fantasy, Adventure, Family',
        release_year: '2021',
        notable_actor: '',
        director: 'Luca, 2021 director',
    },
    '166': {
        content: '',
        submitted_by: '',
        answers: ["Emily the Criminal"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Crime, Drama, Thriller',
        release_year: '2022',
        notable_actor: '',
        director: 'Emily the Criminal, 2022 director',
    },
    '167': {
        content: '',
        submitted_by: '',
        answers: ["Hellraiser"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery',
        release_year: '2022',
        notable_actor: '',
        director: 'Hellraiser, 2022 director',
    },
    '168': {
        content: '',
        submitted_by: '',
        answers: ["Samaritan"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Crime, Fantasy, Science Fiction',
        release_year: '2022',
        notable_actor: '',
        director: 'Samaritan, 2022 director',
    },
    '169': {
        content: '',
        submitted_by: '',
        answers: ["Lightyear"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Action, Adventure, Family, Science Fiction',
        release_year: '2022',
        notable_actor: '',
        director: 'Lightyear, 2022 director',
    },
    '170': {
        content: '',
        submitted_by: '',
        answers: ["The Suicide Squad"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Comedy, Adventure',
        release_year: '2021',
        notable_actor: '',
        director: 'The Suicide Squad, 2021 director',
    },
    '171': {
        content: '',
        submitted_by: '',
        answers: ["Memory"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller, Crime',
        release_year: '2022',
        notable_actor: '',
        director: 'Memory, 2022 director',
    },
    '172': {
        content: '',
        submitted_by: '',
        answers: ["The Hunger Games: Mockingjay - Part 1"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Adventure, Thriller',
        release_year: '2014',
        notable_actor: '',
        director: 'The Hunger Games: Mockingjay - Part 1, 2014 director',
    },
    '173': {
        content: '',
        submitted_by: '',
        answers: ["Morbius"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Science Fiction, Fantasy',
        release_year: '2022',
        notable_actor: '',
        director: 'Morbius, 2022 director',
    },
    '174': {
        content: '',
        submitted_by: '',
        answers: ["The Lion King"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Animation, Drama',
        release_year: '1994',
        notable_actor: '',
        director: 'The Lion King, 1994 director',
    },
    '175': {
        content: '',
        submitted_by: '',
        answers: ["The Jack in the Box: Awakening"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror',
        release_year: '2022',
        notable_actor: '',
        director: 'The Jack in the Box: Awakening, 2022 director',
    },
    '176': {
        content: '',
        submitted_by: '',
        answers: ["Shrek 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Comedy, Fantasy, Adventure, Romance',
        release_year: '2004',
        notable_actor: '',
        director: 'Shrek 2, 2004 director',
    },
    '177': {
        content: '',
        submitted_by: '',
        answers: ["The Tomorrow War"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Science Fiction, Adventure',
        release_year: '2021',
        notable_actor: '',
        director: 'The Tomorrow War, 2021 director',
    },
    '178': {
        content: '',
        submitted_by: '',
        answers: ["Ratatouille"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Family, Fantasy',
        release_year: '2007',
        notable_actor: '',
        director: 'Ratatouille, 2007 director',
    },
    '179': {
        content: '',
        submitted_by: '',
        answers: ["The Godfather"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Crime',
        release_year: '1972',
        notable_actor: '',
        director: 'The Godfather, 1972 director',
    },
    '180': {
        content: '',
        submitted_by: '',
        answers: ["The Boss Baby: Family Business"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Adventure, Family',
        release_year: '2021',
        notable_actor: '',
        director: 'The Boss Baby: Family Business, 2021 director',
    },
    '181': {
        content: '',
        submitted_by: '',
        answers: ["Coco"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Animation, Fantasy, Music, Comedy, Adventure',
        release_year: '2017',
        notable_actor: '',
        director: 'Coco, 2017 director',
    },
    '182': {
        content: '',
        submitted_by: '',
        answers: ["Raya and the Last Dragon"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Fantasy, Action, Adventure',
        release_year: '2021',
        notable_actor: '',
        director: 'Raya and the Last Dragon, 2021 director',
    },
    '183': {
        content: '',
        submitted_by: '',
        answers: ["The Invitation"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2022',
        notable_actor: '',
        director: 'The Invitation, 2022 director',
    },
    '184': {
        content: '',
        submitted_by: '',
        answers: ["Eternals"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Action, Adventure',
        release_year: '2021',
        notable_actor: '',
        director: 'Eternals, 2021 director',
    },
    '185': {
        content: '',
        submitted_by: '',
        answers: ["Avengers: Endgame"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Science Fiction, Action',
        release_year: '2019',
        notable_actor: '',
        director: 'Avengers: Endgame, 2019 director',
    },
    '186': {
        content: '',
        submitted_by: '',
        answers: ["Pirates of the Caribbean: The Curse of the Black Pearl"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy, Action',
        release_year: '2003',
        notable_actor: '',
        director: 'Pirates of the Caribbean: The Curse of the Black Pearl, 2003 director',
    },
    '187': {
        content: '',
        submitted_by: '',
        answers: ["Enola Holmes 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Mystery, Comedy, Crime',
        release_year: '2022',
        notable_actor: '',
        director: 'Enola Holmes 2, 2022 director',
    },
    '188': {
        content: '',
        submitted_by: '',
        answers: ["Mean Girls"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2004',
        notable_actor: '',
        director: 'Mean Girls, 2004 director',
    },
    '189': {
        content: '',
        submitted_by: '',
        answers: ["The Croods: A New Age"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Adventure, Fantasy, Comedy',
        release_year: '2020',
        notable_actor: '',
        director: 'The Croods: A New Age, 2020 director',
    },
    '190': {
        content: '',
        submitted_by: '',
        answers: ["The Conjuring: The Devil Made Me Do It"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery, Thriller',
        release_year: '2021',
        notable_actor: '',
        director: 'The Conjuring: The Devil Made Me Do It, 2021 director',
    },
    '191': {
        content: '',
        submitted_by: '',
        answers: ["Tetris"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, History, Drama',
        release_year: '2023',
        notable_actor: '',
        director: 'Tetris, 2023 director',
    },
    '192': {
        content: '',
        submitted_by: '',
        answers: ["Resident Evil: Welcome to Raccoon City"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Science Fiction, Action',
        release_year: '2021',
        notable_actor: '',
        director: 'Resident Evil: Welcome to Raccoon City, 2021 director',
    },
    '193': {
        content: '',
        submitted_by: '',
        answers: ["E.T. the Extra-Terrestrial"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Adventure, Family, Fantasy',
        release_year: '1982',
        notable_actor: '',
        director: 'E.T. the Extra-Terrestrial, 1982 director',
    },
    '194': {
        content: '',
        submitted_by: '',
        answers: ["Pirates of the Caribbean: On Stranger Tides"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Fantasy',
        release_year: '2011',
        notable_actor: '',
        director: 'Pirates of the Caribbean: On Stranger Tides, 2011 director',
    },
    '195': {
        content: '',
        submitted_by: '',
        answers: ["Dog"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Comedy',
        release_year: '2022',
        notable_actor: '',
        director: 'Dog, 2022 director',
    },
    '196': {
        content: '',
        submitted_by: '',
        answers: ["Three Steps Above Heaven"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Romance, Drama',
        release_year: '2010',
        notable_actor: '',
        director: 'Three Steps Above Heaven, 2010 director',
    },
    '197': {
        content: '',
        submitted_by: '',
        answers: ["The Maze Runner"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Mystery, Science Fiction, Thriller',
        release_year: '2014',
        notable_actor: '',
        director: 'The Maze Runner, 2014 director',
    },
    '198': {
        content: '',
        submitted_by: '',
        answers: ["Secret Headquarters"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Comedy, Science Fiction, Family',
        release_year: '2022',
        notable_actor: '',
        director: 'Secret Headquarters, 2022 director',
    },
    '199': {
        content: '',
        submitted_by: '',
        answers: ["Star Wars"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Science Fiction',
        release_year: '1977',
        notable_actor: '',
        director: 'Star Wars, 1977 director',
    },
    '200': {
        content: '',
        submitted_by: '',
        answers: ["Deadpool"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Comedy',
        release_year: '2016',
        notable_actor: '',
        director: 'Deadpool, 2016 director',
    },
    '201': {
        content: '',
        submitted_by: '',
        answers: ["Big Hero 6"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Family, Animation, Action, Comedy',
        release_year: '2014',
        notable_actor: '',
        director: 'Big Hero 6, 2014 director',
    },
    '202': {
        content: '',
        submitted_by: '',
        answers: ["Me Before You"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Romance',
        release_year: '2016',
        notable_actor: '',
        director: 'Me Before You, 2016 director',
    },
    '203': {
        content: '',
        submitted_by: '',
        answers: ["How to Train Your Dragon: The Hidden World"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Adventure',
        release_year: '2019',
        notable_actor: '',
        director: 'How to Train Your Dragon: The Hidden World, 2019 director',
    },
    '204': {
        content: '',
        submitted_by: '',
        answers: ["Dragon Ball Z: Bardock - The Father of Goku"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Action, Science Fiction, Drama, Fantasy',
        release_year: '1990',
        notable_actor: '',
        director: 'Dragon Ball Z: Bardock - The Father of Goku, 1990 director',
    },
    '205': {
        content: '',
        submitted_by: '',
        answers: ["The Amazing Spider-Man 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Fantasy',
        release_year: '2014',
        notable_actor: '',
        director: 'The Amazing Spider-Man 2, 2014 director',
    },
    '206': {
        content: '',
        submitted_by: '',
        answers: ["Naruto Shippuden the Movie"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Action, Fantasy',
        release_year: '2007',
        notable_actor: '',
        director: 'Naruto Shippuden the Movie, 2007 director',
    },
    '207': {
        content: '',
        submitted_by: '',
        answers: ["Dragon Ball Z: Resurrection 'F'"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Animation, Science Fiction',
        release_year: '2015',
        notable_actor: '',
        director: 'Dragon Ball Z: Resurrection \'F\', 2015 director',
    },
    '208': {
        content: '',
        submitted_by: '',
        answers: ["The Sea Beast"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Adventure, Family, Fantasy',
        release_year: '2022',
        notable_actor: '',
        director: 'The Sea Beast, 2022 director',
    },
    '209': {
        content: '',
        submitted_by: '',
        answers: ["Jack Reacher"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Crime, Drama, Thriller, Action',
        release_year: '2012',
        notable_actor: '',
        director: 'Jack Reacher, 2012 director',
    },
    '210': {
        content: '',
        submitted_by: '',
        answers: ["Shazam!"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Comedy, Fantasy',
        release_year: '2019',
        notable_actor: '',
        director: 'Shazam!, 2019 director',
    },
    '211': {
        content: '',
        submitted_by: '',
        answers: ["Gabriel's Inferno: Part II"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Romance',
        release_year: '2020',
        notable_actor: '',
        director: 'Gabriel\'s Inferno: Part II, 2020 director',
    },
    '212': {
        content: '',
        submitted_by: '',
        answers: ["The Princess and the Frog"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Romance, Family, Animation',
        release_year: '2009',
        notable_actor: '',
        director: 'The Princess and the Frog, 2009 director',
    },
    '213': {
        content: '',
        submitted_by: '',
        answers: ["Real Steel"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Science Fiction, Drama',
        release_year: '2011',
        notable_actor: '',
        director: 'Real Steel, 2011 director',
    },
    '214': {
        content: '',
        submitted_by: '',
        answers: ["After We Collided"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Romance, Drama',
        release_year: '2020',
        notable_actor: '',
        director: 'After We Collided, 2020 director',
    },
    '215': {
        content: '',
        submitted_by: '',
        answers: ["The Matrix Resurrections"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Action, Adventure',
        release_year: '2021',
        notable_actor: '',
        director: 'The Matrix Resurrections, 2021 director',
    },
    '216': {
        content: '',
        submitted_by: '',
        answers: ["The Pale Blue Eye"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Crime',
        release_year: '2022',
        notable_actor: '',
        director: 'The Pale Blue Eye, 2022 director',
    },
    '217': {
        content: '',
        submitted_by: '',
        answers: ["The Equalizer"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Action, Crime',
        release_year: '2014',
        notable_actor: '',
        director: 'The Equalizer, 2014 director',
    },
    '218': {
        content: '',
        submitted_by: '',
        answers: ["Iron Man 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Science Fiction',
        release_year: '2010',
        notable_actor: '',
        director: 'Iron Man 2, 2010 director',
    },
    '219': {
        content: '',
        submitted_by: '',
        answers: ["Spider-Man: Into the Spider-Verse"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Animation, Science Fiction',
        release_year: '2018',
        notable_actor: '',
        director: 'Spider-Man: Into the Spider-Verse, 2018 director',
    },
    '220': {
        content: '',
        submitted_by: '',
        answers: ["Free Guy"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Adventure, Science Fiction',
        release_year: '2021',
        notable_actor: '',
        director: 'Free Guy, 2021 director',
    },
    '221': {
        content: '',
        submitted_by: '',
        answers: ["Triangle of Sadness"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Drama',
        release_year: '2022',
        notable_actor: '',
        director: 'Triangle of Sadness, 2022 director',
    },
    '222': {
        content: '',
        submitted_by: '',
        answers: ["Ice Age"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Family, Adventure',
        release_year: '2002',
        notable_actor: '',
        director: 'Ice Age, 2002 director',
    },
    '223': {
        content: '',
        submitted_by: '',
        answers: ["Red Notice"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Comedy, Crime, Thriller',
        release_year: '2021',
        notable_actor: '',
        director: 'Red Notice, 2021 director',
    },
    '224': {
        content: '',
        submitted_by: '',
        answers: ["San Andreas"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Drama, Thriller',
        release_year: '2015',
        notable_actor: '',
        director: 'San Andreas, 2015 director',
    },
    '225': {
        content: '',
        submitted_by: '',
        answers: ["The Conjuring"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2013',
        notable_actor: '',
        director: 'The Conjuring, 2013 director',
    },
    '226': {
        content: '',
        submitted_by: '',
        answers: ["Twilight"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Drama, Romance',
        release_year: '2008',
        notable_actor: '',
        director: 'Twilight, 2008 director',
    },
    '227': {
        content: '',
        submitted_by: '',
        answers: ["I, Robot"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Science Fiction',
        release_year: '2004',
        notable_actor: '',
        director: 'I, Robot, 2004 director',
    },
    '228': {
        content: '',
        submitted_by: '',
        answers: ["Pearl"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror',
        release_year: '2022',
        notable_actor: '',
        director: 'Pearl, 2022 director',
    },
    '229': {
        content: '',
        submitted_by: '',
        answers: ["Deadpool 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Comedy, Adventure',
        release_year: '2018',
        notable_actor: '',
        director: 'Deadpool 2, 2018 director',
    },
    '230': {
        content: '',
        submitted_by: '',
        answers: ["Air"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, History',
        release_year: '2023',
        notable_actor: '',
        director: 'Air, 2023 director',
    },
    '231': {
        content: '',
        submitted_by: '',
        answers: ["Pirates of the Caribbean: At World's End"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy, Action',
        release_year: '2007',
        notable_actor: '',
        director: 'Pirates of the Caribbean: At World\'s End, 2007 director',
    },
    '232': {
        content: '',
        submitted_by: '',
        answers: ["Boyka: Undisputed IV"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Drama',
        release_year: '2016',
        notable_actor: '',
        director: 'Boyka: Undisputed IV, 2016 director',
    },
    '233': {
        content: '',
        submitted_by: '',
        answers: ["Last Seen Alive"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller',
        release_year: '2022',
        notable_actor: '',
        director: 'Last Seen Alive, 2022 director',
    },
    '234': {
        content: '',
        submitted_by: '',
        answers: ["Avengers: Age of Ultron"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '2015',
        notable_actor: '',
        director: 'Avengers: Age of Ultron, 2015 director',
    },
    '235': {
        content: '',
        submitted_by: '',
        answers: ["Wrath of Man"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Crime, Thriller',
        release_year: '2021',
        notable_actor: '',
        director: 'Wrath of Man, 2021 director',
    },
    '236': {
        content: '',
        submitted_by: '',
        answers: ["Mighty Morphin Power Rangers: Once & Always"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Science Fiction, Family',
        release_year: '2023',
        notable_actor: '',
        director: 'Mighty Morphin Power Rangers: Once & Always, 2023 director',
    },
    '237': {
        content: '',
        submitted_by: '',
        answers: ["The Bad Guys"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Action, Adventure, Crime, Comedy, Family',
        release_year: '2022',
        notable_actor: '',
        director: 'The Bad Guys, 2022 director',
    },
    '238': {
        content: '',
        submitted_by: '',
        answers: ["The King's Man"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Thriller, War, Mystery',
        release_year: '2021',
        notable_actor: '',
        director: 'The King\'s Man, 2021 director',
    },
    '239': {
        content: '',
        submitted_by: '',
        answers: ["Cars 3"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Adventure, Comedy, Family',
        release_year: '2017',
        notable_actor: '',
        director: 'Cars 3, 2017 director',
    },
    '240': {
        content: '',
        submitted_by: '',
        answers: ["Clifford the Big Red Dog"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Adventure, Comedy, Fantasy',
        release_year: '2021',
        notable_actor: '',
        director: 'Clifford the Big Red Dog, 2021 director',
    },
    '241': {
        content: '',
        submitted_by: '',
        answers: ["Grimcutty"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror',
        release_year: '2022',
        notable_actor: '',
        director: 'Grimcutty, 2022 director',
    },
    '242': {
        content: '',
        submitted_by: '',
        answers: ["A Quiet Place"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Drama, Science Fiction',
        release_year: '2018',
        notable_actor: '',
        director: 'A Quiet Place, 2018 director',
    },
    '243': {
        content: '',
        submitted_by: '',
        answers: ["Hidden Figures"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, History',
        release_year: '2016',
        notable_actor: '',
        director: 'Hidden Figures, 2016 director',
    },
    '244': {
        content: '',
        submitted_by: '',
        answers: ["Transformers: The Last Knight"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '2017',
        notable_actor: '',
        director: 'Transformers: The Last Knight, 2017 director',
    },
    '245': {
        content: '',
        submitted_by: '',
        answers: ["Magic Mike's Last Dance"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Drama, Romance',
        release_year: '2023',
        notable_actor: '',
        director: 'Magic Mike\'s Last Dance, 2023 director',
    },
    '246': {
        content: '',
        submitted_by: '',
        answers: ["Law Abiding Citizen"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Crime, Thriller',
        release_year: '2009',
        notable_actor: '',
        director: 'Law Abiding Citizen, 2009 director',
    },
    '247': {
        content: '',
        submitted_by: '',
        answers: ["Slumberland"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Fantasy',
        release_year: '2022',
        notable_actor: '',
        director: 'Slumberland, 2022 director',
    },
    '248': {
        content: '',
        submitted_by: '',
        answers: ["Fury"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'War, Drama, Action',
        release_year: '2014',
        notable_actor: '',
        director: 'Fury, 2014 director',
    },
    '249': {
        content: '',
        submitted_by: '',
        answers: ["Dragon Ball Z: Broly \u2013 The Legendary Super Saiyan"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Science Fiction, Action',
        release_year: '1993',
        notable_actor: '',
        director: 'Dragon Ball Z: Broly – The Legendary Super Saiyan, 1993 director',
    },
    '250': {
        content: '',
        submitted_by: '',
        answers: ["Spider-Man"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Action',
        release_year: '2002',
        notable_actor: '',
        director: 'Spider-Man, 2002 director',
    },
    '251': {
        content: '',
        submitted_by: '',
        answers: ["Grown Ups 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2013',
        notable_actor: '',
        director: 'Grown Ups 2, 2013 director',
    },
    '252': {
        content: '',
        submitted_by: '',
        answers: ["A Bronx Tale"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Crime',
        release_year: '1993',
        notable_actor: '',
        director: 'A Bronx Tale, 1993 director',
    },
    '253': {
        content: '',
        submitted_by: '',
        answers: ["The Amazing Spider-Man"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Fantasy',
        release_year: '2012',
        notable_actor: '',
        director: 'The Amazing Spider-Man, 2012 director',
    },
    '254': {
        content: '',
        submitted_by: '',
        answers: ["Scream 4"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery',
        release_year: '2011',
        notable_actor: '',
        director: 'Scream 4, 2011 director',
    },
    '255': {
        content: '',
        submitted_by: '',
        answers: ["Spider-Man: Homecoming"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Science Fiction, Drama',
        release_year: '2017',
        notable_actor: '',
        director: 'Spider-Man: Homecoming, 2017 director',
    },
    '256': {
        content: '',
        submitted_by: '',
        answers: ["The Platform"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Science Fiction, Thriller',
        release_year: '2019',
        notable_actor: '',
        director: 'The Platform, 2019 director',
    },
    '257': {
        content: '',
        submitted_by: '',
        answers: ["Hitman: Agent 47"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Crime, Thriller',
        release_year: '2015',
        notable_actor: '',
        director: 'Hitman: Agent 47, 2015 director',
    },
    '258': {
        content: '',
        submitted_by: '',
        answers: ["Spirited Away"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Fantasy',
        release_year: '2001',
        notable_actor: '',
        director: 'Spirited Away, 2001 director',
    },
    '259': {
        content: '',
        submitted_by: '',
        answers: ["Monster High: The Movie"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Fantasy, Comedy, Adventure, Music, TV Movie',
        release_year: '2022',
        notable_actor: '',
        director: 'Monster High: The Movie, 2022 director',
    },
    '260': {
        content: '',
        submitted_by: '',
        answers: ["Apocalypse Now"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, War',
        release_year: '1979',
        notable_actor: '',
        director: 'Apocalypse Now, 1979 director',
    },
    '261': {
        content: '',
        submitted_by: '',
        answers: ["The Gray Man"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller',
        release_year: '2022',
        notable_actor: '',
        director: 'The Gray Man, 2022 director',
    },
    '262': {
        content: '',
        submitted_by: '',
        answers: ["Everything Everywhere All at Once"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '2022',
        notable_actor: '',
        director: 'Everything Everywhere All at Once, 2022 director',
    },
    '263': {
        content: '',
        submitted_by: '',
        answers: ["Dragon Ball Z: Wrath of the Dragon"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Action, Science Fiction',
        release_year: '1995',
        notable_actor: '',
        director: 'Dragon Ball Z: Wrath of the Dragon, 1995 director',
    },
    '264': {
        content: '',
        submitted_by: '',
        answers: ["Don't Hang Up"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2016',
        notable_actor: '',
        director: 'Don\'t Hang Up, 2016 director',
    },
    '265': {
        content: '',
        submitted_by: '',
        answers: ["Lou"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller',
        release_year: '2022',
        notable_actor: '',
        director: 'Lou, 2022 director',
    },
    '266': {
        content: '',
        submitted_by: '',
        answers: ["Mulan"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Adventure',
        release_year: '1998',
        notable_actor: '',
        director: 'Mulan, 1998 director',
    },
    '267': {
        content: '',
        submitted_by: '',
        answers: ["The Shawshank Redemption"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Crime',
        release_year: '1994',
        notable_actor: '',
        director: 'The Shawshank Redemption, 1994 director',
    },
    '268': {
        content: '',
        submitted_by: '',
        answers: ["Groot's First Steps"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Comedy, Science Fiction',
        release_year: '2022',
        notable_actor: '',
        director: 'Groot\'s First Steps, 2022 director',
    },
    '269': {
        content: '',
        submitted_by: '',
        answers: ["Cars 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Adventure, Comedy',
        release_year: '2011',
        notable_actor: '',
        director: 'Cars 2, 2011 director',
    },
    '270': {
        content: '',
        submitted_by: '',
        answers: ["Toy Story 4"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Adventure, Animation, Comedy, Fantasy',
        release_year: '2019',
        notable_actor: '',
        director: 'Toy Story 4, 2019 director',
    },
    '271': {
        content: '',
        submitted_by: '',
        answers: ["Sonic the Hedgehog"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Science Fiction, Comedy, Family',
        release_year: '2020',
        notable_actor: '',
        director: 'Sonic the Hedgehog, 2020 director',
    },
    '272': {
        content: '',
        submitted_by: '',
        answers: ["PAW Patrol: The Movie"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Adventure, Comedy',
        release_year: '2021',
        notable_actor: '',
        director: 'PAW Patrol: The Movie, 2021 director',
    },
    '273': {
        content: '',
        submitted_by: '',
        answers: ["Shrek Forever After"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Adventure, Fantasy, Animation, Family',
        release_year: '2010',
        notable_actor: '',
        director: 'Shrek Forever After, 2010 director',
    },
    '274': {
        content: '',
        submitted_by: '',
        answers: ["Good Will Hunting"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '1997',
        notable_actor: '',
        director: 'Good Will Hunting, 1997 director',
    },
    '275': {
        content: '',
        submitted_by: '',
        answers: ["Geostorm"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Science Fiction, Thriller',
        release_year: '2017',
        notable_actor: '',
        director: 'Geostorm, 2017 director',
    },
    '276': {
        content: '',
        submitted_by: '',
        answers: ["Frozen"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Adventure, Fantasy',
        release_year: '2013',
        notable_actor: '',
        director: 'Frozen, 2013 director',
    },
    '277': {
        content: '',
        submitted_by: '',
        answers: ["Kung Fu Panda"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Animation, Family, Comedy',
        release_year: '2008',
        notable_actor: '',
        director: 'Kung Fu Panda, 2008 director',
    },
    '278': {
        content: '',
        submitted_by: '',
        answers: ["Toy Story 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Family',
        release_year: '1999',
        notable_actor: '',
        director: 'Toy Story 2, 1999 director',
    },
    '279': {
        content: '',
        submitted_by: '',
        answers: ["Monster Hunter"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Fantasy, Adventure',
        release_year: '2020',
        notable_actor: '',
        director: 'Monster Hunter, 2020 director',
    },
    '280': {
        content: '',
        submitted_by: '',
        answers: ["The Nightmare Before Christmas"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Animation, Family',
        release_year: '1993',
        notable_actor: '',
        director: 'The Nightmare Before Christmas, 1993 director',
    },
    '281': {
        content: '',
        submitted_by: '',
        answers: ["Scream 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery',
        release_year: '1997',
        notable_actor: '',
        director: 'Scream 2, 1997 director',
    },
    '282': {
        content: '',
        submitted_by: '',
        answers: ["Ghostbusters: Afterlife"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Comedy, Adventure',
        release_year: '2021',
        notable_actor: '',
        director: 'Ghostbusters: Afterlife, 2021 director',
    },
    '283': {
        content: '',
        submitted_by: '',
        answers: ["Shrek the Third"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Adventure, Animation, Comedy, Family',
        release_year: '2007',
        notable_actor: '',
        director: 'Shrek the Third, 2007 director',
    },
    '284': {
        content: '',
        submitted_by: '',
        answers: ["The Hobbit: The Battle of the Five Armies"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Fantasy',
        release_year: '2014',
        notable_actor: '',
        director: 'The Hobbit: The Battle of the Five Armies, 2014 director',
    },
    '285': {
        content: '',
        submitted_by: '',
        answers: ["Halloween Ends"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2022',
        notable_actor: '',
        director: 'Halloween Ends, 2022 director',
    },
    '286': {
        content: '',
        submitted_by: '',
        answers: ["Pirates of the Caribbean: Dead Man's Chest"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy, Action',
        release_year: '2006',
        notable_actor: '',
        director: 'Pirates of the Caribbean: Dead Man\'s Chest, 2006 director',
    },
    '287': {
        content: '',
        submitted_by: '',
        answers: ["Blacklight"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller',
        release_year: '2022',
        notable_actor: '',
        director: 'Blacklight, 2022 director',
    },
    '288': {
        content: '',
        submitted_by: '',
        answers: ["Black Widow"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '2021',
        notable_actor: '',
        director: 'Black Widow, 2021 director',
    },
    '289': {
        content: '',
        submitted_by: '',
        answers: ["Brothers"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Thriller, War',
        release_year: '2009',
        notable_actor: '',
        director: 'Brothers, 2009 director',
    },
    '290': {
        content: '',
        submitted_by: '',
        answers: ["Up"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Family, Adventure',
        release_year: '2009',
        notable_actor: '',
        director: 'Up, 2009 director',
    },
    '291': {
        content: '',
        submitted_by: '',
        answers: ["The Parent Trap"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Family',
        release_year: '1998',
        notable_actor: '',
        director: 'The Parent Trap, 1998 director',
    },
    '292': {
        content: '',
        submitted_by: '',
        answers: ["American Sniper"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'War, Action',
        release_year: '2014',
        notable_actor: '',
        director: 'American Sniper, 2014 director',
    },
    '293': {
        content: '',
        submitted_by: '',
        answers: ["Ghost in the Shell"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'tbd',
        genre: 'Science Fiction, Drama, Action',
        release_year: '2017',
        notable_actor: 'tbd',
        director: 'Ghost in the Shell, 2017 director',
    },
    '294': {
        content: '',
        submitted_by: '',
        answers: ["Frozen II"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Animation, Adventure, Comedy, Fantasy',
        release_year: '2019',
        notable_actor: '',
        director: 'Frozen II, 2019 director',
    },
    '295': {
        content: '',
        submitted_by: '',
        answers: ["Watcher"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Mystery, Thriller, Horror',
        release_year: '2022',
        notable_actor: '',
        director: 'Watcher, 2022 director',
    },
    '296': {
        content: '',
        submitted_by: '',
        answers: ["Thor"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy, Action',
        release_year: '2011',
        notable_actor: '',
        director: 'Thor, 2011 director',
    },
    '297': {
        content: '',
        submitted_by: '',
        answers: ["Howl's Moving Castle"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Animation, Adventure',
        release_year: '2004',
        notable_actor: '',
        director: 'Howl\'s Moving Castle, 2004 director',
    },
    '298': {
        content: '',
        submitted_by: '',
        answers: ["The Hunger Games: Catching Fire"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Science Fiction',
        release_year: '2013',
        notable_actor: '',
        director: 'The Hunger Games: Catching Fire, 2013 director',
    },
    '299': {
        content: '',
        submitted_by: '',
        answers: ["Miraculous World: New York, United HeroeZ"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, TV Movie, Fantasy, Action',
        release_year: '2020',
        notable_actor: '',
        director: 'Miraculous World: New York, United HeroeZ, 2020 director',
    },
    '300': {
        content: '',
        submitted_by: '',
        answers: ["The Requin"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Horror',
        release_year: '2022',
        notable_actor: '',
        director: 'The Requin, 2022 director',
    },
    '301': {
        content: '',
        submitted_by: '',
        answers: ["The Unbearable Weight of Massive Talent"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Comedy, Crime',
        release_year: '2022',
        notable_actor: '',
        director: 'The Unbearable Weight of Massive Talent, 2022 director',
    },
    '302': {
        content: '',
        submitted_by: '',
        answers: ["Thor: Ragnarok"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Fantasy, Science Fiction, Comedy',
        release_year: '2017',
        notable_actor: '',
        director: 'Thor: Ragnarok, 2017 director',
    },
    '303': {
        content: '',
        submitted_by: '',
        answers: ["Space Jam: A New Legacy"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Animation, Comedy, Science Fiction',
        release_year: '2021',
        notable_actor: '',
        director: 'Space Jam: A New Legacy, 2021 director',
    },
    '304': {
        content: '',
        submitted_by: '',
        answers: ["Undisputed III: Redemption"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller',
        release_year: '2010',
        notable_actor: '',
        director: 'Undisputed III: Redemption, 2010 director',
    },
    '305': {
        content: '',
        submitted_by: '',
        answers: ["Finding Nemo"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family',
        release_year: '2003',
        notable_actor: '',
        director: 'Finding Nemo, 2003 director',
    },
    '306': {
        content: '',
        submitted_by: '',
        answers: ["The Meg"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Science Fiction, Horror',
        release_year: '2018',
        notable_actor: '',
        director: 'The Meg, 2018 director',
    },
    '307': {
        content: '',
        submitted_by: '',
        answers: ["The Exorcist"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror',
        release_year: '1973',
        notable_actor: '',
        director: 'The Exorcist, 1973 director',
    },
    '308': {
        content: '',
        submitted_by: '',
        answers: ["Nobody"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Crime, Thriller',
        release_year: '2021',
        notable_actor: '',
        director: 'Nobody, 2021 director',
    },
    '309': {
        content: '',
        submitted_by: '',
        answers: ["Grown Ups"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2010',
        notable_actor: '',
        director: 'Grown Ups, 2010 director',
    },
    '310': {
        content: '',
        submitted_by: '',
        answers: ["The Hangover"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2009',
        notable_actor: '',
        director: 'The Hangover, 2009 director',
    },
    '311': {
        content: '',
        submitted_by: '',
        answers: ["Mary Magdalene"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, History',
        release_year: '2018',
        notable_actor: '',
        director: 'Mary Magdalene, 2018 director',
    },
    '312': {
        content: '',
        submitted_by: '',
        answers: ["Scary Movie"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2000',
        notable_actor: '',
        director: 'Scary Movie, 2000 director',
    },
    '313': {
        content: '',
        submitted_by: '',
        answers: ["The Unholy"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery',
        release_year: '2021',
        notable_actor: '',
        director: 'The Unholy, 2021 director',
    },
    '314': {
        content: '',
        submitted_by: '',
        answers: ["Day Shift"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Fantasy, Horror, Comedy',
        release_year: '2022',
        notable_actor: '',
        director: 'Day Shift, 2022 director',
    },
    '315': {
        content: '',
        submitted_by: '',
        answers: ["Ready Player One"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Adventure, Action',
        release_year: '2018',
        notable_actor: '',
        director: 'Ready Player One, 2018 director',
    },
    '316': {
        content: '',
        submitted_by: '',
        answers: ["Dragon Ball Z: Broly \u2013 Second Coming"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Action, Science Fiction',
        release_year: '1994',
        notable_actor: '',
        director: 'Dragon Ball Z: Broly – Second Coming, 1994 director',
    },
    '317': {
        content: '',
        submitted_by: '',
        answers: ["Get Out"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Mystery, Thriller, Horror',
        release_year: '2017',
        notable_actor: '',
        director: 'Get Out, 2017 director',
    },
    '318': {
        content: '',
        submitted_by: '',
        answers: ["Monsters University"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family',
        release_year: '2013',
        notable_actor: '',
        director: 'Monsters University, 2013 director',
    },
    '319': {
        content: '',
        submitted_by: '',
        answers: ["Jungle Cruise"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Fantasy',
        release_year: '2021',
        notable_actor: '',
        director: 'Jungle Cruise, 2021 director',
    },
    '320': {
        content: '',
        submitted_by: '',
        answers: ["The Menu"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller, Comedy',
        release_year: '2022',
        notable_actor: '',
        director: 'The Menu, 2022 director',
    },
    '321': {
        content: '',
        submitted_by: '',
        answers: ["Pinocchio"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Adventure, Family',
        release_year: '2022',
        notable_actor: '',
        director: 'Pinocchio, 2022 director',
    },
    '322': {
        content: '',
        submitted_by: '',
        answers: ["World War Z"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Drama, Horror, Science Fiction, Thriller',
        release_year: '2013',
        notable_actor: '',
        director: 'World War Z, 2013 director',
    },
    '323': {
        content: '',
        submitted_by: '',
        answers: ["The Princess"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Action',
        release_year: '2022',
        notable_actor: '',
        director: 'The Princess, 2022 director',
    },
    '324': {
        content: '',
        submitted_by: '',
        answers: ["Pacific Rim"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Science Fiction, Adventure',
        release_year: '2013',
        notable_actor: '',
        director: 'Pacific Rim, 2013 director',
    },
    '325': {
        content: '',
        submitted_by: '',
        answers: ["Kingsman: The Secret Service"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Crime, Comedy, Action, Adventure',
        release_year: '2014',
        notable_actor: '',
        director: 'Kingsman: The Secret Service, 2014 director',
    },
    '326': {
        content: '',
        submitted_by: '',
        answers: ["Evil Dead II"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Comedy, Fantasy',
        release_year: '1987',
        notable_actor: '',
        director: 'Evil Dead II, 1987 director',
    },
    '327': {
        content: '',
        submitted_by: '',
        answers: ["Scream 3"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery',
        release_year: '2000',
        notable_actor: '',
        director: 'Scream 3, 2000 director',
    },
    '328': {
        content: '',
        submitted_by: '',
        answers: ["Wonder Woman 1984"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Fantasy',
        release_year: '2020',
        notable_actor: '',
        director: 'Wonder Woman 1984, 2020 director',
    },
    '329': {
        content: '',
        submitted_by: '',
        answers: ["Decision to Leave"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Crime, Drama, Mystery, Romance, Thriller',
        release_year: '2022',
        notable_actor: '',
        director: 'Decision to Leave, 2022 director',
    },
    '330': {
        content: '',
        submitted_by: '',
        answers: ["Aquaman"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Fantasy',
        release_year: '2018',
        notable_actor: '',
        director: 'Aquaman, 2018 director',
    },
    '331': {
        content: '',
        submitted_by: '',
        answers: ["Inside Out"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Adventure, Drama, Comedy',
        release_year: '2015',
        notable_actor: '',
        director: 'Inside Out, 2015 director',
    },
    '332': {
        content: '',
        submitted_by: '',
        answers: ["The Twilight Saga: Eclipse"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy, Drama, Romance',
        release_year: '2010',
        notable_actor: '',
        director: 'The Twilight Saga: Eclipse, 2010 director',
    },
    '333': {
        content: '',
        submitted_by: '',
        answers: ["Finch"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Drama, Adventure',
        release_year: '2021',
        notable_actor: '',
        director: 'Finch, 2021 director',
    },
    '334': {
        content: '',
        submitted_by: '',
        answers: ["Shadow in the Cloud"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Action, War',
        release_year: '2020',
        notable_actor: '',
        director: 'Shadow in the Cloud, 2020 director',
    },
    '335': {
        content: '',
        submitted_by: '',
        answers: ["Mulan"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy, Drama, Action',
        release_year: '2020',
        notable_actor: '',
        director: 'Mulan, 2020 director',
    },
    '336': {
        content: '',
        submitted_by: '',
        answers: ["The Adam Project"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Science Fiction',
        release_year: '2022',
        notable_actor: '',
        director: 'The Adam Project, 2022 director',
    },
    '337': {
        content: '',
        submitted_by: '',
        answers: ["Godzilla: King of the Monsters"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Action',
        release_year: '2019',
        notable_actor: '',
        director: 'Godzilla: King of the Monsters, 2019 director',
    },
    '338': {
        content: '',
        submitted_by: '',
        answers: ["The Lion King"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Drama, Family, Animation',
        release_year: '2019',
        notable_actor: '',
        director: 'The Lion King, 2019 director',
    },
    '339': {
        content: '',
        submitted_by: '',
        answers: ["Batman v Superman: Dawn of Justice"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Fantasy',
        release_year: '2016',
        notable_actor: '',
        director: 'Batman v Superman: Dawn of Justice, 2016 director',
    },
    '340': {
        content: '',
        submitted_by: '',
        answers: ["Miraculous World: Shanghai \u2013 The Legend of Ladydragon"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Fantasy, Action, TV Movie',
        release_year: '2021',
        notable_actor: '',
        director: 'Miraculous World: Shanghai – The Legend of Ladydragon, 2021 director',
    },
    '341': {
        content: '',
        submitted_by: '',
        answers: ["The Lord of the Rings: The Fellowship of the Ring"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy, Action',
        release_year: '2001',
        notable_actor: '',
        director: 'The Lord of the Rings: The Fellowship of the Ring, 2001 director',
    },
    '342': {
        content: '',
        submitted_by: '',
        answers: ["Cinderella"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Fantasy, Animation, Romance',
        release_year: '1950',
        notable_actor: '',
        director: 'Cinderella, 1950 director',
    },
    '343': {
        content: '',
        submitted_by: '',
        answers: ["No Time to Die"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Thriller',
        release_year: '2021',
        notable_actor: '',
        director: 'No Time to Die, 2021 director',
    },
    '344': {
        content: '',
        submitted_by: '',
        answers: ["No Exit"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Mystery',
        release_year: '2022',
        notable_actor: '',
        director: 'No Exit, 2022 director',
    },
    '345': {
        content: '',
        submitted_by: '',
        answers: ["Fifty Shades Darker"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Romance',
        release_year: '2017',
        notable_actor: '',
        director: 'Fifty Shades Darker, 2017 director',
    },
    '346': {
        content: '',
        submitted_by: '',
        answers: ["Paws of Fury: The Legend of Hank"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Action, Adventure, Comedy, Family, Western',
        release_year: '2022',
        notable_actor: '',
        director: 'Paws of Fury: The Legend of Hank, 2022 director',
    },
    '347': {
        content: '',
        submitted_by: '',
        answers: ["Scarface"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Crime, Drama',
        release_year: '1983',
        notable_actor: '',
        director: 'Scarface, 1983 director',
    },
    '348': {
        content: '',
        submitted_by: '',
        answers: ["Infinite"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Action, Thriller',
        release_year: '2021',
        notable_actor: '',
        director: 'Infinite, 2021 director',
    },
    '349': {
        content: '',
        submitted_by: '',
        answers: ["Dragon Ball Z: Battle of Gods"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Action, Science Fiction',
        release_year: '2013',
        notable_actor: '',
        director: 'Dragon Ball Z: Battle of Gods, 2013 director',
    },
    '350': {
        content: '',
        submitted_by: '',
        answers: ["The Last: Naruto the Movie"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Romance, Animation',
        release_year: '2014',
        notable_actor: '',
        director: 'The Last: Naruto the Movie, 2014 director',
    },
    '351': {
        content: '',
        submitted_by: '',
        answers: ["Wreck-It Ralph"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Animation, Comedy, Adventure',
        release_year: '2012',
        notable_actor: '',
        director: 'Wreck-It Ralph, 2012 director',
    },
    '352': {
        content: '',
        submitted_by: '',
        answers: ["The SpongeBob Movie: Sponge on the Run"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Animation, Fantasy, Adventure, Comedy',
        release_year: '2020',
        notable_actor: '',
        director: 'The SpongeBob Movie: Sponge on the Run, 2020 director',
    },
    '353': {
        content: '',
        submitted_by: '',
        answers: ["Blended"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Romance',
        release_year: '2014',
        notable_actor: '',
        director: 'Blended, 2014 director',
    },
    '354': {
        content: '',
        submitted_by: '',
        answers: ["The Good Dinosaur"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Animation, Family',
        release_year: '2015',
        notable_actor: '',
        director: 'The Good Dinosaur, 2015 director',
    },
    '355': {
        content: '',
        submitted_by: '',
        answers: ["2012"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '2009',
        notable_actor: '',
        director: '2012, 2009 director',
    },
    '356': {
        content: '',
        submitted_by: '',
        answers: ["xXx: Return of Xander Cage"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Crime',
        release_year: '2017',
        notable_actor: '',
        director: 'xXx: Return of Xander Cage, 2017 director',
    },
    '357': {
        content: '',
        submitted_by: '',
        answers: ["Groot Takes a Bath"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Comedy, Science Fiction',
        release_year: '2022',
        notable_actor: '',
        director: 'Groot Takes a Bath, 2022 director',
    },
    '358': {
        content: '',
        submitted_by: '',
        answers: ["Through My Window"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Romance',
        release_year: '2022',
        notable_actor: '',
        director: 'Through My Window, 2022 director',
    },
    '359': {
        content: '',
        submitted_by: '',
        answers: ["Separation"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Drama',
        release_year: '2021',
        notable_actor: '',
        director: 'Separation, 2021 director',
    },
    '360': {
        content: '',
        submitted_by: '',
        answers: ["Inception"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Science Fiction, Adventure',
        release_year: '2010',
        notable_actor: '',
        director: 'Inception, 2010 director',
    },
    '361': {
        content: '',
        submitted_by: '',
        answers: ["Doctor Strange"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Fantasy',
        release_year: '2016',
        notable_actor: '',
        director: 'Doctor Strange, 2016 director',
    },
    '362': {
        content: '',
        submitted_by: '',
        answers: ["The Conjuring 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2016',
        notable_actor: '',
        director: 'The Conjuring 2, 2016 director',
    },
    '363': {
        content: '',
        submitted_by: '',
        answers: ["Charlie and the Chocolate Factory"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Comedy, Family, Fantasy',
        release_year: '2005',
        notable_actor: '',
        director: 'Charlie and the Chocolate Factory, 2005 director',
    },
    '364': {
        content: '',
        submitted_by: '',
        answers: ["Tinker Bell and the Legend of the NeverBeast"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Animation, Family',
        release_year: '2014',
        notable_actor: '',
        director: 'Tinker Bell and the Legend of the NeverBeast, 2014 director',
    },
    '365': {
        content: '',
        submitted_by: '',
        answers: ["The Nun"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery, Thriller',
        release_year: '2018',
        notable_actor: '',
        director: 'The Nun, 2018 director',
    },
    '366': {
        content: '',
        submitted_by: '',
        answers: ["Madagascar"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Animation, Adventure, Comedy',
        release_year: '2005',
        notable_actor: '',
        director: 'Madagascar, 2005 director',
    },
    '367': {
        content: '',
        submitted_by: '',
        answers: ["Ted 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Fantasy',
        release_year: '2015',
        notable_actor: '',
        director: 'Ted 2, 2015 director',
    },
    '368': {
        content: '',
        submitted_by: '',
        answers: ["Zootopia"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Adventure, Family, Comedy',
        release_year: '2016',
        notable_actor: '',
        director: 'Zootopia, 2016 director',
    },
    '369': {
        content: '',
        submitted_by: '',
        answers: ["The Equalizer 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller, Crime',
        release_year: '2018',
        notable_actor: '',
        director: 'The Equalizer 2, 2018 director',
    },
    '370': {
        content: '',
        submitted_by: '',
        answers: ["Batman and Superman: Battle of the Super Sons"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Action, Science Fiction',
        release_year: '2022',
        notable_actor: '',
        director: 'Batman and Superman: Battle of the Super Sons, 2022 director',
    },
    '371': {
        content: '',
        submitted_by: '',
        answers: ["Toy Story 3"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Comedy',
        release_year: '2010',
        notable_actor: '',
        director: 'Toy Story 3, 2010 director',
    },
    '372': {
        content: '',
        submitted_by: '',
        answers: ["Annabelle Comes Home"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery, Thriller',
        release_year: '2019',
        notable_actor: '',
        director: 'Annabelle Comes Home, 2019 director',
    },
    '373': {
        content: '',
        submitted_by: '',
        answers: ["Terrifier"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2016',
        notable_actor: '',
        director: 'Terrifier, 2016 director',
    },
    '374': {
        content: '',
        submitted_by: '',
        answers: ["Dawn of the Planet of the Apes"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Action, Drama, Thriller',
        release_year: '2014',
        notable_actor: '',
        director: 'Dawn of the Planet of the Apes, 2014 director',
    },
    '375': {
        content: '',
        submitted_by: '',
        answers: ["Spider-Man: Far From Home"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '2019',
        notable_actor: '',
        director: 'Spider-Man: Far From Home, 2019 director',
    },
    '376': {
        content: '',
        submitted_by: '',
        answers: ["The Mummy"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Fantasy',
        release_year: '1999',
        notable_actor: '',
        director: 'The Mummy, 1999 director',
    },
    '377': {
        content: '',
        submitted_by: '',
        answers: ["Pirates of the Caribbean: Dead Men Tell No Tales"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Fantasy',
        release_year: '2017',
        notable_actor: '',
        director: 'Pirates of the Caribbean: Dead Men Tell No Tales, 2017 director',
    },
    '378': {
        content: '',
        submitted_by: '',
        answers: ["Corpse Bride"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Romance, Fantasy, Animation',
        release_year: '2005',
        notable_actor: '',
        director: 'Corpse Bride, 2005 director',
    },
    '379': {
        content: '',
        submitted_by: '',
        answers: ["The Addams Family 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Adventure, Comedy, Family, Fantasy, Horror',
        release_year: '2021',
        notable_actor: '',
        director: 'The Addams Family 2, 2021 director',
    },
    '380': {
        content: '',
        submitted_by: '',
        answers: ["Brave"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Adventure, Comedy, Family, Action, Fantasy',
        release_year: '2012',
        notable_actor: '',
        director: 'Brave, 2012 director',
    },
    '381': {
        content: '',
        submitted_by: '',
        answers: ["Terminator Genisys"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Action, Thriller, Adventure',
        release_year: '2015',
        notable_actor: '',
        director: 'Terminator Genisys, 2015 director',
    },
    '382': {
        content: '',
        submitted_by: '',
        answers: ["Chip 'n Dale: Rescue Rangers"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Comedy, Adventure, Mystery',
        release_year: '2022',
        notable_actor: '',
        director: 'Chip \'n Dale: Rescue Rangers, 2022 director',
    },
    '383': {
        content: '',
        submitted_by: '',
        answers: ["Desire"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Romance',
        release_year: '2011',
        notable_actor: '',
        director: 'Desire, 2011 director',
    },
    '384': {
        content: '',
        submitted_by: '',
        answers: ["The Contractor"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller',
        release_year: '2022',
        notable_actor: '',
        director: 'The Contractor, 2022 director',
    },
    '385': {
        content: '',
        submitted_by: '',
        answers: ["Ice Age: Dawn of the Dinosaurs"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Family, Adventure',
        release_year: '2009',
        notable_actor: '',
        director: 'Ice Age: Dawn of the Dinosaurs, 2009 director',
    },
    '386': {
        content: '',
        submitted_by: '',
        answers: ["Your Place or Mine"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Romance, Comedy',
        release_year: '2023',
        notable_actor: '',
        director: 'Your Place or Mine, 2023 director',
    },
    '387': {
        content: '',
        submitted_by: '',
        answers: ["The Shack"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Fantasy',
        release_year: '2017',
        notable_actor: '',
        director: 'The Shack, 2017 director',
    },
    '388': {
        content: '',
        submitted_by: '',
        answers: ["El Infierno"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Crime, Comedy',
        release_year: '2010',
        notable_actor: '',
        director: 'El Infierno, 2010 director',
    },
    '389': {
        content: '',
        submitted_by: '',
        answers: ["Captain America: Civil War"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Science Fiction',
        release_year: '2016',
        notable_actor: '',
        director: 'Captain America: Civil War, 2016 director',
    },
    '390': {
        content: '',
        submitted_by: '',
        answers: ["Dragon Ball Z: Fusion Reborn"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Action, Fantasy, Science Fiction',
        release_year: '1995',
        notable_actor: '',
        director: 'Dragon Ball Z: Fusion Reborn, 1995 director',
    },
    '391': {
        content: '',
        submitted_by: '',
        answers: ["Top Gun"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Romance, Drama, Action',
        release_year: '1986',
        notable_actor: '',
        director: 'Top Gun, 1986 director',
    },
    '392': {
        content: '',
        submitted_by: '',
        answers: ["Harry Potter 20th Anniversary: Return to Hogwarts"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Documentary',
        release_year: '2022',
        notable_actor: '',
        director: 'Harry Potter 20th Anniversary: Return to Hogwarts, 2022 director',
    },
    '393': {
        content: '',
        submitted_by: '',
        answers: ["Rampage"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '2018',
        notable_actor: '',
        director: 'Rampage, 2018 director',
    },
    '394': {
        content: '',
        submitted_by: '',
        answers: ["Tom Clancy's Without Remorse"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Thriller, War',
        release_year: '2021',
        notable_actor: '',
        director: 'Tom Clancy\'s Without Remorse, 2021 director',
    },
    '395': {
        content: '',
        submitted_by: '',
        answers: ["Teenage Mutant Ninja Turtles"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Action, Adventure, Comedy',
        release_year: '2014',
        notable_actor: '',
        director: 'Teenage Mutant Ninja Turtles, 2014 director',
    },
    '396': {
        content: '',
        submitted_by: '',
        answers: ["Puss in Boots"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Fantasy, Animation, Romance, Adventure, Family',
        release_year: '2011',
        notable_actor: '',
        director: 'Puss in Boots, 2011 director',
    },
    '397': {
        content: '',
        submitted_by: '',
        answers: ["Spirit: Stallion of the Cimarron"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Western, Animation, Adventure, Comedy, Family, Drama',
        release_year: '2002',
        notable_actor: '',
        director: 'Spirit: Stallion of the Cimarron, 2002 director',
    },
    '398': {
        content: '',
        submitted_by: '',
        answers: ["Ant-Man"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Action, Adventure',
        release_year: '2015',
        notable_actor: '',
        director: 'Ant-Man, 2015 director',
    },
    '399': {
        content: '',
        submitted_by: '',
        answers: ["Blade Runner 2049"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Drama',
        release_year: '2017',
        notable_actor: '',
        director: 'Blade Runner 2049, 2017 director',
    },
    '400': {
        content: '',
        submitted_by: '',
        answers: ["Red Corner"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Crime, Drama, Thriller',
        release_year: '1997',
        notable_actor: '',
        director: 'Red Corner, 1997 director',
    },
    '401': {
        content: '',
        submitted_by: '',
        answers: ["Wrong Turn 2: Dead End"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2007',
        notable_actor: '',
        director: 'Wrong Turn 2: Dead End, 2007 director',
    },
    '402': {
        content: '',
        submitted_by: '',
        answers: ["Kung Fu Panda 3"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Animation, Comedy, Family',
        release_year: '2016',
        notable_actor: '',
        director: 'Kung Fu Panda 3, 2016 director',
    },
    '403': {
        content: '',
        submitted_by: '',
        answers: ["Venom"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Action',
        release_year: '2018',
        notable_actor: '',
        director: 'Venom, 2018 director',
    },
    '404': {
        content: '',
        submitted_by: '',
        answers: ["Snow White and the Seven Dwarfs"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Animation, Family',
        release_year: '1937',
        notable_actor: '',
        director: 'Snow White and the Seven Dwarfs, 1937 director',
    },
    '405': {
        content: '',
        submitted_by: '',
        answers: ["The Green Mile"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Drama, Crime',
        release_year: '1999',
        notable_actor: '',
        director: 'The Green Mile, 1999 director',
    },
    '406': {
        content: '',
        submitted_by: '',
        answers: ["Sing"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Family, Music',
        release_year: '2016',
        notable_actor: '',
        director: 'Sing, 2016 director',
    },
    '407': {
        content: '',
        submitted_by: '',
        answers: ["Penguins of Madagascar"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Animation, Adventure, Comedy',
        release_year: '2014',
        notable_actor: '',
        director: 'Penguins of Madagascar, 2014 director',
    },
    '408': {
        content: '',
        submitted_by: '',
        answers: ["The Boy in the Striped Pyjamas"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'War, Drama, History',
        release_year: '2008',
        notable_actor: '',
        director: 'The Boy in the Striped Pyjamas, 2008 director',
    },
    '409': {
        content: '',
        submitted_by: '',
        answers: ["Apocalypto"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Drama, History',
        release_year: '2006',
        notable_actor: '',
        director: 'Apocalypto, 2006 director',
    },
    '410': {
        content: '',
        submitted_by: '',
        answers: ["Secret of the Wings"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Fantasy, Adventure',
        release_year: '2012',
        notable_actor: '',
        director: 'Secret of the Wings, 2012 director',
    },
    '411': {
        content: '',
        submitted_by: '',
        answers: ["Source Code"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Science Fiction, Mystery',
        release_year: '2011',
        notable_actor: '',
        director: 'Source Code, 2011 director',
    },
    '412': {
        content: '',
        submitted_by: '',
        answers: ["Ant-Man and the Wasp"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '2018',
        notable_actor: '',
        director: 'Ant-Man and the Wasp, 2018 director',
    },
    '413': {
        content: '',
        submitted_by: '',
        answers: ["The Hobbit: An Unexpected Journey"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy, Action',
        release_year: '2012',
        notable_actor: '',
        director: 'The Hobbit: An Unexpected Journey, 2012 director',
    },
    '414': {
        content: '',
        submitted_by: '',
        answers: ["Soul"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Comedy, Fantasy, Drama',
        release_year: '2020',
        notable_actor: '',
        director: 'Soul, 2020 director',
    },
    '415': {
        content: '',
        submitted_by: '',
        answers: ["Choose or Die"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller, Science Fiction',
        release_year: '2022',
        notable_actor: '',
        director: 'Choose or Die, 2022 director',
    },
    '416': {
        content: '',
        submitted_by: '',
        answers: ["Elvis"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Music, History',
        release_year: '2022',
        notable_actor: '',
        director: 'Elvis, 2022 director',
    },
    '417': {
        content: '',
        submitted_by: '',
        answers: ["Knowing"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Mystery, Science Fiction, Thriller',
        release_year: '2009',
        notable_actor: '',
        director: 'Knowing, 2009 director',
    },
    '418': {
        content: '',
        submitted_by: '',
        answers: ["Constantine"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Action, Horror',
        release_year: '2005',
        notable_actor: '',
        director: 'Constantine, 2005 director',
    },
    '419': {
        content: '',
        submitted_by: '',
        answers: ["Wrong Turn 3: Left for Dead"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2009',
        notable_actor: '',
        director: 'Wrong Turn 3: Left for Dead, 2009 director',
    },
    '420': {
        content: '',
        submitted_by: '',
        answers: ["Incredibles 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Animation, Family',
        release_year: '2018',
        notable_actor: '',
        director: 'Incredibles 2, 2018 director',
    },
    '421': {
        content: '',
        submitted_by: '',
        answers: ["The Emperor's New Groove"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Animation, Comedy, Family, Fantasy',
        release_year: '2000',
        notable_actor: '',
        director: 'The Emperor\'s New Groove, 2000 director',
    },
    '422': {
        content: '',
        submitted_by: '',
        answers: ["How to Train Your Dragon 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Action, Adventure, Animation, Comedy, Family',
        release_year: '2014',
        notable_actor: '',
        director: 'How to Train Your Dragon 2, 2014 director',
    },
    '423': {
        content: '',
        submitted_by: '',
        answers: ["Scrooge: A Christmas Carol"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Fantasy',
        release_year: '2022',
        notable_actor: '',
        director: 'Scrooge: A Christmas Carol, 2022 director',
    },
    '424': {
        content: '',
        submitted_by: '',
        answers: ["Jumanji: The Next Level"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Comedy, Fantasy',
        release_year: '2019',
        notable_actor: '',
        director: 'Jumanji: The Next Level, 2019 director',
    },
    '425': {
        content: '',
        submitted_by: '',
        answers: ["Hercules"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Fantasy, Adventure, Comedy, Romance',
        release_year: '1997',
        notable_actor: '',
        director: 'Hercules, 1997 director',
    },
    '426': {
        content: '',
        submitted_by: '',
        answers: ["Jurassic World: Fallen Kingdom"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Science Fiction, Thriller',
        release_year: '2018',
        notable_actor: '',
        director: 'Jurassic World: Fallen Kingdom, 2018 director',
    },
    '427': {
        content: '',
        submitted_by: '',
        answers: ["Cinderella"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Romance, Fantasy, Family, Drama',
        release_year: '2015',
        notable_actor: '',
        director: 'Cinderella, 2015 director',
    },
    '428': {
        content: '',
        submitted_by: '',
        answers: ["The Strays"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller',
        release_year: '2023',
        notable_actor: '',
        director: 'The Strays, 2023 director',
    },
    '429': {
        content: '',
        submitted_by: '',
        answers: ["Miss Peregrine's Home for Peculiar Children"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Adventure, Family, Drama',
        release_year: '2016',
        notable_actor: '',
        director: 'Miss Peregrine\'s Home for Peculiar Children, 2016 director',
    },
    '430': {
        content: '',
        submitted_by: '',
        answers: ["The Lord of the Rings: The Return of the King"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy, Action',
        release_year: '2003',
        notable_actor: '',
        director: 'The Lord of the Rings: The Return of the King, 2003 director',
    },
    '431': {
        content: '',
        submitted_by: '',
        answers: ["Tarzan"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Adventure, Animation, Drama',
        release_year: '1999',
        notable_actor: '',
        director: 'Tarzan, 1999 director',
    },
    '432': {
        content: '',
        submitted_by: '',
        answers: ["Taken 3"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Action',
        release_year: '2014',
        notable_actor: '',
        director: 'Taken 3, 2014 director',
    },
    '433': {
        content: '',
        submitted_by: '',
        answers: ["The Incredibles"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Animation, Family',
        release_year: '2004',
        notable_actor: '',
        director: 'The Incredibles, 2004 director',
    },
    '434': {
        content: '',
        submitted_by: '',
        answers: ["Luther: The Fallen Sun"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Crime',
        release_year: '2023',
        notable_actor: '',
        director: 'Luther: The Fallen Sun, 2023 director',
    },
    '435': {
        content: '',
        submitted_by: '',
        answers: ["Meet the Spartans"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2008',
        notable_actor: '',
        director: 'Meet the Spartans, 2008 director',
    },
    '436': {
        content: '',
        submitted_by: '',
        answers: ["Caligula"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, History, Romance',
        release_year: '1979',
        notable_actor: '',
        director: 'Caligula, 1979 director',
    },
    '437': {
        content: '',
        submitted_by: '',
        answers: ["Ralph Breaks the Internet"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Animation, Comedy, Adventure',
        release_year: '2018',
        notable_actor: '',
        director: 'Ralph Breaks the Internet, 2018 director',
    },
    '438': {
        content: '',
        submitted_by: '',
        answers: ["Sex Tape"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2014',
        notable_actor: '',
        director: 'Sex Tape, 2014 director',
    },
    '439': {
        content: '',
        submitted_by: '',
        answers: ["Pulp Fiction"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Crime',
        release_year: '1994',
        notable_actor: '',
        director: 'Pulp Fiction, 1994 director',
    },
    '440': {
        content: '',
        submitted_by: '',
        answers: ["Star Wars: The Rise of Skywalker"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Science Fiction',
        release_year: '2019',
        notable_actor: '',
        director: 'Star Wars: The Rise of Skywalker, 2019 director',
    },
    '441': {
        content: '',
        submitted_by: '',
        answers: ["Percy Jackson & the Olympians: The Lightning Thief"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy, Family',
        release_year: '2010',
        notable_actor: '',
        director: 'Percy Jackson & the Olympians: The Lightning Thief, 2010 director',
    },
    '442': {
        content: '',
        submitted_by: '',
        answers: ["Fight Club"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Thriller, Comedy',
        release_year: '1999',
        notable_actor: '',
        director: 'Fight Club, 1999 director',
    },
    '443': {
        content: '',
        submitted_by: '',
        answers: ["Parasite"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Thriller, Drama',
        release_year: '2019',
        notable_actor: '',
        director: 'Parasite, 2019 director',
    },
    '444': {
        content: '',
        submitted_by: '',
        answers: ["The Gangster, the Cop, the Devil"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Crime, Action, Adventure',
        release_year: '2019',
        notable_actor: '',
        director: 'The Gangster, the Cop, the Devil, 2019 director',
    },
    '445': {
        content: '',
        submitted_by: '',
        answers: ["War of the Worlds"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Thriller, Science Fiction',
        release_year: '2005',
        notable_actor: '',
        director: 'War of the Worlds, 2005 director',
    },
    '446': {
        content: '',
        submitted_by: '',
        answers: ["Texas Chainsaw Massacre"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2022',
        notable_actor: '',
        director: 'Texas Chainsaw Massacre, 2022 director',
    },
    '447': {
        content: '',
        submitted_by: '',
        answers: ["Deathstroke: Knights & Dragons - The Movie"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Action, Adventure, Science Fiction',
        release_year: '2020',
        notable_actor: '',
        director: 'Deathstroke: Knights & Dragons - The Movie, 2020 director',
    },
    '448': {
        content: '',
        submitted_by: '',
        answers: ["Beau Is Afraid"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Adventure, Fantasy',
        release_year: '2023',
        notable_actor: '',
        director: 'Beau Is Afraid, 2023 director',
    },
    '449': {
        content: '',
        submitted_by: '',
        answers: ["The Twilight Saga: Breaking Dawn - Part 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy, Drama, Romance',
        release_year: '2012',
        notable_actor: '',
        director: 'The Twilight Saga: Breaking Dawn - Part 2, 2012 director',
    },
    '450': {
        content: '',
        submitted_by: '',
        answers: ["Kingsman: The Golden Circle"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Comedy',
        release_year: '2017',
        notable_actor: '',
        director: 'Kingsman: The Golden Circle, 2017 director',
    },
    '451': {
        content: '',
        submitted_by: '',
        answers: ["Tinker Bell and the Pirate Fairy"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family',
        release_year: '2014',
        notable_actor: '',
        director: 'Tinker Bell and the Pirate Fairy, 2014 director',
    },
    '452': {
        content: '',
        submitted_by: '',
        answers: ["Justice League"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '2017',
        notable_actor: '',
        director: 'Justice League, 2017 director',
    },
    '453': {
        content: '',
        submitted_by: '',
        answers: ["It Chapter Two"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Fantasy',
        release_year: '2019',
        notable_actor: '',
        director: 'It Chapter Two, 2019 director',
    },
    '454': {
        content: '',
        submitted_by: '',
        answers: ["The Dark Knight Rises"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Crime, Drama, Thriller',
        release_year: '2012',
        notable_actor: '',
        director: 'The Dark Knight Rises, 2012 director',
    },
    '455': {
        content: '',
        submitted_by: '',
        answers: ["Yu-Gi-Oh! The Movie"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Fantasy, Action, Science Fiction',
        release_year: '2004',
        notable_actor: '',
        director: 'Yu-Gi-Oh! The Movie, 2004 director',
    },
    '456': {
        content: '',
        submitted_by: '',
        answers: ["Roald Dahl's The Witches"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy, Comedy, Family, Horror',
        release_year: '2020',
        notable_actor: '',
        director: 'Roald Dahl\'s The Witches, 2020 director',
    },
    '457': {
        content: '',
        submitted_by: '',
        answers: ["Scary Movie 5"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2013',
        notable_actor: '',
        director: 'Scary Movie 5, 2013 director',
    },
    '458': {
        content: '',
        submitted_by: '',
        answers: ["Ambulance"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Action',
        release_year: '2022',
        notable_actor: '',
        director: 'Ambulance, 2022 director',
    },
    '459': {
        content: '',
        submitted_by: '',
        answers: ["Life in a Year"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Romance',
        release_year: '2020',
        notable_actor: '',
        director: 'Life in a Year, 2020 director',
    },
    '460': {
        content: '',
        submitted_by: '',
        answers: ["The Basketball Diaries"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Crime',
        release_year: '1995',
        notable_actor: '',
        director: 'The Basketball Diaries, 1995 director',
    },
    '461': {
        content: '',
        submitted_by: '',
        answers: ["WALL\u00b7E"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Science Fiction',
        release_year: '2008',
        notable_actor: '',
        director: 'WALL·E, 2008 director',
    },
    '462': {
        content: '',
        submitted_by: '',
        answers: ["Star Wars: The Last Jedi"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Science Fiction',
        release_year: '2017',
        notable_actor: '',
        director: 'Star Wars: The Last Jedi, 2017 director',
    },
    '463': {
        content: '',
        submitted_by: '',
        answers: ["The Forever Purge"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Action, Thriller',
        release_year: '2021',
        notable_actor: '',
        director: 'The Forever Purge, 2021 director',
    },
    '464': {
        content: '',
        submitted_by: '',
        answers: ["The Addams Family"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Fantasy',
        release_year: '1991',
        notable_actor: '',
        director: 'The Addams Family, 1991 director',
    },
    '465': {
        content: '',
        submitted_by: '',
        answers: ["Back to the Future"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Comedy, Science Fiction',
        release_year: '1985',
        notable_actor: '',
        director: 'Back to the Future, 1985 director',
    },
    '466': {
        content: '',
        submitted_by: '',
        answers: ["Madagascar: Escape 2 Africa"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Adventure, Animation, Comedy',
        release_year: '2008',
        notable_actor: '',
        director: 'Madagascar: Escape 2 Africa, 2008 director',
    },
    '467': {
        content: '',
        submitted_by: '',
        answers: ["The Wandering Earth"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Action, Drama',
        release_year: '2019',
        notable_actor: '',
        director: 'The Wandering Earth, 2019 director',
    },
    '468': {
        content: '',
        submitted_by: '',
        answers: ["The Hunger Games: Mockingjay - Part 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '2015',
        notable_actor: '',
        director: 'The Hunger Games: Mockingjay - Part 2, 2015 director',
    },
    '469': {
        content: '',
        submitted_by: '',
        answers: ["Men"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller, Fantasy',
        release_year: '2022',
        notable_actor: '',
        director: 'Men, 2022 director',
    },
    '470': {
        content: '',
        submitted_by: '',
        answers: ["Star Wars: The Force Awakens"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Science Fiction, Fantasy',
        release_year: '2015',
        notable_actor: '',
        director: 'Star Wars: The Force Awakens, 2015 director',
    },
    '471': {
        content: '',
        submitted_by: '',
        answers: ["Boruto: Naruto the Movie"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Action, Comedy',
        release_year: '2015',
        notable_actor: '',
        director: 'Boruto: Naruto the Movie, 2015 director',
    },
    '472': {
        content: '',
        submitted_by: '',
        answers: ["Don't Breathe 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Horror',
        release_year: '2021',
        notable_actor: '',
        director: 'Don\'t Breathe 2, 2021 director',
    },
    '473': {
        content: '',
        submitted_by: '',
        answers: ["The Simpsons Movie"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Family',
        release_year: '2007',
        notable_actor: '',
        director: 'The Simpsons Movie, 2007 director',
    },
    '474': {
        content: '',
        submitted_by: '',
        answers: ["Hellboy"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'tbd',
        genre: 'Fantasy, Action',
        release_year: '2004',
        notable_actor: 'tbd',
        director: 'Hellboy, 2004 director',
    },
    '475': {
        content: '',
        submitted_by: '',
        answers: ["Thor: The Dark World"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Fantasy',
        release_year: '2013',
        notable_actor: '',
        director: 'Thor: The Dark World, 2013 director',
    },
    '476': {
        content: '',
        submitted_by: '',
        answers: ["The Autopsy of Jane Doe"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery',
        release_year: '2016',
        notable_actor: '',
        director: 'The Autopsy of Jane Doe, 2016 director',
    },
    '477': {
        content: '',
        submitted_by: '',
        answers: ["Maleficent"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Adventure, Action, Family, Romance',
        release_year: '2014',
        notable_actor: '',
        director: 'Maleficent, 2014 director',
    },
    '478': {
        content: '',
        submitted_by: '',
        answers: ["The Devil Wears Prada"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Drama, Romance',
        release_year: '2006',
        notable_actor: '',
        director: 'The Devil Wears Prada, 2006 director',
    },
    '479': {
        content: '',
        submitted_by: '',
        answers: ["Project X"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2012',
        notable_actor: '',
        director: 'Project X, 2012 director',
    },
    '480': {
        content: '',
        submitted_by: '',
        answers: ["Spirit Untamed"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Adventure, Family, Western',
        release_year: '2021',
        notable_actor: '',
        director: 'Spirit Untamed, 2021 director',
    },
    '481': {
        content: '',
        submitted_by: '',
        answers: ["Power Rangers"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '2017',
        notable_actor: '',
        director: 'Power Rangers, 2017 director',
    },
    '482': {
        content: '',
        submitted_by: '',
        answers: ["Money Shot: The Pornhub Story"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Documentary',
        release_year: '2023',
        notable_actor: '',
        director: 'Money Shot: The Pornhub Story, 2023 director',
    },
    '483': {
        content: '',
        submitted_by: '',
        answers: ["Iron Man 3"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '2013',
        notable_actor: '',
        director: 'Iron Man 3, 2013 director',
    },
    '484': {
        content: '',
        submitted_by: '',
        answers: ["Wrong Turn"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller, Drama',
        release_year: '2021',
        notable_actor: '',
        director: 'Wrong Turn, 2021 director',
    },
    '485': {
        content: '',
        submitted_by: '',
        answers: ["The Lion King II: Simba's Pride"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Adventure, Animation, Action',
        release_year: '1998',
        notable_actor: '',
        director: 'The Lion King II: Simba\'s Pride, 1998 director',
    },
    '486': {
        content: '',
        submitted_by: '',
        answers: ["It"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Fantasy',
        release_year: '2017',
        notable_actor: '',
        director: 'It, 2017 director',
    },
    '487': {
        content: '',
        submitted_by: '',
        answers: ["The Lord of the Rings: The Two Towers"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy, Action',
        release_year: '2002',
        notable_actor: '',
        director: 'The Lord of the Rings: The Two Towers, 2002 director',
    },
    '488': {
        content: '',
        submitted_by: '',
        answers: ["Tom & Jerry"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Family, Animation',
        release_year: '2021',
        notable_actor: '',
        director: 'Tom & Jerry, 2021 director',
    },
    '489': {
        content: '',
        submitted_by: '',
        answers: ["Road to Ninja: Naruto the Movie"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Fantasy, Action',
        release_year: '2012',
        notable_actor: '',
        director: 'Road to Ninja: Naruto the Movie, 2012 director',
    },
    '490': {
        content: '',
        submitted_by: '',
        answers: ["Terminator: Dark Fate"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Action',
        release_year: '2019',
        notable_actor: '',
        director: 'Terminator: Dark Fate, 2019 director',
    },
    '491': {
        content: '',
        submitted_by: '',
        answers: ["Three Thousand Years of Longing"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy',
        release_year: '2022',
        notable_actor: '',
        director: 'Three Thousand Years of Longing, 2022 director',
    },
    '492': {
        content: '',
        submitted_by: '',
        answers: ["Heat"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Crime, Drama, Thriller',
        release_year: '1995',
        notable_actor: '',
        director: 'Heat, 1995 director',
    },
    '493': {
        content: '',
        submitted_by: '',
        answers: ["Rio"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Adventure, Comedy, Family',
        release_year: '2011',
        notable_actor: '',
        director: 'Rio, 2011 director',
    },
    '494': {
        content: '',
        submitted_by: '',
        answers: ["Go!"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family',
        release_year: '2020',
        notable_actor: '',
        director: 'Go!, 2020 director',
    },
    '495': {
        content: '',
        submitted_by: '',
        answers: ["Journey 2: The Mysterious Island"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Science Fiction',
        release_year: '2012',
        notable_actor: '',
        director: 'Journey 2: The Mysterious Island, 2012 director',
    },
    '496': {
        content: '',
        submitted_by: '',
        answers: ["Dragon Ball Z: Cooler's Revenge"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Animation, Science Fiction',
        release_year: '1991',
        notable_actor: '',
        director: 'Dragon Ball Z: Cooler\'s Revenge, 1991 director',
    },
    '497': {
        content: '',
        submitted_by: '',
        answers: ["The Twilight Saga: Breaking Dawn - Part 1"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy, Romance',
        release_year: '2011',
        notable_actor: '',
        director: 'The Twilight Saga: Breaking Dawn - Part 1, 2011 director',
    },
    '498': {
        content: '',
        submitted_by: '',
        answers: ["Train to Busan"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller, Adventure, Action',
        release_year: '2016',
        notable_actor: '',
        director: 'Train to Busan, 2016 director',
    },
    '499': {
        content: '',
        submitted_by: '',
        answers: ["Snake Eyes: G.I. Joe Origins"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure',
        release_year: '2021',
        notable_actor: '',
        director: 'Snake Eyes: G.I. Joe Origins, 2021 director',
    },
    '500': {
        content: '',
        submitted_by: '',
        answers: ["Malignant"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery, Thriller',
        release_year: '2021',
        notable_actor: '',
        director: 'Malignant, 2021 director',
    },
    '501': {
        content: '',
        submitted_by: '',
        answers: ["Despicable Me 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Family',
        release_year: '2013',
        notable_actor: '',
        director: 'Despicable Me 2, 2013 director',
    },
    '502': {
        content: '',
        submitted_by: '',
        answers: ["Despicable Me"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Animation, Comedy, Crime',
        release_year: '2010',
        notable_actor: '',
        director: 'Despicable Me, 2010 director',
    },
    '503': {
        content: '',
        submitted_by: '',
        answers: ["Jack Reacher: Never Go Back"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller',
        release_year: '2016',
        notable_actor: '',
        director: 'Jack Reacher: Never Go Back, 2016 director',
    },
    '504': {
        content: '',
        submitted_by: '',
        answers: ["Pok\u00e9mon Detective Pikachu"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Fantasy',
        release_year: '2019',
        notable_actor: '',
        director: 'Pokémon Detective Pikachu, 2019 director',
    },
    '505': {
        content: '',
        submitted_by: '',
        answers: ["The House"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Drama, Comedy, Horror, Fantasy',
        release_year: '2022',
        notable_actor: '',
        director: 'The House, 2022 director',
    },
    '506': {
        content: '',
        submitted_by: '',
        answers: ["Pacific Rim: Uprising"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Fantasy, Science Fiction, Adventure',
        release_year: '2018',
        notable_actor: '',
        director: 'Pacific Rim: Uprising, 2018 director',
    },
    '507': {
        content: '',
        submitted_by: '',
        answers: ["Alien"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Science Fiction',
        release_year: '1979',
        notable_actor: '',
        director: 'Alien, 1979 director',
    },
    '508': {
        content: '',
        submitted_by: '',
        answers: ["Kung Fu Panda 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family',
        release_year: '2011',
        notable_actor: '',
        director: 'Kung Fu Panda 2, 2011 director',
    },
    '509': {
        content: '',
        submitted_by: '',
        answers: ["Predator"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Action, Adventure, Thriller',
        release_year: '1987',
        notable_actor: '',
        director: 'Predator, 1987 director',
    },
    '510': {
        content: '',
        submitted_by: '',
        answers: ["Rambo"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller',
        release_year: '2008',
        notable_actor: '',
        director: 'Rambo, 2008 director',
    },
    '511': {
        content: '',
        submitted_by: '',
        answers: ["The Outfit"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Crime',
        release_year: '2022',
        notable_actor: '',
        director: 'The Outfit, 2022 director',
    },
    '512': {
        content: '',
        submitted_by: '',
        answers: ["Saving Private Ryan"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, History, War',
        release_year: '1998',
        notable_actor: '',
        director: 'Saving Private Ryan, 1998 director',
    },
    '513': {
        content: '',
        submitted_by: '',
        answers: ["Godzilla"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Drama, Science Fiction',
        release_year: '2014',
        notable_actor: '',
        director: 'Godzilla, 2014 director',
    },
    '514': {
        content: '',
        submitted_by: '',
        answers: ["Beauty and the Beast"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Romance, Family, Animation, Fantasy',
        release_year: '1991',
        notable_actor: '',
        director: 'Beauty and the Beast, 1991 director',
    },
    '515': {
        content: '',
        submitted_by: '',
        answers: ["Spider-Man 3"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Action, Adventure',
        release_year: '2007',
        notable_actor: '',
        director: 'Spider-Man 3, 2007 director',
    },
    '516': {
        content: '',
        submitted_by: '',
        answers: ["The Proposal"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Romance, Drama',
        release_year: '2009',
        notable_actor: '',
        director: 'The Proposal, 2009 director',
    },
    '517': {
        content: '',
        submitted_by: '',
        answers: ["Addams Family Values"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Family, Fantasy',
        release_year: '1993',
        notable_actor: '',
        director: 'Addams Family Values, 1993 director',
    },
    '518': {
        content: '',
        submitted_by: '',
        answers: ["The Hangover Part II"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2011',
        notable_actor: '',
        director: 'The Hangover Part II, 2011 director',
    },
    '519': {
        content: '',
        submitted_by: '',
        answers: ["Annabelle: Creation"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery, Thriller',
        release_year: '2017',
        notable_actor: '',
        director: 'Annabelle: Creation, 2017 director',
    },
    '520': {
        content: '',
        submitted_by: '',
        answers: ["A Bug's Life"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Animation, Comedy, Family',
        release_year: '1998',
        notable_actor: '',
        director: 'A Bug\'s Life, 1998 director',
    },
    '521': {
        content: '',
        submitted_by: '',
        answers: ["Man of Steel"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '2013',
        notable_actor: '',
        director: 'Man of Steel, 2013 director',
    },
    '522': {
        content: '',
        submitted_by: '',
        answers: ["Beauty and the Beast"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Fantasy, Romance',
        release_year: '2017',
        notable_actor: '',
        director: 'Beauty and the Beast, 2017 director',
    },
    '523': {
        content: '',
        submitted_by: '',
        answers: ["Alien: Covenant"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Horror, Mystery',
        release_year: '2017',
        notable_actor: '',
        director: 'Alien: Covenant, 2017 director',
    },
    '524': {
        content: '',
        submitted_by: '',
        answers: ["Hustle"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Comedy',
        release_year: '2022',
        notable_actor: '',
        director: 'Hustle, 2022 director',
    },
    '525': {
        content: '',
        submitted_by: '',
        answers: ["The Witch"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Fantasy, Horror, Mystery',
        release_year: '2015',
        notable_actor: '',
        director: 'The Witch, 2015 director',
    },
    '526': {
        content: '',
        submitted_by: '',
        answers: ["Cars"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Adventure, Comedy, Family',
        release_year: '2006',
        notable_actor: '',
        director: 'Cars, 2006 director',
    },
    '527': {
        content: '',
        submitted_by: '',
        answers: ["Gabriel's Inferno: Part III"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Romance, Comedy',
        release_year: '2020',
        notable_actor: '',
        director: 'Gabriel\'s Inferno: Part III, 2020 director',
    },
    '528': {
        content: '',
        submitted_by: '',
        answers: ["Rocky"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Romance',
        release_year: '1976',
        notable_actor: '',
        director: 'Rocky, 1976 director',
    },
    '529': {
        content: '',
        submitted_by: '',
        answers: ["Terminator 2: Judgment Day"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller, Science Fiction',
        release_year: '1991',
        notable_actor: '',
        director: 'Terminator 2: Judgment Day, 1991 director',
    },
    '530': {
        content: '',
        submitted_by: '',
        answers: ["The Help"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '2011',
        notable_actor: '',
        director: 'The Help, 2011 director',
    },
    '531': {
        content: '',
        submitted_by: '',
        answers: ["Death on the Nile"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Mystery, Drama, Crime, Thriller, Action, Adventure',
        release_year: '2022',
        notable_actor: '',
        director: 'Death on the Nile, 2022 director',
    },
    '532': {
        content: '',
        submitted_by: '',
        answers: ["Hereditary"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery, Thriller',
        release_year: '2018',
        notable_actor: '',
        director: 'Hereditary, 2018 director',
    },
    '533': {
        content: '',
        submitted_by: '',
        answers: ["A Haunted House 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Horror',
        release_year: '2014',
        notable_actor: '',
        director: 'A Haunted House 2, 2014 director',
    },
    '534': {
        content: '',
        submitted_by: '',
        answers: ["Scary Movie 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2001',
        notable_actor: '',
        director: 'Scary Movie 2, 2001 director',
    },
    '535': {
        content: '',
        submitted_by: '',
        answers: ["How to Train Your Dragon: Homecoming"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Fantasy, Adventure, Action, Family',
        release_year: '2019',
        notable_actor: '',
        director: 'How to Train Your Dragon: Homecoming, 2019 director',
    },
    '536': {
        content: '',
        submitted_by: '',
        answers: ["Emancipation"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '2022',
        notable_actor: '',
        director: 'Emancipation, 2022 director',
    },
    '537': {
        content: '',
        submitted_by: '',
        answers: ["The Simpsons in Plusaversary"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Fantasy',
        release_year: '2021',
        notable_actor: '',
        director: 'The Simpsons in Plusaversary, 2021 director',
    },
    '538': {
        content: '',
        submitted_by: '',
        answers: ["Alita: Battle Angel"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Science Fiction, Adventure',
        release_year: '2019',
        notable_actor: '',
        director: 'Alita: Battle Angel, 2019 director',
    },
    '539': {
        content: '',
        submitted_by: '',
        answers: ["We Have a Ghost"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Fantasy, Family, Adventure',
        release_year: '2023',
        notable_actor: '',
        director: 'We Have a Ghost, 2023 director',
    },
    '540': {
        content: '',
        submitted_by: '',
        answers: ["Alice in Wonderland"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Fantasy, Adventure',
        release_year: '2010',
        notable_actor: '',
        director: 'Alice in Wonderland, 2010 director',
    },
    '541': {
        content: '',
        submitted_by: '',
        answers: ["The Croods"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Adventure, Family, Fantasy, Comedy, Action',
        release_year: '2013',
        notable_actor: '',
        director: 'The Croods, 2013 director',
    },
    '542': {
        content: '',
        submitted_by: '',
        answers: ["The Incredible Hulk"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Action, Adventure',
        release_year: '2008',
        notable_actor: '',
        director: 'The Incredible Hulk, 2008 director',
    },
    '543': {
        content: '',
        submitted_by: '',
        answers: ["Wish Dragon"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Comedy, Fantasy',
        release_year: '2021',
        notable_actor: '',
        director: 'Wish Dragon, 2021 director',
    },
    '544': {
        content: '',
        submitted_by: '',
        answers: ["Spiderhead"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Thriller',
        release_year: '2022',
        notable_actor: '',
        director: 'Spiderhead, 2022 director',
    },
    '545': {
        content: '',
        submitted_by: '',
        answers: ["Abraham Lincoln: Vampire Hunter"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Fantasy, Horror',
        release_year: '2012',
        notable_actor: '',
        director: 'Abraham Lincoln: Vampire Hunter, 2012 director',
    },
    '546': {
        content: '',
        submitted_by: '',
        answers: ["Ninja Assassin"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Thriller',
        release_year: '2009',
        notable_actor: '',
        director: 'Ninja Assassin, 2009 director',
    },
    '547': {
        content: '',
        submitted_by: '',
        answers: ["Boston Strangler"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Drama, Crime',
        release_year: '2023',
        notable_actor: '',
        director: 'Boston Strangler, 2023 director',
    },
    '548': {
        content: '',
        submitted_by: '',
        answers: ["My Neighbor Totoro"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Animation, Family',
        release_year: '1988',
        notable_actor: '',
        director: 'My Neighbor Totoro, 1988 director',
    },
    '549': {
        content: '',
        submitted_by: '',
        answers: ["Kimi"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller',
        release_year: '2022',
        notable_actor: '',
        director: 'Kimi, 2022 director',
    },
    '550': {
        content: '',
        submitted_by: '',
        answers: ["Nightmare Alley"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller',
        release_year: '2021',
        notable_actor: '',
        director: 'Nightmare Alley, 2021 director',
    },
    '551': {
        content: '',
        submitted_by: '',
        answers: ["Below Her Mouth"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Romance',
        release_year: '2017',
        notable_actor: '',
        director: 'Below Her Mouth, 2017 director',
    },
    '552': {
        content: '',
        submitted_by: '',
        answers: ["Dragon Ball Z: The Return of Cooler"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Animation, Science Fiction',
        release_year: '1992',
        notable_actor: '',
        director: 'Dragon Ball Z: The Return of Cooler, 1992 director',
    },
    '553': {
        content: '',
        submitted_by: '',
        answers: ["Umma"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery',
        release_year: '2022',
        notable_actor: '',
        director: 'Umma, 2022 director',
    },
    '554': {
        content: '',
        submitted_by: '',
        answers: ["Monster Pets: A Hotel Transylvania Short"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Fantasy',
        release_year: '2021',
        notable_actor: '',
        director: 'Monster Pets: A Hotel Transylvania Short, 2021 director',
    },
    '555': {
        content: '',
        submitted_by: '',
        answers: ["The Jungle Book"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Animation, Adventure',
        release_year: '1967',
        notable_actor: '',
        director: 'The Jungle Book, 1967 director',
    },
    '556': {
        content: '',
        submitted_by: '',
        answers: ["Maleficent: Mistress of Evil"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Fantasy, Adventure, Action',
        release_year: '2019',
        notable_actor: '',
        director: 'Maleficent: Mistress of Evil, 2019 director',
    },
    '557': {
        content: '',
        submitted_by: '',
        answers: ["White Chicks"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Crime',
        release_year: '2004',
        notable_actor: '',
        director: 'White Chicks, 2004 director',
    },
    '558': {
        content: '',
        submitted_by: '',
        answers: ["Tom and Jerry Cowboy Up!"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Family, Western',
        release_year: '2022',
        notable_actor: '',
        director: 'Tom and Jerry Cowboy Up!, 2022 director',
    },
    '559': {
        content: '',
        submitted_by: '',
        answers: ["Forrest Gump"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Drama, Romance',
        release_year: '1994',
        notable_actor: '',
        director: 'Forrest Gump, 1994 director',
    },
    '560': {
        content: '',
        submitted_by: '',
        answers: ["Wendell & Wild"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Fantasy, Adventure',
        release_year: '2022',
        notable_actor: '',
        director: 'Wendell & Wild, 2022 director',
    },
    '561': {
        content: '',
        submitted_by: '',
        answers: ["Ron's Gone Wrong"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Science Fiction, Family, Comedy',
        release_year: '2021',
        notable_actor: '',
        director: 'Ron\'s Gone Wrong, 2021 director',
    },
    '562': {
        content: '',
        submitted_by: '',
        answers: ["Captain Marvel"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '2019',
        notable_actor: '',
        director: 'Captain Marvel, 2019 director',
    },
    '563': {
        content: '',
        submitted_by: '',
        answers: ["Split"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2017',
        notable_actor: '',
        director: 'Split, 2017 director',
    },
    '564': {
        content: '',
        submitted_by: '',
        answers: ["Coherence"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Science Fiction',
        release_year: '2013',
        notable_actor: '',
        director: 'Coherence, 2013 director',
    },
    '565': {
        content: '',
        submitted_by: '',
        answers: ["Superbad"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2007',
        notable_actor: '',
        director: 'Superbad, 2007 director',
    },
    '566': {
        content: '',
        submitted_by: '',
        answers: ["Mad Max: Fury Road"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '2015',
        notable_actor: '',
        director: 'Mad Max: Fury Road, 2015 director',
    },
    '567': {
        content: '',
        submitted_by: '',
        answers: ["Pixels"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Comedy, Science Fiction',
        release_year: '2015',
        notable_actor: '',
        director: 'Pixels, 2015 director',
    },
    '568': {
        content: '',
        submitted_by: '',
        answers: ["Taken"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller',
        release_year: '2008',
        notable_actor: '',
        director: 'Taken, 2008 director',
    },
    '569': {
        content: '',
        submitted_by: '',
        answers: ["Wonder Woman"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Fantasy',
        release_year: '2017',
        notable_actor: '',
        director: 'Wonder Woman, 2017 director',
    },
    '570': {
        content: '',
        submitted_by: '',
        answers: ["The Terminator"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller, Science Fiction',
        release_year: '1984',
        notable_actor: '',
        director: 'The Terminator, 1984 director',
    },
    '571': {
        content: '',
        submitted_by: '',
        answers: ["American Wedding"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Romance',
        release_year: '2003',
        notable_actor: '',
        director: 'American Wedding, 2003 director',
    },
    '572': {
        content: '',
        submitted_by: '',
        answers: ["Peter Pan"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy, Family',
        release_year: '2003',
        notable_actor: '',
        director: 'Peter Pan, 2003 director',
    },
    '573': {
        content: '',
        submitted_by: '',
        answers: ["Poker Face"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller',
        release_year: '2022',
        notable_actor: '',
        director: 'Poker Face, 2022 director',
    },
    '574': {
        content: '',
        submitted_by: '',
        answers: ["American Pie Presents: Beta House"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2007',
        notable_actor: '',
        director: 'American Pie Presents: Beta House, 2007 director',
    },
    '575': {
        content: '',
        submitted_by: '',
        answers: ["Vendetta"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action',
        release_year: '2022',
        notable_actor: '',
        director: 'Vendetta, 2022 director',
    },
    '576': {
        content: '',
        submitted_by: '',
        answers: ["Birds of Prey (and the Fantabulous Emancipation of One Harley Quinn)"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Crime',
        release_year: '2020',
        notable_actor: '',
        director: 'Birds of Prey (and the Fantabulous Emancipation of One Harley Quinn), 2020 director',
    },
    '577': {
        content: '',
        submitted_by: '',
        answers: ["The Karate Kid"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Drama, Family',
        release_year: '2010',
        notable_actor: '',
        director: 'The Karate Kid, 2010 director',
    },
    '578': {
        content: '',
        submitted_by: '',
        answers: ["Lilo & Stitch"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family',
        release_year: '2002',
        notable_actor: '',
        director: 'Lilo & Stitch, 2002 director',
    },
    '579': {
        content: '',
        submitted_by: '',
        answers: ["In Time"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller, Science Fiction',
        release_year: '2011',
        notable_actor: '',
        director: 'In Time, 2011 director',
    },
    '580': {
        content: '',
        submitted_by: '',
        answers: ["Baywatch"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Action, Crime',
        release_year: '2017',
        notable_actor: '',
        director: 'Baywatch, 2017 director',
    },
    '581': {
        content: '',
        submitted_by: '',
        answers: ["Rambo: Last Blood"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller, Drama',
        release_year: '2019',
        notable_actor: '',
        director: 'Rambo: Last Blood, 2019 director',
    },
    '582': {
        content: '',
        submitted_by: '',
        answers: ["Ice Age: Continental Drift"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Adventure, Family',
        release_year: '2012',
        notable_actor: '',
        director: 'Ice Age: Continental Drift, 2012 director',
    },
    '583': {
        content: '',
        submitted_by: '',
        answers: ["Ted"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Fantasy',
        release_year: '2012',
        notable_actor: '',
        director: 'Ted, 2012 director',
    },
    '584': {
        content: '',
        submitted_by: '',
        answers: ["Garfield"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Family',
        release_year: '2004',
        notable_actor: '',
        director: 'Garfield, 2004 director',
    },
    '585': {
        content: '',
        submitted_by: '',
        answers: ["The Secret Life of Pets 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Animation, Comedy, Family',
        release_year: '2019',
        notable_actor: '',
        director: 'The Secret Life of Pets 2, 2019 director',
    },
    '586': {
        content: '',
        submitted_by: '',
        answers: ["Legion"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Action, Fantasy',
        release_year: '2010',
        notable_actor: '',
        director: 'Legion, 2010 director',
    },
    '587': {
        content: '',
        submitted_by: '',
        answers: ["Lucy"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Science Fiction',
        release_year: '2014',
        notable_actor: '',
        director: 'Lucy, 2014 director',
    },
    '588': {
        content: '',
        submitted_by: '',
        answers: ["The Hobbit: The Desolation of Smaug"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Adventure, Action',
        release_year: '2013',
        notable_actor: '',
        director: 'The Hobbit: The Desolation of Smaug, 2013 director',
    },
    '589': {
        content: '',
        submitted_by: '',
        answers: ["Whiplash"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Music',
        release_year: '2014',
        notable_actor: '',
        director: 'Whiplash, 2014 director',
    },
    '590': {
        content: '',
        submitted_by: '',
        answers: ["No Manches Frida 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2019',
        notable_actor: '',
        director: 'No Manches Frida 2, 2019 director',
    },
    '591': {
        content: '',
        submitted_by: '',
        answers: ["Halloween Kills"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2021',
        notable_actor: '',
        director: 'Halloween Kills, 2021 director',
    },
    '592': {
        content: '',
        submitted_by: '',
        answers: ["The Intern"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2015',
        notable_actor: '',
        director: 'The Intern, 2015 director',
    },
    '593': {
        content: '',
        submitted_by: '',
        answers: ["The Vault"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action',
        release_year: '2021',
        notable_actor: '',
        director: 'The Vault, 2021 director',
    },
    '594': {
        content: '',
        submitted_by: '',
        answers: ["The Kissing Booth"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Romance, Comedy',
        release_year: '2018',
        notable_actor: '',
        director: 'The Kissing Booth, 2018 director',
    },
    '595': {
        content: '',
        submitted_by: '',
        answers: ["The Expendables 3"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Thriller',
        release_year: '2014',
        notable_actor: '',
        director: 'The Expendables 3, 2014 director',
    },
    '596': {
        content: '',
        submitted_by: '',
        answers: ["Black Site"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller',
        release_year: '2022',
        notable_actor: '',
        director: 'Black Site, 2022 director',
    },
    '597': {
        content: '',
        submitted_by: '',
        answers: ["Hitman"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller, Drama',
        release_year: '2007',
        notable_actor: '',
        director: 'Hitman, 2007 director',
    },
    '598': {
        content: '',
        submitted_by: '',
        answers: ["Scooby-Doo"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Mystery, Adventure, Comedy',
        release_year: '2002',
        notable_actor: '',
        director: 'Scooby-Doo, 2002 director',
    },
    '599': {
        content: '',
        submitted_by: '',
        answers: ["Hancock"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Action',
        release_year: '2008',
        notable_actor: '',
        director: 'Hancock, 2008 director',
    },
    '600': {
        content: '',
        submitted_by: '',
        answers: ["Schindler's List"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, History, War',
        release_year: '1993',
        notable_actor: '',
        director: 'Schindler\'s List, 1993 director',
    },
    '601': {
        content: '',
        submitted_by: '',
        answers: ["Don't Worry Darling"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Mystery, Science Fiction',
        release_year: '2022',
        notable_actor: '',
        director: 'Don\'t Worry Darling, 2022 director',
    },
    '602': {
        content: '',
        submitted_by: '',
        answers: ["John Carter"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '2012',
        notable_actor: '',
        director: 'John Carter, 2012 director',
    },
    '603': {
        content: '',
        submitted_by: '',
        answers: ["Friday the 13th"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror',
        release_year: '2009',
        notable_actor: '',
        director: 'Friday the 13th, 2009 director',
    },
    '604': {
        content: '',
        submitted_by: '',
        answers: ["My Hero Academia: Two Heroes"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Action, Adventure, Fantasy',
        release_year: '2018',
        notable_actor: '',
        director: 'My Hero Academia: Two Heroes, 2018 director',
    },
    '605': {
        content: '',
        submitted_by: '',
        answers: ["Tomorrowland"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Family, Mystery, Science Fiction',
        release_year: '2015',
        notable_actor: '',
        director: 'Tomorrowland, 2015 director',
    },
    '606': {
        content: '',
        submitted_by: '',
        answers: ["Sal\u00f2, or the 120 Days of Sodom"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, War',
        release_year: '1976',
        notable_actor: '',
        director: 'Salò, or the 120 Days of Sodom, 1976 director',
    },
    '607': {
        content: '',
        submitted_by: '',
        answers: ["Countdown"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2019',
        notable_actor: '',
        director: 'Countdown, 2019 director',
    },
    '608': {
        content: '',
        submitted_by: '',
        answers: ["I Want You"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Romance',
        release_year: '2012',
        notable_actor: '',
        director: 'I Want You, 2012 director',
    },
    '609': {
        content: '',
        submitted_by: '',
        answers: ["Down"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Drama, Thriller',
        release_year: '2019',
        notable_actor: '',
        director: 'Down, 2019 director',
    },
    '610': {
        content: '',
        submitted_by: '',
        answers: ["War for the Planet of the Apes"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Science Fiction, War',
        release_year: '2017',
        notable_actor: '',
        director: 'War for the Planet of the Apes, 2017 director',
    },
    '611': {
        content: '',
        submitted_by: '',
        answers: ["American Pie"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Romance',
        release_year: '1999',
        notable_actor: '',
        director: 'American Pie, 1999 director',
    },
    '612': {
        content: '',
        submitted_by: '',
        answers: ["Black Panther"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '2018',
        notable_actor: '',
        director: 'Black Panther, 2018 director',
    },
    '613': {
        content: '',
        submitted_by: '',
        answers: ["A Silent Voice: The Movie"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Drama',
        release_year: '2016',
        notable_actor: '',
        director: 'A Silent Voice: The Movie, 2016 director',
    },
    '614': {
        content: '',
        submitted_by: '',
        answers: ["American Pie Presents: The Book of Love"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2009',
        notable_actor: '',
        director: 'American Pie Presents: The Book of Love, 2009 director',
    },
    '615': {
        content: '',
        submitted_by: '',
        answers: ["The Godfather Part II"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Crime',
        release_year: '1974',
        notable_actor: '',
        director: 'The Godfather Part II, 1974 director',
    },
    '616': {
        content: '',
        submitted_by: '',
        answers: ["Black Water: Abyss"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Action, Adventure',
        release_year: '2020',
        notable_actor: '',
        director: 'Black Water: Abyss, 2020 director',
    },
    '617': {
        content: '',
        submitted_by: '',
        answers: ["The Desperate Hour"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller',
        release_year: '2021',
        notable_actor: '',
        director: 'The Desperate Hour, 2021 director',
    },
    '618': {
        content: '',
        submitted_by: '',
        answers: ["Madagascar 3: Europe's Most Wanted"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Comedy, Adventure',
        release_year: '2012',
        notable_actor: '',
        director: 'Madagascar 3: Europe\'s Most Wanted, 2012 director',
    },
    '619': {
        content: '',
        submitted_by: '',
        answers: ["A Whisker Away"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Drama, Romance, Fantasy',
        release_year: '2020',
        notable_actor: '',
        director: 'A Whisker Away, 2020 director',
    },
    '620': {
        content: '',
        submitted_by: '',
        answers: ["Tom and Jerry: The Fast and the Furry"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Animation, Comedy, Action, Adventure',
        release_year: '2005',
        notable_actor: '',
        director: 'Tom and Jerry: The Fast and the Furry, 2005 director',
    },
    '621': {
        content: '',
        submitted_by: '',
        answers: ["Purple Hearts"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Romance, Drama',
        release_year: '2022',
        notable_actor: '',
        director: 'Purple Hearts, 2022 director',
    },
    '622': {
        content: '',
        submitted_by: '',
        answers: ["Glass"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Drama, Science Fiction',
        release_year: '2019',
        notable_actor: '',
        director: 'Glass, 2019 director',
    },
    '623': {
        content: '',
        submitted_by: '',
        answers: ["The Twilight Saga: New Moon"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy, Drama, Romance',
        release_year: '2009',
        notable_actor: '',
        director: 'The Twilight Saga: New Moon, 2009 director',
    },
    '624': {
        content: '',
        submitted_by: '',
        answers: ["Peter Pan"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Adventure, Fantasy',
        release_year: '1953',
        notable_actor: '',
        director: 'Peter Pan, 1953 director',
    },
    '625': {
        content: '',
        submitted_by: '',
        answers: ["Ponyo"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Fantasy, Family',
        release_year: '2008',
        notable_actor: '',
        director: 'Ponyo, 2008 director',
    },
    '626': {
        content: '',
        submitted_by: '',
        answers: ["My Hero Academia: Heroes Rising"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Action, Fantasy, Adventure',
        release_year: '2019',
        notable_actor: '',
        director: 'My Hero Academia: Heroes Rising, 2019 director',
    },
    '627': {
        content: '',
        submitted_by: '',
        answers: ["Bad Boys for Life"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Action, Crime',
        release_year: '2020',
        notable_actor: '',
        director: 'Bad Boys for Life, 2020 director',
    },
    '628': {
        content: '',
        submitted_by: '',
        answers: ["The Remaining"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Horror',
        release_year: '2014',
        notable_actor: '',
        director: 'The Remaining, 2014 director',
    },
    '629': {
        content: '',
        submitted_by: '',
        answers: ["Justice League: The Flashpoint Paradox"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Animation, Action, Adventure',
        release_year: '2013',
        notable_actor: '',
        director: 'Justice League: The Flashpoint Paradox, 2013 director',
    },
    '630': {
        content: '',
        submitted_by: '',
        answers: ["Just Go with It"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Romance',
        release_year: '2011',
        notable_actor: '',
        director: 'Just Go with It, 2011 director',
    },
    '631': {
        content: '',
        submitted_by: '',
        answers: ["The Good, the Bad and the Ugly"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Western',
        release_year: '1966',
        notable_actor: '',
        director: 'The Good, the Bad and the Ugly, 1966 director',
    },
    '632': {
        content: '',
        submitted_by: '',
        answers: ["Charm City Kings"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '2020',
        notable_actor: '',
        director: 'Charm City Kings, 2020 director',
    },
    '633': {
        content: '',
        submitted_by: '',
        answers: ["Living"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '2022',
        notable_actor: '',
        director: 'Living, 2022 director',
    },
    '634': {
        content: '',
        submitted_by: '',
        answers: ["Titane"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Thriller, Horror',
        release_year: '2021',
        notable_actor: '',
        director: 'Titane, 2021 director',
    },
    '635': {
        content: '',
        submitted_by: '',
        answers: ["The SpongeBob SquarePants Movie"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Animation, Comedy, Fantasy, Adventure',
        release_year: '2004',
        notable_actor: '',
        director: 'The SpongeBob SquarePants Movie, 2004 director',
    },
    '636': {
        content: '',
        submitted_by: '',
        answers: ["The Lodge"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Horror, Thriller',
        release_year: '2020',
        notable_actor: '',
        director: 'The Lodge, 2020 director',
    },
    '637': {
        content: '',
        submitted_by: '',
        answers: ["Chappie"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Crime, Action, Science Fiction',
        release_year: '2015',
        notable_actor: '',
        director: 'Chappie, 2015 director',
    },
    '638': {
        content: '',
        submitted_by: '',
        answers: ["Wrong Turn 5: Bloodlines"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2012',
        notable_actor: '',
        director: 'Wrong Turn 5: Bloodlines, 2012 director',
    },
    '639': {
        content: '',
        submitted_by: '',
        answers: ["American Pie Presents: Girls' Rules"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2020',
        notable_actor: '',
        director: 'American Pie Presents: Girls\' Rules, 2020 director',
    },
    '640': {
        content: '',
        submitted_by: '',
        answers: ["Ice Age: The Meltdown"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Comedy, Adventure',
        release_year: '2006',
        notable_actor: '',
        director: 'Ice Age: The Meltdown, 2006 director',
    },
    '641': {
        content: '',
        submitted_by: '',
        answers: ["Rocky IV"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '1985',
        notable_actor: '',
        director: 'Rocky IV, 1985 director',
    },
    '642': {
        content: '',
        submitted_by: '',
        answers: ["Dinosaur"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Animation',
        release_year: '2000',
        notable_actor: '',
        director: 'Dinosaur, 2000 director',
    },
    '643': {
        content: '',
        submitted_by: '',
        answers: ["No Manches Frida"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2016',
        notable_actor: '',
        director: 'No Manches Frida, 2016 director',
    },
    '644': {
        content: '',
        submitted_by: '',
        answers: ["It Boy"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2013',
        notable_actor: '',
        director: 'It Boy, 2013 director',
    },
    '645': {
        content: '',
        submitted_by: '',
        answers: ["Old"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Mystery, Horror',
        release_year: '2021',
        notable_actor: '',
        director: 'Old, 2021 director',
    },
    '646': {
        content: '',
        submitted_by: '',
        answers: ["Gold"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Thriller',
        release_year: '2022',
        notable_actor: '',
        director: 'Gold, 2022 director',
    },
    '647': {
        content: '',
        submitted_by: '',
        answers: ["The Stranger"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller',
        release_year: '2022',
        notable_actor: '',
        director: 'The Stranger, 2022 director',
    },
    '648': {
        content: '',
        submitted_by: '',
        answers: ["The Notebook"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Romance, Drama',
        release_year: '2004',
        notable_actor: '',
        director: 'The Notebook, 2004 director',
    },
    '649': {
        content: '',
        submitted_by: '',
        answers: ["Vivarium"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Thriller, Mystery, Horror',
        release_year: '2019',
        notable_actor: '',
        director: 'Vivarium, 2019 director',
    },
    '650': {
        content: '',
        submitted_by: '',
        answers: ["Redcon-1"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Horror, Science Fiction',
        release_year: '2018',
        notable_actor: '',
        director: 'Redcon-1, 2018 director',
    },
    '651': {
        content: '',
        submitted_by: '',
        answers: ["Brother Bear"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Animation, Family',
        release_year: '2003',
        notable_actor: '',
        director: 'Brother Bear, 2003 director',
    },
    '652': {
        content: '',
        submitted_by: '',
        answers: ["The Valet"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Romance',
        release_year: '2022',
        notable_actor: '',
        director: 'The Valet, 2022 director',
    },
    '653': {
        content: '',
        submitted_by: '',
        answers: ["Murder Mystery"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Mystery',
        release_year: '2019',
        notable_actor: '',
        director: 'Murder Mystery, 2019 director',
    },
    '654': {
        content: '',
        submitted_by: '',
        answers: ["Inglourious Basterds"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Thriller, War',
        release_year: '2009',
        notable_actor: '',
        director: 'Inglourious Basterds, 2009 director',
    },
    '655': {
        content: '',
        submitted_by: '',
        answers: ["Chickenhare and the Hamster of Darkness"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Adventure, Comedy, Family, Fantasy',
        release_year: '2022',
        notable_actor: '',
        director: 'Chickenhare and the Hamster of Darkness, 2022 director',
    },
    '656': {
        content: '',
        submitted_by: '',
        answers: ["Annapolis"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Action',
        release_year: '2006',
        notable_actor: '',
        director: 'Annapolis, 2006 director',
    },
    '657': {
        content: '',
        submitted_by: '',
        answers: ["Magnum Opus"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Science Fiction, Comedy',
        release_year: '2022',
        notable_actor: '',
        director: 'Magnum Opus, 2022 director',
    },
    '658': {
        content: '',
        submitted_by: '',
        answers: ["Bolt"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Adventure, Comedy',
        release_year: '2008',
        notable_actor: '',
        director: 'Bolt, 2008 director',
    },
    '659': {
        content: '',
        submitted_by: '',
        answers: ["Weathering with You"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Drama, Fantasy, Romance',
        release_year: '2019',
        notable_actor: '',
        director: 'Weathering with You, 2019 director',
    },
    '660': {
        content: '',
        submitted_by: '',
        answers: ["I Am Legend"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Science Fiction, Thriller',
        release_year: '2007',
        notable_actor: '',
        director: 'I Am Legend, 2007 director',
    },
    '661': {
        content: '',
        submitted_by: '',
        answers: ["Exodus: Gods and Kings"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Drama, Action',
        release_year: '2014',
        notable_actor: '',
        director: 'Exodus: Gods and Kings, 2014 director',
    },
    '662': {
        content: '',
        submitted_by: '',
        answers: ["Justice League Dark: Apokolips War"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Action, Science Fiction',
        release_year: '2020',
        notable_actor: '',
        director: 'Justice League Dark: Apokolips War, 2020 director',
    },
    '663': {
        content: '',
        submitted_by: '',
        answers: ["Army of Thieves"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Crime, Comedy',
        release_year: '2021',
        notable_actor: '',
        director: 'Army of Thieves, 2021 director',
    },
    '664': {
        content: '',
        submitted_by: '',
        answers: ["Batman Begins"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Crime, Drama',
        release_year: '2005',
        notable_actor: '',
        director: 'Batman Begins, 2005 director',
    },
    '665': {
        content: '',
        submitted_by: '',
        answers: ["Fireheart"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family',
        release_year: '2022',
        notable_actor: '',
        director: 'Fireheart, 2022 director',
    },
    '666': {
        content: '',
        submitted_by: '',
        answers: ["The Deep End of the Ocean"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Mystery',
        release_year: '1999',
        notable_actor: '',
        director: 'The Deep End of the Ocean, 1999 director',
    },
    '667': {
        content: '',
        submitted_by: '',
        answers: ["The Hangover Part III"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2013',
        notable_actor: '',
        director: 'The Hangover Part III, 2013 director',
    },
    '668': {
        content: '',
        submitted_by: '',
        answers: ["Tenet"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller, Science Fiction',
        release_year: '2020',
        notable_actor: '',
        director: 'Tenet, 2020 director',
    },
    '669': {
        content: '',
        submitted_by: '',
        answers: ["The Passion of the Christ"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '2004',
        notable_actor: '',
        director: 'The Passion of the Christ, 2004 director',
    },
    '670': {
        content: '',
        submitted_by: '',
        answers: ["Logan"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Drama, Science Fiction',
        release_year: '2017',
        notable_actor: '',
        director: 'Logan, 2017 director',
    },
    '671': {
        content: '',
        submitted_by: '',
        answers: ["American Psycho"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Drama, Crime',
        release_year: '2000',
        notable_actor: '',
        director: 'American Psycho, 2000 director',
    },
    '672': {
        content: '',
        submitted_by: '',
        answers: ["The Sadness"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2021',
        notable_actor: '',
        director: 'The Sadness, 2021 director',
    },
    '673': {
        content: '',
        submitted_by: '',
        answers: ["Rise of the Planet of the Apes"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Action, Drama, Science Fiction',
        release_year: '2011',
        notable_actor: '',
        director: 'Rise of the Planet of the Apes, 2011 director',
    },
    '674': {
        content: '',
        submitted_by: '',
        answers: ["Hotel Transylvania"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Family, Fantasy',
        release_year: '2012',
        notable_actor: '',
        director: 'Hotel Transylvania, 2012 director',
    },
    '675': {
        content: '',
        submitted_by: '',
        answers: ["Heaven & Earth"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'History, War, Action, Drama',
        release_year: '1993',
        notable_actor: '',
        director: 'Heaven & Earth, 1993 director',
    },
    '676': {
        content: '',
        submitted_by: '',
        answers: ["Tinker Bell and the Lost Treasure"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Adventure, Fantasy',
        release_year: '2009',
        notable_actor: '',
        director: 'Tinker Bell and the Lost Treasure, 2009 director',
    },
    '677': {
        content: '',
        submitted_by: '',
        answers: ["The Age of Adaline"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Romance, Fantasy, Drama',
        release_year: '2015',
        notable_actor: '',
        director: 'The Age of Adaline, 2015 director',
    },
    '678': {
        content: '',
        submitted_by: '',
        answers: ["Child's Play"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror',
        release_year: '1988',
        notable_actor: '',
        director: 'Child\'s Play, 1988 director',
    },
    '679': {
        content: '',
        submitted_by: '',
        answers: ["The First Purge"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Action, Thriller',
        release_year: '2018',
        notable_actor: '',
        director: 'The First Purge, 2018 director',
    },
    '680': {
        content: '',
        submitted_by: '',
        answers: ["Meet Joe Black"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Drama, Romance',
        release_year: '1998',
        notable_actor: '',
        director: 'Meet Joe Black, 1998 director',
    },
    '681': {
        content: '',
        submitted_by: '',
        answers: ["The Mummy Returns"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Fantasy',
        release_year: '2001',
        notable_actor: '',
        director: 'The Mummy Returns, 2001 director',
    },
    '682': {
        content: '',
        submitted_by: '',
        answers: ["Miracles from Heaven"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Drama',
        release_year: '2016',
        notable_actor: '',
        director: 'Miracles from Heaven, 2016 director',
    },
    '683': {
        content: '',
        submitted_by: '',
        answers: ["The Truman Show"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Drama',
        release_year: '1998',
        notable_actor: '',
        director: 'The Truman Show, 1998 director',
    },
    '684': {
        content: '',
        submitted_by: '',
        answers: ["Clash of the Titans"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy, Action',
        release_year: '2010',
        notable_actor: '',
        director: 'Clash of the Titans, 2010 director',
    },
    '685': {
        content: '',
        submitted_by: '',
        answers: ["Captain America: The First Avenger"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '2011',
        notable_actor: '',
        director: 'Captain America: The First Avenger, 2011 director',
    },
    '686': {
        content: '',
        submitted_by: '',
        answers: ["Trolls World Tour"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Animation, Comedy, Fantasy, Adventure, Music',
        release_year: '2020',
        notable_actor: '',
        director: 'Trolls World Tour, 2020 director',
    },
    '687': {
        content: '',
        submitted_by: '',
        answers: ["Men in Black"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Comedy, Science Fiction',
        release_year: '1997',
        notable_actor: '',
        director: 'Men in Black, 1997 director',
    },
    '688': {
        content: '',
        submitted_by: '',
        answers: ["Catch Me If You Can"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Crime',
        release_year: '2002',
        notable_actor: '',
        director: 'Catch Me If You Can, 2002 director',
    },
    '689': {
        content: '',
        submitted_by: '',
        answers: ["Paranormal Activity: Next of Kin"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery, Thriller',
        release_year: '2021',
        notable_actor: '',
        director: 'Paranormal Activity: Next of Kin, 2021 director',
    },
    '690': {
        content: '',
        submitted_by: '',
        answers: ["Star Trek Beyond"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '2016',
        notable_actor: '',
        director: 'Star Trek Beyond, 2016 director',
    },
    '691': {
        content: '',
        submitted_by: '',
        answers: ["Kong: Skull Island"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Fantasy',
        release_year: '2017',
        notable_actor: '',
        director: 'Kong: Skull Island, 2017 director',
    },
    '692': {
        content: '',
        submitted_by: '',
        answers: ["Extraction"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Action, Thriller',
        release_year: '2020',
        notable_actor: '',
        director: 'Extraction, 2020 director',
    },
    '693': {
        content: '',
        submitted_by: '',
        answers: ["Tombstone"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Western, Action',
        release_year: '1993',
        notable_actor: '',
        director: 'Tombstone, 1993 director',
    },
    '694': {
        content: '',
        submitted_by: '',
        answers: ["Shut In"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Horror, Drama',
        release_year: '2022',
        notable_actor: '',
        director: 'Shut In, 2022 director',
    },
    '695': {
        content: '',
        submitted_by: '',
        answers: ["Wrong Turn 4: Bloody Beginnings"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2011',
        notable_actor: '',
        director: 'Wrong Turn 4: Bloody Beginnings, 2011 director',
    },
    '696': {
        content: '',
        submitted_by: '',
        answers: ["One Hundred and One Dalmatians"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Animation, Comedy, Family',
        release_year: '1961',
        notable_actor: '',
        director: 'One Hundred and One Dalmatians, 1961 director',
    },
    '697': {
        content: '',
        submitted_by: '',
        answers: ["A Haunted House"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Horror',
        release_year: '2013',
        notable_actor: '',
        director: 'A Haunted House, 2013 director',
    },
    '698': {
        content: '',
        submitted_by: '',
        answers: ["Prometheus"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Adventure, Mystery',
        release_year: '2012',
        notable_actor: '',
        director: 'Prometheus, 2012 director',
    },
    '699': {
        content: '',
        submitted_by: '',
        answers: ["Bambi"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Drama, Family',
        release_year: '1942',
        notable_actor: '',
        director: 'Bambi, 1942 director',
    },
    '700': {
        content: '',
        submitted_by: '',
        answers: ["Never Back Down: No Surrender"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Action',
        release_year: '2016',
        notable_actor: '',
        director: 'Never Back Down: No Surrender, 2016 director',
    },
    '701': {
        content: '',
        submitted_by: '',
        answers: ["Spartacus"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'History, War, Drama, Adventure',
        release_year: '1960',
        notable_actor: '',
        director: 'Spartacus, 1960 director',
    },
    '702': {
        content: '',
        submitted_by: '',
        answers: ["Overcomer"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '2019',
        notable_actor: '',
        director: 'Overcomer, 2019 director',
    },
    '703': {
        content: '',
        submitted_by: '',
        answers: ["Jeepers Creepers"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2001',
        notable_actor: '',
        director: 'Jeepers Creepers, 2001 director',
    },
    '704': {
        content: '',
        submitted_by: '',
        answers: ["Call Me by Your Name"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Romance, Drama',
        release_year: '2017',
        notable_actor: '',
        director: 'Call Me by Your Name, 2017 director',
    },
    '705': {
        content: '',
        submitted_by: '',
        answers: ["Shutter Island"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Thriller, Mystery',
        release_year: '2010',
        notable_actor: '',
        director: 'Shutter Island, 2010 director',
    },
    '706': {
        content: '',
        submitted_by: '',
        answers: ["The Innkeepers"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2011',
        notable_actor: '',
        director: 'The Innkeepers, 2011 director',
    },
    '707': {
        content: '',
        submitted_by: '',
        answers: ["Jumanji: Welcome to the Jungle"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Comedy, Fantasy',
        release_year: '2017',
        notable_actor: '',
        director: 'Jumanji: Welcome to the Jungle, 2017 director',
    },
    '708': {
        content: '',
        submitted_by: '',
        answers: ["Taken 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Crime, Thriller',
        release_year: '2012',
        notable_actor: '',
        director: 'Taken 2, 2012 director',
    },
    '709': {
        content: '',
        submitted_by: '',
        answers: ["Perfume: The Story of a Murderer"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Crime, Fantasy, Drama',
        release_year: '2006',
        notable_actor: '',
        director: 'Perfume: The Story of a Murderer, 2006 director',
    },
    '710': {
        content: '',
        submitted_by: '',
        answers: ["Gladiator"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Drama, Adventure',
        release_year: '2000',
        notable_actor: '',
        director: 'Gladiator, 2000 director',
    },
    '711': {
        content: '',
        submitted_by: '',
        answers: ["Five Feet Apart"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Romance, Drama',
        release_year: '2019',
        notable_actor: '',
        director: 'Five Feet Apart, 2019 director',
    },
    '712': {
        content: '',
        submitted_by: '',
        answers: ["Megamind"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Action, Comedy, Family, Science Fiction',
        release_year: '2010',
        notable_actor: '',
        director: 'Megamind, 2010 director',
    },
    '713': {
        content: '',
        submitted_by: '',
        answers: ["The Expendables"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Adventure, Action',
        release_year: '2010',
        notable_actor: '',
        director: 'The Expendables, 2010 director',
    },
    '714': {
        content: '',
        submitted_by: '',
        answers: ["After Porn Ends 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Documentary',
        release_year: '2017',
        notable_actor: '',
        director: 'After Porn Ends 2, 2017 director',
    },
    '715': {
        content: '',
        submitted_by: '',
        answers: ["Annabelle"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror',
        release_year: '2014',
        notable_actor: '',
        director: 'Annabelle, 2014 director',
    },
    '716': {
        content: '',
        submitted_by: '',
        answers: ["Fantastic Beasts and Where to Find Them"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Adventure, Family',
        release_year: '2016',
        notable_actor: '',
        director: 'Fantastic Beasts and Where to Find Them, 2016 director',
    },
    '717': {
        content: '',
        submitted_by: '',
        answers: ["The Hunger Games"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Adventure, Fantasy',
        release_year: '2012',
        notable_actor: '',
        director: 'The Hunger Games, 2012 director',
    },
    '718': {
        content: '',
        submitted_by: '',
        answers: ["Lady and the Tramp"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Animation, Romance',
        release_year: '1955',
        notable_actor: '',
        director: 'Lady and the Tramp, 1955 director',
    },
    '719': {
        content: '',
        submitted_by: '',
        answers: ["The Marksman"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action',
        release_year: '2021',
        notable_actor: '',
        director: 'The Marksman, 2021 director',
    },
    '720': {
        content: '',
        submitted_by: '',
        answers: ["Jojo Rabbit"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, War, Drama',
        release_year: '2019',
        notable_actor: '',
        director: 'Jojo Rabbit, 2019 director',
    },
    '721': {
        content: '',
        submitted_by: '',
        answers: ["Sleeping Beauty"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Animation, Romance, Family',
        release_year: '1959',
        notable_actor: '',
        director: 'Sleeping Beauty, 1959 director',
    },
    '722': {
        content: '',
        submitted_by: '',
        answers: ["How the Grinch Stole Christmas"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Comedy, Fantasy',
        release_year: '2000',
        notable_actor: '',
        director: 'How the Grinch Stole Christmas, 2000 director',
    },
    '723': {
        content: '',
        submitted_by: '',
        answers: ["Predators"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Action, Thriller',
        release_year: '2010',
        notable_actor: '',
        director: 'Predators, 2010 director',
    },
    '724': {
        content: '',
        submitted_by: '',
        answers: ["The Town"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Crime, Drama, Thriller',
        release_year: '2010',
        notable_actor: '',
        director: 'The Town, 2010 director',
    },
    '725': {
        content: '',
        submitted_by: '',
        answers: ["Terminator Salvation"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Science Fiction, Thriller',
        release_year: '2009',
        notable_actor: '',
        director: 'Terminator Salvation, 2009 director',
    },
    '726': {
        content: '',
        submitted_by: '',
        answers: ["Mission: Impossible - Fallout"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure',
        release_year: '2018',
        notable_actor: '',
        director: 'Mission: Impossible - Fallout, 2018 director',
    },
    '727': {
        content: '',
        submitted_by: '',
        answers: ["Wrong Turn"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2003',
        notable_actor: '',
        director: 'Wrong Turn, 2003 director',
    },
    '728': {
        content: '',
        submitted_by: '',
        answers: ["How to Train Your Dragon"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Adventure, Animation, Family',
        release_year: '2010',
        notable_actor: '',
        director: 'How to Train Your Dragon, 2010 director',
    },
    '729': {
        content: '',
        submitted_by: '',
        answers: ["Tinker Bell"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Adventure, Fantasy',
        release_year: '2008',
        notable_actor: '',
        director: 'Tinker Bell, 2008 director',
    },
    '730': {
        content: '',
        submitted_by: '',
        answers: ["Rango"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Family, Western, Adventure',
        release_year: '2011',
        notable_actor: '',
        director: 'Rango, 2011 director',
    },
    '731': {
        content: '',
        submitted_by: '',
        answers: ["American Reunion"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2012',
        notable_actor: '',
        director: 'American Reunion, 2012 director',
    },
    '732': {
        content: '',
        submitted_by: '',
        answers: ["Hansel & Gretel: Witch Hunters"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Horror, Action',
        release_year: '2013',
        notable_actor: '',
        director: 'Hansel & Gretel: Witch Hunters, 2013 director',
    },
    '733': {
        content: '',
        submitted_by: '',
        answers: ["Scary Movie 4"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2006',
        notable_actor: '',
        director: 'Scary Movie 4, 2006 director',
    },
    '734': {
        content: '',
        submitted_by: '',
        answers: ["The Last Airbender"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Fantasy',
        release_year: '2010',
        notable_actor: '',
        director: 'The Last Airbender, 2010 director',
    },
    '735': {
        content: '',
        submitted_by: '',
        answers: ["Lolita"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '1997',
        notable_actor: '',
        director: 'Lolita, 1997 director',
    },
    '736': {
        content: '',
        submitted_by: '',
        answers: ["Back to the Outback"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Adventure, Comedy',
        release_year: '2021',
        notable_actor: '',
        director: 'Back to the Outback, 2021 director',
    },
    '737': {
        content: '',
        submitted_by: '',
        answers: ["The Little Guy"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Comedy, Science Fiction',
        release_year: '2022',
        notable_actor: '',
        director: 'The Little Guy, 2022 director',
    },
    '738': {
        content: '',
        submitted_by: '',
        answers: ["Rise of the Guardians"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Animation, Fantasy, Action, Adventure',
        release_year: '2012',
        notable_actor: '',
        director: 'Rise of the Guardians, 2012 director',
    },
    '739': {
        content: '',
        submitted_by: '',
        answers: ["Project Almanac"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Thriller, Drama',
        release_year: '2015',
        notable_actor: '',
        director: 'Project Almanac, 2015 director',
    },
    '740': {
        content: '',
        submitted_by: '',
        answers: ["Coach Carter"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, History',
        release_year: '2005',
        notable_actor: '',
        director: 'Coach Carter, 2005 director',
    },
    '741': {
        content: '',
        submitted_by: '',
        answers: ["Kick-Ass"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Crime',
        release_year: '2010',
        notable_actor: '',
        director: 'Kick-Ass, 2010 director',
    },
    '742': {
        content: '',
        submitted_by: '',
        answers: ["Insidious"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2010',
        notable_actor: '',
        director: 'Insidious, 2010 director',
    },
    '743': {
        content: '',
        submitted_by: '',
        answers: ["Ghost Rider: Spirit of Vengeance"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Fantasy, Thriller',
        release_year: '2011',
        notable_actor: '',
        director: 'Ghost Rider: Spirit of Vengeance, 2011 director',
    },
    '744': {
        content: '',
        submitted_by: '',
        answers: ["Army of the Dead"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Crime, Fantasy',
        release_year: '2021',
        notable_actor: '',
        director: 'Army of the Dead, 2021 director',
    },
    '745': {
        content: '',
        submitted_by: '',
        answers: ["The Devil's Advocate"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Mystery, Thriller, Horror',
        release_year: '1997',
        notable_actor: '',
        director: 'The Devil\'s Advocate, 1997 director',
    },
    '746': {
        content: '',
        submitted_by: '',
        answers: ["Green Book"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Comedy',
        release_year: '2018',
        notable_actor: '',
        director: 'Green Book, 2018 director',
    },
    '747': {
        content: '',
        submitted_by: '',
        answers: ["X-Men: Apocalypse"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Science Fiction, Fantasy',
        release_year: '2016',
        notable_actor: '',
        director: 'X-Men: Apocalypse, 2016 director',
    },
    '748': {
        content: '',
        submitted_by: '',
        answers: ["Warrior"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Action',
        release_year: '2011',
        notable_actor: '',
        director: 'Warrior, 2011 director',
    },
    '749': {
        content: '',
        submitted_by: '',
        answers: ["Scary Movie 3"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2003',
        notable_actor: '',
        director: 'Scary Movie 3, 2003 director',
    },
    '750': {
        content: '',
        submitted_by: '',
        answers: ["Epic"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Adventure, Family, Fantasy',
        release_year: '2013',
        notable_actor: '',
        director: 'Epic, 2013 director',
    },
    '751': {
        content: '',
        submitted_by: '',
        answers: ["Legend"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Crime, Thriller',
        release_year: '2015',
        notable_actor: '',
        director: 'Legend, 2015 director',
    },
    '752': {
        content: '',
        submitted_by: '',
        answers: ["Life Is Beautiful"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Drama',
        release_year: '1997',
        notable_actor: '',
        director: 'Life Is Beautiful, 1997 director',
    },
    '753': {
        content: '',
        submitted_by: '',
        answers: ["The Great Wall"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Fantasy',
        release_year: '2016',
        notable_actor: '',
        director: 'The Great Wall, 2016 director',
    },
    '754': {
        content: '',
        submitted_by: '',
        answers: ["Aliens"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller, Science Fiction',
        release_year: '1986',
        notable_actor: '',
        director: 'Aliens, 1986 director',
    },
    '755': {
        content: '',
        submitted_by: '',
        answers: ["Matilda"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Family, Fantasy',
        release_year: '1996',
        notable_actor: '',
        director: 'Matilda, 1996 director',
    },
    '756': {
        content: '',
        submitted_by: '',
        answers: ["Hercules"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure',
        release_year: '2014',
        notable_actor: '',
        director: 'Hercules, 2014 director',
    },
    '757': {
        content: '',
        submitted_by: '',
        answers: ["Final Destination"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror',
        release_year: '2000',
        notable_actor: '',
        director: 'Final Destination, 2000 director',
    },
    '758': {
        content: '',
        submitted_by: '',
        answers: ["War Horse"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'War, Drama, Action',
        release_year: '2011',
        notable_actor: '',
        director: 'War Horse, 2011 director',
    },
    '759': {
        content: '',
        submitted_by: '',
        answers: ["The Angry Birds Movie"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Adventure, Comedy',
        release_year: '2016',
        notable_actor: '',
        director: 'The Angry Birds Movie, 2016 director',
    },
    '760': {
        content: '',
        submitted_by: '',
        answers: ["Van Helsing"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Adventure, Action',
        release_year: '2004',
        notable_actor: '',
        director: 'Van Helsing, 2004 director',
    },
    '761': {
        content: '',
        submitted_by: '',
        answers: ["Pok\u00e9mon the Movie: I Choose You!"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Animation, Adventure, Fantasy',
        release_year: '2017',
        notable_actor: '',
        director: 'Pokémon the Movie: I Choose You!, 2017 director',
    },
    '762': {
        content: '',
        submitted_by: '',
        answers: ["Insurgent"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Science Fiction, Thriller',
        release_year: '2015',
        notable_actor: '',
        director: 'Insurgent, 2015 director',
    },
    '763': {
        content: '',
        submitted_by: '',
        answers: ["Ice Age: Collision Course"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Animation, Family, Comedy, Science Fiction',
        release_year: '2016',
        notable_actor: '',
        director: 'Ice Age: Collision Course, 2016 director',
    },
    '764': {
        content: '',
        submitted_by: '',
        answers: ["The Predator"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Action, Adventure',
        release_year: '2018',
        notable_actor: '',
        director: 'The Predator, 2018 director',
    },
    '765': {
        content: '',
        submitted_by: '',
        answers: ["TRON: Legacy"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Science Fiction',
        release_year: '2010',
        notable_actor: '',
        director: 'TRON: Legacy, 2010 director',
    },
    '766': {
        content: '',
        submitted_by: '',
        answers: ["The Deep House"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror',
        release_year: '2021',
        notable_actor: '',
        director: 'The Deep House, 2021 director',
    },
    '767': {
        content: '',
        submitted_by: '',
        answers: ["Lady Chatterley's Lover"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Romance',
        release_year: '2022',
        notable_actor: '',
        director: 'Lady Chatterley\'s Lover, 2022 director',
    },
    '768': {
        content: '',
        submitted_by: '',
        answers: ["Host"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller, Mystery',
        release_year: '2020',
        notable_actor: '',
        director: 'Host, 2020 director',
    },
    '769': {
        content: '',
        submitted_by: '',
        answers: ["RV"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Comedy',
        release_year: '2006',
        notable_actor: '',
        director: 'RV, 2006 director',
    },
    '770': {
        content: '',
        submitted_by: '',
        answers: ["Maze Runner: The Scorch Trials"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Action, Thriller, Adventure',
        release_year: '2015',
        notable_actor: '',
        director: 'Maze Runner: The Scorch Trials, 2015 director',
    },
    '771': {
        content: '',
        submitted_by: '',
        answers: ["Season of the Witch"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy, Action',
        release_year: '2011',
        notable_actor: '',
        director: 'Season of the Witch, 2011 director',
    },
    '772': {
        content: '',
        submitted_by: '',
        answers: ["Pinocchio"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family',
        release_year: '1940',
        notable_actor: '',
        director: 'Pinocchio, 1940 director',
    },
    '773': {
        content: '',
        submitted_by: '',
        answers: ["The Fabelmans"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '2022',
        notable_actor: '',
        director: 'The Fabelmans, 2022 director',
    },
    '774': {
        content: '',
        submitted_by: '',
        answers: ["Maze Runner: The Death Cure"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Action, Adventure, Thriller',
        release_year: '2018',
        notable_actor: '',
        director: 'Maze Runner: The Death Cure, 2018 director',
    },
    '775': {
        content: '',
        submitted_by: '',
        answers: ["The Fifth Element"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy, Action, Thriller, Science Fiction',
        release_year: '1997',
        notable_actor: '',
        director: 'The Fifth Element, 1997 director',
    },
    '776': {
        content: '',
        submitted_by: '',
        answers: ["Bumblebee"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '2018',
        notable_actor: '',
        director: 'Bumblebee, 2018 director',
    },
    '777': {
        content: '',
        submitted_by: '',
        answers: ["Need for Speed"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Crime, Drama, Thriller',
        release_year: '2014',
        notable_actor: '',
        director: 'Need for Speed, 2014 director',
    },
    '778': {
        content: '',
        submitted_by: '',
        answers: ["Mechanic: Resurrection"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Crime, Thriller',
        release_year: '2016',
        notable_actor: '',
        director: 'Mechanic: Resurrection, 2016 director',
    },
    '779': {
        content: '',
        submitted_by: '',
        answers: ["G.I. Joe: The Rise of Cobra"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Thriller, Science Fiction',
        release_year: '2009',
        notable_actor: '',
        director: 'G.I. Joe: The Rise of Cobra, 2009 director',
    },
    '780': {
        content: '',
        submitted_by: '',
        answers: ["Undisputed II: Last Man Standing"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Crime, Thriller',
        release_year: '2006',
        notable_actor: '',
        director: 'Undisputed II: Last Man Standing, 2006 director',
    },
    '781': {
        content: '',
        submitted_by: '',
        answers: ["Django Unchained"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Western',
        release_year: '2012',
        notable_actor: '',
        director: 'Django Unchained, 2012 director',
    },
    '782': {
        content: '',
        submitted_by: '',
        answers: ["Now You See Me"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Crime',
        release_year: '2013',
        notable_actor: '',
        director: 'Now You See Me, 2013 director',
    },
    '783': {
        content: '',
        submitted_by: '',
        answers: ["Nymphomaniac: Vol. II"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Mystery',
        release_year: '2013',
        notable_actor: '',
        director: 'Nymphomaniac: Vol. II, 2013 director',
    },
    '784': {
        content: '',
        submitted_by: '',
        answers: ["American Pie Presents: The Naked Mile"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2006',
        notable_actor: '',
        director: 'American Pie Presents: The Naked Mile, 2006 director',
    },
    '785': {
        content: '',
        submitted_by: '',
        answers: ["The 355"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller',
        release_year: '2022',
        notable_actor: '',
        director: 'The 355, 2022 director',
    },
    '786': {
        content: '',
        submitted_by: '',
        answers: ["Alice Through the Looking Glass"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Family, Fantasy',
        release_year: '2016',
        notable_actor: '',
        director: 'Alice Through the Looking Glass, 2016 director',
    },
    '787': {
        content: '',
        submitted_by: '',
        answers: ["The Lorax"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family',
        release_year: '2012',
        notable_actor: '',
        director: 'The Lorax, 2012 director',
    },
    '788': {
        content: '',
        submitted_by: '',
        answers: ["The Intouchables"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Comedy',
        release_year: '2011',
        notable_actor: '',
        director: 'The Intouchables, 2011 director',
    },
    '789': {
        content: '',
        submitted_by: '',
        answers: ["Sharper"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Crime, Drama',
        release_year: '2023',
        notable_actor: '',
        director: 'Sharper, 2023 director',
    },
    '790': {
        content: '',
        submitted_by: '',
        answers: ["Spiral: From the Book of Saw"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Crime, Mystery',
        release_year: '2021',
        notable_actor: '',
        director: 'Spiral: From the Book of Saw, 2021 director',
    },
    '791': {
        content: '',
        submitted_by: '',
        answers: ["Transformers: Beginnings"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Action, Thriller, Science Fiction',
        release_year: '2006',
        notable_actor: '',
        director: 'Transformers: Beginnings, 2006 director',
    },
    '792': {
        content: '',
        submitted_by: '',
        answers: ["Riddick"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Action, Thriller',
        release_year: '2013',
        notable_actor: '',
        director: 'Riddick, 2013 director',
    },
    '793': {
        content: '',
        submitted_by: '',
        answers: ["Groot's Pursuit"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Comedy, Science Fiction',
        release_year: '2022',
        notable_actor: '',
        director: 'Groot\'s Pursuit, 2022 director',
    },
    '794': {
        content: '',
        submitted_by: '',
        answers: ["Knock Knock"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Thriller, Horror',
        release_year: '2015',
        notable_actor: '',
        director: 'Knock Knock, 2015 director',
    },
    '795': {
        content: '',
        submitted_by: '',
        answers: ["The Blind Side"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '2009',
        notable_actor: '',
        director: 'The Blind Side, 2009 director',
    },
    '796': {
        content: '',
        submitted_by: '',
        answers: ["Night at the Museum: Secret of the Tomb"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Comedy, Fantasy, Family',
        release_year: '2014',
        notable_actor: '',
        director: 'Night at the Museum: Secret of the Tomb, 2014 director',
    },
    '797': {
        content: '',
        submitted_by: '',
        answers: ["That's My Boy"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Drama',
        release_year: '2012',
        notable_actor: '',
        director: 'That\'s My Boy, 2012 director',
    },
    '798': {
        content: '',
        submitted_by: '',
        answers: ["Alice in Wonderland"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Fantasy, Adventure',
        release_year: '1951',
        notable_actor: '',
        director: 'Alice in Wonderland, 1951 director',
    },
    '799': {
        content: '',
        submitted_by: '',
        answers: ["Trolls"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Animation, Fantasy, Adventure, Comedy, Music',
        release_year: '2016',
        notable_actor: '',
        director: 'Trolls, 2016 director',
    },
    '800': {
        content: '',
        submitted_by: '',
        answers: ["Valerian and the City of a Thousand Planets"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Science Fiction, Action',
        release_year: '2017',
        notable_actor: '',
        director: 'Valerian and the City of a Thousand Planets, 2017 director',
    },
    '801': {
        content: '',
        submitted_by: '',
        answers: ["Divergent"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '2014',
        notable_actor: '',
        director: 'Divergent, 2014 director',
    },
    '802': {
        content: '',
        submitted_by: '',
        answers: ["Raiders of the Lost Ark"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action',
        release_year: '1981',
        notable_actor: '',
        director: 'Raiders of the Lost Ark, 1981 director',
    },
    '803': {
        content: '',
        submitted_by: '',
        answers: ["Glory Road"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, History',
        release_year: '2006',
        notable_actor: '',
        director: 'Glory Road, 2006 director',
    },
    '804': {
        content: '',
        submitted_by: '',
        answers: ["Ava"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Crime, Drama, Adventure, Thriller',
        release_year: '2020',
        notable_actor: '',
        director: 'Ava, 2020 director',
    },
    '805': {
        content: '',
        submitted_by: '',
        answers: ["The Curse of La Llorona"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery, Thriller',
        release_year: '2019',
        notable_actor: '',
        director: 'The Curse of La Llorona, 2019 director',
    },
    '806': {
        content: '',
        submitted_by: '',
        answers: ["Norbit"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2007',
        notable_actor: '',
        director: 'Norbit, 2007 director',
    },
    '807': {
        content: '',
        submitted_by: '',
        answers: ["Ford v Ferrari"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Action, History',
        release_year: '2019',
        notable_actor: '',
        director: 'Ford v Ferrari, 2019 director',
    },
    '808': {
        content: '',
        submitted_by: '',
        answers: ["The Little Mermaid"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Adventure, Fantasy, Romance, Drama',
        release_year: '2018',
        notable_actor: '',
        director: 'The Little Mermaid, 2018 director',
    },
    '809': {
        content: '',
        submitted_by: '',
        answers: ["Underworld: Blood Wars"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Action, Thriller, Horror',
        release_year: '2016',
        notable_actor: '',
        director: 'Underworld: Blood Wars, 2016 director',
    },
    '810': {
        content: '',
        submitted_by: '',
        answers: ["The Purge: Anarchy"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2014',
        notable_actor: '',
        director: 'The Purge: Anarchy, 2014 director',
    },
    '811': {
        content: '',
        submitted_by: '',
        answers: ["The Magnificent Seven"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Western',
        release_year: '2016',
        notable_actor: '',
        director: 'The Magnificent Seven, 2016 director',
    },
    '812': {
        content: '',
        submitted_by: '',
        answers: ["Star Wars: Episode I - The Phantom Menace"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Science Fiction',
        release_year: '1999',
        notable_actor: '',
        director: 'Star Wars: Episode I - The Phantom Menace, 1999 director',
    },
    '813': {
        content: '',
        submitted_by: '',
        answers: ["Brightburn"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Science Fiction, Thriller, Mystery',
        release_year: '2019',
        notable_actor: '',
        director: 'Brightburn, 2019 director',
    },
    '814': {
        content: '',
        submitted_by: '',
        answers: ["Titanic II"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Thriller',
        release_year: '2010',
        notable_actor: '',
        director: 'Titanic II, 2010 director',
    },
    '815': {
        content: '',
        submitted_by: '',
        answers: ["Zack and Miri Make a Porno"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2008',
        notable_actor: '',
        director: 'Zack and Miri Make a Porno, 2008 director',
    },
    '816': {
        content: '',
        submitted_by: '',
        answers: ["Rio 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Animation, Comedy, Family',
        release_year: '2014',
        notable_actor: '',
        director: 'Rio 2, 2014 director',
    },
    '817': {
        content: '',
        submitted_by: '',
        answers: ["Conan the Barbarian"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy, Action',
        release_year: '1982',
        notable_actor: '',
        director: 'Conan the Barbarian, 1982 director',
    },
    '818': {
        content: '',
        submitted_by: '',
        answers: ["Cinderella II: Dreams Come True"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Animation, Romance, Fantasy',
        release_year: '2002',
        notable_actor: '',
        director: 'Cinderella II: Dreams Come True, 2002 director',
    },
    '819': {
        content: '',
        submitted_by: '',
        answers: ["Independence Day: Resurgence"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '2016',
        notable_actor: '',
        director: 'Independence Day: Resurgence, 2016 director',
    },
    '820': {
        content: '',
        submitted_by: '',
        answers: ["Battle: Los Angeles"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Action',
        release_year: '2011',
        notable_actor: '',
        director: 'Battle: Los Angeles, 2011 director',
    },
    '821': {
        content: '',
        submitted_by: '',
        answers: ["Werewolf by Night"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Fantasy, Horror, TV Movie',
        release_year: '2022',
        notable_actor: '',
        director: 'Werewolf by Night, 2022 director',
    },
    '822': {
        content: '',
        submitted_by: '',
        answers: ["Straight Outta Nowhere: Scooby-Doo! Meets Courage the Cowardly Dog"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Mystery, Family, Fantasy',
        release_year: '2021',
        notable_actor: '',
        director: 'Straight Outta Nowhere: Scooby-Doo! Meets Courage the Cowardly Dog, 2021 director',
    },
    '823': {
        content: '',
        submitted_by: '',
        answers: ["Rush Hour 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Comedy, Crime',
        release_year: '2001',
        notable_actor: '',
        director: 'Rush Hour 2, 2001 director',
    },
    '824': {
        content: '',
        submitted_by: '',
        answers: ["Revolutionary Road"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Romance',
        release_year: '2008',
        notable_actor: '',
        director: 'Revolutionary Road, 2008 director',
    },
    '825': {
        content: '',
        submitted_by: '',
        answers: ["Greenland"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller, Drama',
        release_year: '2020',
        notable_actor: '',
        director: 'Greenland, 2020 director',
    },
    '826': {
        content: '',
        submitted_by: '',
        answers: ["One Shot"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action',
        release_year: '2021',
        notable_actor: '',
        director: 'One Shot, 2021 director',
    },
    '827': {
        content: '',
        submitted_by: '',
        answers: ["Men in Black 3"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Comedy, Science Fiction',
        release_year: '2012',
        notable_actor: '',
        director: 'Men in Black 3, 2012 director',
    },
    '828': {
        content: '',
        submitted_by: '',
        answers: ["I'm Not Ashamed"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '2016',
        notable_actor: '',
        director: 'I\'m Not Ashamed, 2016 director',
    },
    '829': {
        content: '',
        submitted_by: '',
        answers: ["The Mask"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Romance, Comedy, Crime, Fantasy',
        release_year: '1994',
        notable_actor: '',
        director: 'The Mask, 1994 director',
    },
    '830': {
        content: '',
        submitted_by: '',
        answers: ["Battleship"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Action, Adventure, Science Fiction',
        release_year: '2012',
        notable_actor: '',
        director: 'Battleship, 2012 director',
    },
    '831': {
        content: '',
        submitted_by: '',
        answers: ["Hotel Transylvania 3: Summer Vacation"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Family, Fantasy',
        release_year: '2018',
        notable_actor: '',
        director: 'Hotel Transylvania 3: Summer Vacation, 2018 director',
    },
    '832': {
        content: '',
        submitted_by: '',
        answers: ["Just a Breath Away"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Science Fiction, Thriller',
        release_year: '2018',
        notable_actor: '',
        director: 'Just a Breath Away, 2018 director',
    },
    '833': {
        content: '',
        submitted_by: '',
        answers: ["The Jungle Book"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Adventure, Drama, Fantasy, Animation',
        release_year: '2016',
        notable_actor: '',
        director: 'The Jungle Book, 2016 director',
    },
    '834': {
        content: '',
        submitted_by: '',
        answers: ["Jaws"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller, Adventure',
        release_year: '1975',
        notable_actor: '',
        director: 'Jaws, 1975 director',
    },
    '835': {
        content: '',
        submitted_by: '',
        answers: ["Green Lantern"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Thriller, Science Fiction',
        release_year: '2011',
        notable_actor: '',
        director: 'Green Lantern, 2011 director',
    },
    '836': {
        content: '',
        submitted_by: '',
        answers: ["Midsommar"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Drama, Mystery',
        release_year: '2019',
        notable_actor: '',
        director: 'Midsommar, 2019 director',
    },
    '837': {
        content: '',
        submitted_by: '',
        answers: ["Shooter"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Drama, Mystery, Thriller, Crime',
        release_year: '2007',
        notable_actor: '',
        director: 'Shooter, 2007 director',
    },
    '838': {
        content: '',
        submitted_by: '',
        answers: ["The Lion King 1\u00bd"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Animation, Comedy, Action',
        release_year: '2004',
        notable_actor: '',
        director: 'The Lion King 1½, 2004 director',
    },
    '839': {
        content: '',
        submitted_by: '',
        answers: ["The Road to El Dorado"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Adventure, Animation, Comedy, Fantasy',
        release_year: '2000',
        notable_actor: '',
        director: 'The Road to El Dorado, 2000 director',
    },
    '840': {
        content: '',
        submitted_by: '',
        answers: ["Deep Water"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Mystery, Thriller',
        release_year: '2022',
        notable_actor: '',
        director: 'Deep Water, 2022 director',
    },
    '841': {
        content: '',
        submitted_by: '',
        answers: ["T\u00c1R"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Music',
        release_year: '2022',
        notable_actor: '',
        director: 'TÁR, 2022 director',
    },
    '842': {
        content: '',
        submitted_by: '',
        answers: ["Injustice"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Science Fiction, Fantasy, Action',
        release_year: '2021',
        notable_actor: '',
        director: 'Injustice, 2021 director',
    },
    '843': {
        content: '',
        submitted_by: '',
        answers: ["Sicario"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Crime, Thriller',
        release_year: '2015',
        notable_actor: '',
        director: 'Sicario, 2015 director',
    },
    '844': {
        content: '',
        submitted_by: '',
        answers: ["The Wizard of Oz"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy, Family',
        release_year: '1939',
        notable_actor: '',
        director: 'The Wizard of Oz, 1939 director',
    },
    '845': {
        content: '',
        submitted_by: '',
        answers: ["American Pie 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Romance',
        release_year: '2001',
        notable_actor: '',
        director: 'American Pie 2, 2001 director',
    },
    '846': {
        content: '',
        submitted_by: '',
        answers: ["Rambo: First Blood Part II"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Thriller, War',
        release_year: '1985',
        notable_actor: '',
        director: 'Rambo: First Blood Part II, 1985 director',
    },
    '847': {
        content: '',
        submitted_by: '',
        answers: ["Vacation"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2015',
        notable_actor: '',
        director: 'Vacation, 2015 director',
    },
    '848': {
        content: '',
        submitted_by: '',
        answers: ["Home"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Comedy, Animation, Science Fiction, Family',
        release_year: '2015',
        notable_actor: '',
        director: 'Home, 2015 director',
    },
    '849': {
        content: '',
        submitted_by: '',
        answers: ["Ghost Rider"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Action, Fantasy',
        release_year: '2007',
        notable_actor: '',
        director: 'Ghost Rider, 2007 director',
    },
    '850': {
        content: '',
        submitted_by: '',
        answers: ["Mission: Impossible - Ghost Protocol"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller, Adventure',
        release_year: '2011',
        notable_actor: '',
        director: 'Mission: Impossible - Ghost Protocol, 2011 director',
    },
    '851': {
        content: '',
        submitted_by: '',
        answers: ["Barbarian"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery, Thriller',
        release_year: '2022',
        notable_actor: '',
        director: 'Barbarian, 2022 director',
    },
    '852': {
        content: '',
        submitted_by: '',
        answers: ["The Grinch"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Comedy, Fantasy',
        release_year: '2018',
        notable_actor: '',
        director: 'The Grinch, 2018 director',
    },
    '853': {
        content: '',
        submitted_by: '',
        answers: ["Unhinged"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller',
        release_year: '2020',
        notable_actor: '',
        director: 'Unhinged, 2020 director',
    },
    '854': {
        content: '',
        submitted_by: '',
        answers: ["Crank: High Voltage"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller, Crime',
        release_year: '2009',
        notable_actor: '',
        director: 'Crank: High Voltage, 2009 director',
    },
    '855': {
        content: '',
        submitted_by: '',
        answers: ["The Cellar"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery',
        release_year: '2022',
        notable_actor: '',
        director: 'The Cellar, 2022 director',
    },
    '856': {
        content: '',
        submitted_by: '',
        answers: ["Ben-Hur"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Drama, History',
        release_year: '1959',
        notable_actor: '',
        director: 'Ben-Hur, 1959 director',
    },
    '857': {
        content: '',
        submitted_by: '',
        answers: ["V/H/S/99"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2022',
        notable_actor: '',
        director: 'V/H/S/99, 2022 director',
    },
    '858': {
        content: '',
        submitted_by: '',
        answers: ["Knives Out"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Crime, Mystery',
        release_year: '2019',
        notable_actor: '',
        director: 'Knives Out, 2019 director',
    },
    '859': {
        content: '',
        submitted_by: '',
        answers: ["I Spit on Your Grave III: Vengeance is Mine"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2015',
        notable_actor: '',
        director: 'I Spit on Your Grave III: Vengeance is Mine, 2015 director',
    },
    '860': {
        content: '',
        submitted_by: '',
        answers: ["Rush Hour"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Comedy, Crime',
        release_year: '1998',
        notable_actor: '',
        director: 'Rush Hour, 1998 director',
    },
    '861': {
        content: '',
        submitted_by: '',
        answers: ["The Aristocats"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Family, Adventure',
        release_year: '1970',
        notable_actor: '',
        director: 'The Aristocats, 1970 director',
    },
    '862': {
        content: '',
        submitted_by: '',
        answers: ["Mama"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror',
        release_year: '2013',
        notable_actor: '',
        director: 'Mama, 2013 director',
    },
    '863': {
        content: '',
        submitted_by: '',
        answers: ["Seed of Chucky"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Horror, Thriller',
        release_year: '2004',
        notable_actor: '',
        director: 'Seed of Chucky, 2004 director',
    },
    '864': {
        content: '',
        submitted_by: '',
        answers: ["Bride of Chucky"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Horror',
        release_year: '1998',
        notable_actor: '',
        director: 'Bride of Chucky, 1998 director',
    },
    '865': {
        content: '',
        submitted_by: '',
        answers: ["The Chronicles of Narnia: Prince Caspian"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Family, Fantasy',
        release_year: '2008',
        notable_actor: '',
        director: 'The Chronicles of Narnia: Prince Caspian, 2008 director',
    },
    '866': {
        content: '',
        submitted_by: '',
        answers: ["Perfect Blue"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Thriller',
        release_year: '1997',
        notable_actor: '',
        director: 'Perfect Blue, 1997 director',
    },
    '867': {
        content: '',
        submitted_by: '',
        answers: ["King Kong"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Drama, Action',
        release_year: '2005',
        notable_actor: '',
        director: 'King Kong, 2005 director',
    },
    '868': {
        content: '',
        submitted_by: '',
        answers: ["GoodFellas"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Crime',
        release_year: '1990',
        notable_actor: '',
        director: 'GoodFellas, 1990 director',
    },
    '869': {
        content: '',
        submitted_by: '',
        answers: ["Batman"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Action, Crime',
        release_year: '1989',
        notable_actor: '',
        director: 'Batman, 1989 director',
    },
    '870': {
        content: '',
        submitted_by: '',
        answers: ["Beasts of No Nation"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, War',
        release_year: '2015',
        notable_actor: '',
        director: 'Beasts of No Nation, 2015 director',
    },
    '871': {
        content: '',
        submitted_by: '',
        answers: ["Bad Boys"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'tbd',
        genre: 'Action, Comedy, Crime, Thriller',
        release_year: '1995',
        notable_actor: 'tbd',
        director: 'Bad Boys, 1995 director',
    },
    '872': {
        content: '',
        submitted_by: '',
        answers: ["Alvin and the Chipmunks"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Family, Fantasy, Animation',
        release_year: '2007',
        notable_actor: '',
        director: 'Alvin and the Chipmunks, 2007 director',
    },
    '873': {
        content: '',
        submitted_by: '',
        answers: ["The Martian"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Adventure, Science Fiction',
        release_year: '2015',
        notable_actor: '',
        director: 'The Martian, 2015 director',
    },
    '874': {
        content: '',
        submitted_by: '',
        answers: ["Prince of Persia: The Sands of Time"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy, Action',
        release_year: '2010',
        notable_actor: '',
        director: 'Prince of Persia: The Sands of Time, 2010 director',
    },
    '875': {
        content: '',
        submitted_by: '',
        answers: ["Pride & Prejudice"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Romance',
        release_year: '2005',
        notable_actor: '',
        director: 'Pride & Prejudice, 2005 director',
    },
    '876': {
        content: '',
        submitted_by: '',
        answers: ["Indiana Jones and the Last Crusade"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action',
        release_year: '1989',
        notable_actor: '',
        director: 'Indiana Jones and the Last Crusade, 1989 director',
    },
    '877': {
        content: '',
        submitted_by: '',
        answers: ["Aftersun"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '2022',
        notable_actor: '',
        director: 'Aftersun, 2022 director',
    },
    '878': {
        content: '',
        submitted_by: '',
        answers: ["Dumbo"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family',
        release_year: '1941',
        notable_actor: '',
        director: 'Dumbo, 1941 director',
    },
    '879': {
        content: '',
        submitted_by: '',
        answers: ["Predator 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Action, Thriller',
        release_year: '1990',
        notable_actor: '',
        director: 'Predator 2, 1990 director',
    },
    '880': {
        content: '',
        submitted_by: '',
        answers: ["(500) Days of Summer"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Drama, Romance',
        release_year: '2009',
        notable_actor: '',
        director: '(500) Days of Summer, 2009 director',
    },
    '881': {
        content: '',
        submitted_by: '',
        answers: ["Vivo"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Comedy',
        release_year: '2021',
        notable_actor: '',
        director: 'Vivo, 2021 director',
    },
    '882': {
        content: '',
        submitted_by: '',
        answers: ["Star Wars: Episode III - Revenge of the Sith"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Science Fiction',
        release_year: '2005',
        notable_actor: '',
        director: 'Star Wars: Episode III - Revenge of the Sith, 2005 director',
    },
    '883': {
        content: '',
        submitted_by: '',
        answers: ["Death Race"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller, Science Fiction',
        release_year: '2008',
        notable_actor: '',
        director: 'Death Race, 2008 director',
    },
    '884': {
        content: '',
        submitted_by: '',
        answers: ["God's Not Dead: A Light in Darkness"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '2018',
        notable_actor: '',
        director: 'God\'s Not Dead: A Light in Darkness, 2018 director',
    },
    '885': {
        content: '',
        submitted_by: '',
        answers: ["Terminator 3: Rise of the Machines"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller, Science Fiction',
        release_year: '2003',
        notable_actor: '',
        director: 'Terminator 3: Rise of the Machines, 2003 director',
    },
    '886': {
        content: '',
        submitted_by: '',
        answers: ["Planet of the Apes"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Adventure, Drama, Action',
        release_year: '1968',
        notable_actor: '',
        director: 'Planet of the Apes, 1968 director',
    },
    '887': {
        content: '',
        submitted_by: '',
        answers: ["Paranormal Activity"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery',
        release_year: '2007',
        notable_actor: '',
        director: 'Paranormal Activity, 2007 director',
    },
    '888': {
        content: '',
        submitted_by: '',
        answers: ["13 Hours: The Secret Soldiers of Benghazi"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'War, Action, History, Drama',
        release_year: '2016',
        notable_actor: '',
        director: '13 Hours: The Secret Soldiers of Benghazi, 2016 director',
    },
    '889': {
        content: '',
        submitted_by: '',
        answers: ["Sausage Party"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Animation, Comedy, Fantasy',
        release_year: '2016',
        notable_actor: '',
        director: 'Sausage Party, 2016 director',
    },
    '890': {
        content: '',
        submitted_by: '',
        answers: ["The Vatican Tapes"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Horror',
        release_year: '2015',
        notable_actor: '',
        director: 'The Vatican Tapes, 2015 director',
    },
    '891': {
        content: '',
        submitted_by: '',
        answers: ["Malena"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '2000',
        notable_actor: '',
        director: 'Malena, 2000 director',
    },
    '892': {
        content: '',
        submitted_by: '',
        answers: ["Tangled Ever After"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Action, Family',
        release_year: '2012',
        notable_actor: '',
        director: 'Tangled Ever After, 2012 director',
    },
    '893': {
        content: '',
        submitted_by: '',
        answers: ["RoboCop"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'tbd',
        genre: 'Action, Thriller, Science Fiction',
        release_year: '1987',
        notable_actor: 'tbd',
        director: 'RoboCop, 1987 director',
    },
    '894': {
        content: '',
        submitted_by: '',
        answers: ["A-X-L"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Action, Adventure, Family',
        release_year: '2018',
        notable_actor: '',
        director: 'A-X-L, 2018 director',
    },
    '895': {
        content: '',
        submitted_by: '',
        answers: ["Happy Feet"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Family',
        release_year: '2006',
        notable_actor: '',
        director: 'Happy Feet, 2006 director',
    },
    '896': {
        content: '',
        submitted_by: '',
        answers: ["I Spit on Your Grave"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Crime, Horror',
        release_year: '2010',
        notable_actor: '',
        director: 'I Spit on Your Grave, 2010 director',
    },
    '897': {
        content: '',
        submitted_by: '',
        answers: ["Little Man"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Crime',
        release_year: '2006',
        notable_actor: '',
        director: 'Little Man, 2006 director',
    },
    '898': {
        content: '',
        submitted_by: '',
        answers: ["The Last Mercenary"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Comedy',
        release_year: '2021',
        notable_actor: '',
        director: 'The Last Mercenary, 2021 director',
    },
    '899': {
        content: '',
        submitted_by: '',
        answers: ["Scouts Guide to the Zombie Apocalypse"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Horror',
        release_year: '2015',
        notable_actor: '',
        director: 'Scouts Guide to the Zombie Apocalypse, 2015 director',
    },
    '900': {
        content: '',
        submitted_by: '',
        answers: ["Sex and the City"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Drama, Romance',
        release_year: '2008',
        notable_actor: '',
        director: 'Sex and the City, 2008 director',
    },
    '901': {
        content: '',
        submitted_by: '',
        answers: ["Die Hard: With a Vengeance"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller',
        release_year: '1995',
        notable_actor: '',
        director: 'Die Hard: With a Vengeance, 1995 director',
    },
    '902': {
        content: '',
        submitted_by: '',
        answers: ["Ciao Alberto"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Family, Fantasy',
        release_year: '2021',
        notable_actor: '',
        director: 'Ciao Alberto, 2021 director',
    },
    '903': {
        content: '',
        submitted_by: '',
        answers: ["The Banshees of Inisherin"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Comedy',
        release_year: '2022',
        notable_actor: '',
        director: 'The Banshees of Inisherin, 2022 director',
    },
    '904': {
        content: '',
        submitted_by: '',
        answers: ["Bound by Honor"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Crime, Action, Drama, Thriller',
        release_year: '1993',
        notable_actor: '',
        director: 'Bound by Honor, 1993 director',
    },
    '905': {
        content: '',
        submitted_by: '',
        answers: ["The Hills Have Eyes"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2006',
        notable_actor: '',
        director: 'The Hills Have Eyes, 2006 director',
    },
    '906': {
        content: '',
        submitted_by: '',
        answers: ["101 Dalmatians"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Comedy',
        release_year: '1996',
        notable_actor: '',
        director: '101 Dalmatians, 1996 director',
    },
    '907': {
        content: '',
        submitted_by: '',
        answers: ["Ex Machina"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Science Fiction',
        release_year: '2015',
        notable_actor: '',
        director: 'Ex Machina, 2015 director',
    },
    '908': {
        content: '',
        submitted_by: '',
        answers: ["Major Grom: Plague Doctor"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure',
        release_year: '2021',
        notable_actor: '',
        director: 'Major Grom: Plague Doctor, 2021 director',
    },
    '909': {
        content: '',
        submitted_by: '',
        answers: ["300"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, War',
        release_year: '2007',
        notable_actor: '',
        director: '300, 2007 director',
    },
    '910': {
        content: '',
        submitted_by: '',
        answers: ["Mr. Harrigan's Phone"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Thriller, Horror',
        release_year: '2022',
        notable_actor: '',
        director: 'Mr. Harrigan\'s Phone, 2022 director',
    },
    '911': {
        content: '',
        submitted_by: '',
        answers: ["Lionheart"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action',
        release_year: '1990',
        notable_actor: '',
        director: 'Lionheart, 1990 director',
    },
    '912': {
        content: '',
        submitted_by: '',
        answers: ["Warcraft"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Fantasy',
        release_year: '2016',
        notable_actor: '',
        director: 'Warcraft, 2016 director',
    },
    '913': {
        content: '',
        submitted_by: '',
        answers: ["Olympus Has Fallen"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller',
        release_year: '2013',
        notable_actor: '',
        director: 'Olympus Has Fallen, 2013 director',
    },
    '914': {
        content: '',
        submitted_by: '',
        answers: ["Jupiter Ascending"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Fantasy, Action',
        release_year: '2015',
        notable_actor: '',
        director: 'Jupiter Ascending, 2015 director',
    },
    '915': {
        content: '',
        submitted_by: '',
        answers: ["Trolls Holiday in Harmony"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Fantasy, Family',
        release_year: '2021',
        notable_actor: '',
        director: 'Trolls Holiday in Harmony, 2021 director',
    },
    '916': {
        content: '',
        submitted_by: '',
        answers: ["Monster House"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Family, Fantasy',
        release_year: '2006',
        notable_actor: '',
        director: 'Monster House, 2006 director',
    },
    '917': {
        content: '',
        submitted_by: '',
        answers: ["Troy"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, History, War, Action',
        release_year: '2004',
        notable_actor: '',
        director: 'Troy, 2004 director',
    },
    '918': {
        content: '',
        submitted_by: '',
        answers: ["Saw"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery, Crime',
        release_year: '2004',
        notable_actor: '',
        director: 'Saw, 2004 director',
    },
    '919': {
        content: '',
        submitted_by: '',
        answers: ["Jeepers Creepers 3"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery, Thriller',
        release_year: '2017',
        notable_actor: '',
        director: 'Jeepers Creepers 3, 2017 director',
    },
    '920': {
        content: '',
        submitted_by: '',
        answers: ["Nightcrawler"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Crime, Drama, Thriller',
        release_year: '2014',
        notable_actor: '',
        director: 'Nightcrawler, 2014 director',
    },
    '921': {
        content: '',
        submitted_by: '',
        answers: ["Con Air"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller, Crime',
        release_year: '1997',
        notable_actor: '',
        director: 'Con Air, 1997 director',
    },
    '922': {
        content: '',
        submitted_by: '',
        answers: ["AINBO: Spirit of the Amazon"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Animation, Family, Fantasy',
        release_year: '2021',
        notable_actor: '',
        director: 'AINBO: Spirit of the Amazon, 2021 director',
    },
    '923': {
        content: '',
        submitted_by: '',
        answers: ["Drive"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Thriller, Crime',
        release_year: '2011',
        notable_actor: '',
        director: 'Drive, 2011 director',
    },
    '924': {
        content: '',
        submitted_by: '',
        answers: ["The Greatest Beer Run Ever"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Comedy, War, History',
        release_year: '2022',
        notable_actor: '',
        director: 'The Greatest Beer Run Ever, 2022 director',
    },
    '925': {
        content: '',
        submitted_by: '',
        answers: ["Fear Street: 1994"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Mystery, Horror',
        release_year: '2021',
        notable_actor: '',
        director: 'Fear Street: 1994, 2021 director',
    },
    '926': {
        content: '',
        submitted_by: '',
        answers: ["The Father"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '2020',
        notable_actor: '',
        director: 'The Father, 2020 director',
    },
    '927': {
        content: '',
        submitted_by: '',
        answers: ["Hard Target"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Crime, Thriller',
        release_year: '1993',
        notable_actor: '',
        director: 'Hard Target, 1993 director',
    },
    '928': {
        content: '',
        submitted_by: '',
        answers: ["Ferdinand"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Adventure, Comedy',
        release_year: '2017',
        notable_actor: '',
        director: 'Ferdinand, 2017 director',
    },
    '929': {
        content: '',
        submitted_by: '',
        answers: ["The Last Duel"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Drama, History',
        release_year: '2021',
        notable_actor: '',
        director: 'The Last Duel, 2021 director',
    },
    '930': {
        content: '',
        submitted_by: '',
        answers: ["Die Hard"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller',
        release_year: '1988',
        notable_actor: '',
        director: 'Die Hard, 1988 director',
    },
    '931': {
        content: '',
        submitted_by: '',
        answers: ["Guardians"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Comedy, Fantasy, Science Fiction, Thriller',
        release_year: '2017',
        notable_actor: '',
        director: 'Guardians, 2017 director',
    },
    '932': {
        content: '',
        submitted_by: '',
        answers: ["Outside the Wire"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Action, Science Fiction',
        release_year: '2021',
        notable_actor: '',
        director: 'Outside the Wire, 2021 director',
    },
    '933': {
        content: '',
        submitted_by: '',
        answers: ["Cult of Chucky"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2017',
        notable_actor: '',
        director: 'Cult of Chucky, 2017 director',
    },
    '934': {
        content: '',
        submitted_by: '',
        answers: ["Descendants 3"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, TV Movie, Music, Adventure, Fantasy',
        release_year: '2019',
        notable_actor: '',
        director: 'Descendants 3, 2019 director',
    },
    '935': {
        content: '',
        submitted_by: '',
        answers: ["Clean"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Crime',
        release_year: '2022',
        notable_actor: '',
        director: 'Clean, 2022 director',
    },
    '936': {
        content: '',
        submitted_by: '',
        answers: ["The Iron Giant"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Animation, Science Fiction, Drama',
        release_year: '1999',
        notable_actor: '',
        director: 'The Iron Giant, 1999 director',
    },
    '937': {
        content: '',
        submitted_by: '',
        answers: ["Blade Runner"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Drama, Thriller',
        release_year: '1982',
        notable_actor: '',
        director: 'Blade Runner, 1982 director',
    },
    '938': {
        content: '',
        submitted_by: '',
        answers: ["Dark Phoenix"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Science Fiction, Action, Adventure',
        release_year: '2019',
        notable_actor: '',
        director: 'Dark Phoenix, 2019 director',
    },
    '939': {
        content: '',
        submitted_by: '',
        answers: ["Sherlock Holmes: A Game of Shadows"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Crime, Mystery',
        release_year: '2011',
        notable_actor: '',
        director: 'Sherlock Holmes: A Game of Shadows, 2011 director',
    },
    '940': {
        content: '',
        submitted_by: '',
        answers: ["Puss in Boots: The Three Diablos"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Animation, Comedy, Family',
        release_year: '2012',
        notable_actor: '',
        director: 'Puss in Boots: The Three Diablos, 2012 director',
    },
    '941': {
        content: '',
        submitted_by: '',
        answers: ["Superman"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Action, Adventure',
        release_year: '1978',
        notable_actor: '',
        director: 'Superman, 1978 director',
    },
    '942': {
        content: '',
        submitted_by: '',
        answers: ["Pitch Perfect 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Music',
        release_year: '2015',
        notable_actor: '',
        director: 'Pitch Perfect 2, 2015 director',
    },
    '943': {
        content: '',
        submitted_by: '',
        answers: ["Home Sweet Home Alone"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Crime, Family',
        release_year: '2021',
        notable_actor: '',
        director: 'Home Sweet Home Alone, 2021 director',
    },
    '944': {
        content: '',
        submitted_by: '',
        answers: ["Monsters vs Aliens"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Adventure, Science Fiction',
        release_year: '2009',
        notable_actor: '',
        director: 'Monsters vs Aliens, 2009 director',
    },
    '945': {
        content: '',
        submitted_by: '',
        answers: ["The Invisible Guest"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Mystery, Thriller',
        release_year: '2017',
        notable_actor: '',
        director: 'The Invisible Guest, 2017 director',
    },
    '946': {
        content: '',
        submitted_by: '',
        answers: ["Cloudy with a Chance of Meatballs"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Family',
        release_year: '2009',
        notable_actor: '',
        director: 'Cloudy with a Chance of Meatballs, 2009 director',
    },
    '947': {
        content: '',
        submitted_by: '',
        answers: ["The Punisher"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Crime, Drama',
        release_year: '2004',
        notable_actor: '',
        director: 'The Punisher, 2004 director',
    },
    '948': {
        content: '',
        submitted_by: '',
        answers: ["Psycho"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Drama, Thriller',
        release_year: '1960',
        notable_actor: '',
        director: 'Psycho, 1960 director',
    },
    '949': {
        content: '',
        submitted_by: '',
        answers: ["Jeepers Creepers 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror',
        release_year: '2003',
        notable_actor: '',
        director: 'Jeepers Creepers 2, 2003 director',
    },
    '950': {
        content: '',
        submitted_by: '',
        answers: ["Southpaw"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Drama',
        release_year: '2015',
        notable_actor: '',
        director: 'Southpaw, 2015 director',
    },
    '951': {
        content: '',
        submitted_by: '',
        answers: ["Time Trap"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Adventure',
        release_year: '2017',
        notable_actor: '',
        director: 'Time Trap, 2017 director',
    },
    '952': {
        content: '',
        submitted_by: '',
        answers: ["Hachi: A Dog's Tale"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Family',
        release_year: '2009',
        notable_actor: '',
        director: 'Hachi: A Dog\'s Tale, 2009 director',
    },
    '953': {
        content: '',
        submitted_by: '',
        answers: ["A Dog's Journey"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Adventure, Drama',
        release_year: '2019',
        notable_actor: '',
        director: 'A Dog\'s Journey, 2019 director',
    },
    '954': {
        content: '',
        submitted_by: '',
        answers: ["Dead Silence"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror',
        release_year: '2007',
        notable_actor: '',
        director: 'Dead Silence, 2007 director',
    },
    '955': {
        content: '',
        submitted_by: '',
        answers: ["Taxi Driver"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Crime, Drama',
        release_year: '1976',
        notable_actor: '',
        director: 'Taxi Driver, 1976 director',
    },
    '956': {
        content: '',
        submitted_by: '',
        answers: ["The Book of Life"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Adventure, Comedy, Family, Fantasy',
        release_year: '2014',
        notable_actor: '',
        director: 'The Book of Life, 2014 director',
    },
    '957': {
        content: '',
        submitted_by: '',
        answers: ["An Interview with God"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Mystery',
        release_year: '2018',
        notable_actor: '',
        director: 'An Interview with God, 2018 director',
    },
    '958': {
        content: '',
        submitted_by: '',
        answers: ["Black Crab"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller',
        release_year: '2022',
        notable_actor: '',
        director: 'Black Crab, 2022 director',
    },
    '959': {
        content: '',
        submitted_by: '',
        answers: ["X-Men: Days of Future Past"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '2014',
        notable_actor: '',
        director: 'X-Men: Days of Future Past, 2014 director',
    },
    '960': {
        content: '',
        submitted_by: '',
        answers: ["Suicide Squad"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Fantasy',
        release_year: '2016',
        notable_actor: '',
        director: 'Suicide Squad, 2016 director',
    },
    '961': {
        content: '',
        submitted_by: '',
        answers: ["Red Shoes and the Seven Dwarfs"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Romance, Family, Comedy, Fantasy',
        release_year: '2019',
        notable_actor: '',
        director: 'Red Shoes and the Seven Dwarfs, 2019 director',
    },
    '962': {
        content: '',
        submitted_by: '',
        answers: ["Arrival"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Science Fiction, Mystery',
        release_year: '2016',
        notable_actor: '',
        director: 'Arrival, 2016 director',
    },
    '963': {
        content: '',
        submitted_by: '',
        answers: ["Kickboxer: Retaliation"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Drama',
        release_year: '2018',
        notable_actor: '',
        director: 'Kickboxer: Retaliation, 2018 director',
    },
    '964': {
        content: '',
        submitted_by: '',
        answers: ["The Godfather Part III"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Crime, Drama, Thriller',
        release_year: '1990',
        notable_actor: '',
        director: 'The Godfather Part III, 1990 director',
    },
    '965': {
        content: '',
        submitted_by: '',
        answers: ["Love & Other Drugs"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Comedy, Romance',
        release_year: '2010',
        notable_actor: '',
        director: 'Love & Other Drugs, 2010 director',
    },
    '966': {
        content: '',
        submitted_by: '',
        answers: ["Honor Society"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2022',
        notable_actor: '',
        director: 'Honor Society, 2022 director',
    },
    '967': {
        content: '',
        submitted_by: '',
        answers: ["Peter Rabbit 2: The Runaway"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Comedy, Adventure, Animation, Fantasy',
        release_year: '2021',
        notable_actor: '',
        director: 'Peter Rabbit 2: The Runaway, 2021 director',
    },
    '968': {
        content: '',
        submitted_by: '',
        answers: ["Solo: A Star Wars Story"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Adventure, Action',
        release_year: '2018',
        notable_actor: '',
        director: 'Solo: A Star Wars Story, 2018 director',
    },
    '969': {
        content: '',
        submitted_by: '',
        answers: ["Once Upon a Snowman"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Comedy, Fantasy',
        release_year: '2020',
        notable_actor: '',
        director: 'Once Upon a Snowman, 2020 director',
    },
    '970': {
        content: '',
        submitted_by: '',
        answers: ["Teen Wolf"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Fantasy, Romance',
        release_year: '1985',
        notable_actor: '',
        director: 'Teen Wolf, 1985 director',
    },
    '971': {
        content: '',
        submitted_by: '',
        answers: ["A Monster Calls"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Fantasy',
        release_year: '2016',
        notable_actor: '',
        director: 'A Monster Calls, 2016 director',
    },
    '972': {
        content: '',
        submitted_by: '',
        answers: ["The Son"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '2022',
        notable_actor: '',
        director: 'The Son, 2022 director',
    },
    '973': {
        content: '',
        submitted_by: '',
        answers: ["Primer"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Drama, Thriller',
        release_year: '2004',
        notable_actor: '',
        director: 'Primer, 2004 director',
    },
    '974': {
        content: '',
        submitted_by: '',
        answers: ["No Strings Attached"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Romance',
        release_year: '2011',
        notable_actor: '',
        director: 'No Strings Attached, 2011 director',
    },
    '975': {
        content: '',
        submitted_by: '',
        answers: ["Me Time"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2022',
        notable_actor: '',
        director: 'Me Time, 2022 director',
    },
    '976': {
        content: '',
        submitted_by: '',
        answers: ["Room in Rome"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Romance',
        release_year: '2010',
        notable_actor: '',
        director: 'Room in Rome, 2010 director',
    },
    '977': {
        content: '',
        submitted_by: '',
        answers: ["Resident Evil: Damnation"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Horror, Action',
        release_year: '2012',
        notable_actor: '',
        director: 'Resident Evil: Damnation, 2012 director',
    },
    '978': {
        content: '',
        submitted_by: '',
        answers: ["Looper"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller, Science Fiction',
        release_year: '2012',
        notable_actor: '',
        director: 'Looper, 2012 director',
    },
    '979': {
        content: '',
        submitted_by: '',
        answers: ["Castle in the Sky"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy, Animation, Action, Family, Romance',
        release_year: '1986',
        notable_actor: '',
        director: 'Castle in the Sky, 1986 director',
    },
    '980': {
        content: '',
        submitted_by: '',
        answers: ["Mission: Impossible"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Thriller',
        release_year: '1996',
        notable_actor: '',
        director: 'Mission: Impossible, 1996 director',
    },
    '981': {
        content: '',
        submitted_by: '',
        answers: ["Cannibal Holocaust"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror',
        release_year: '1980',
        notable_actor: '',
        director: 'Cannibal Holocaust, 1980 director',
    },
    '982': {
        content: '',
        submitted_by: '',
        answers: ["Eternal Sunshine of the Spotless Mind"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Drama, Romance',
        release_year: '2004',
        notable_actor: '',
        director: 'Eternal Sunshine of the Spotless Mind, 2004 director',
    },
    '983': {
        content: '',
        submitted_by: '',
        answers: ["Robin Hood"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Drama',
        release_year: '2010',
        notable_actor: '',
        director: 'Robin Hood, 2010 director',
    },
    '984': {
        content: '',
        submitted_by: '',
        answers: ["The Addams Family"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Animation, Comedy, Fantasy',
        release_year: '2019',
        notable_actor: '',
        director: 'The Addams Family, 2019 director',
    },
    '985': {
        content: '',
        submitted_by: '',
        answers: ["Bram Stoker's Dracula"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Romance, Horror',
        release_year: '1992',
        notable_actor: '',
        director: 'Bram Stoker\'s Dracula, 1992 director',
    },
    '986': {
        content: '',
        submitted_by: '',
        answers: ["Descendants"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Music, Adventure, Comedy, Fantasy, TV Movie',
        release_year: '2015',
        notable_actor: '',
        director: 'Descendants, 2015 director',
    },
    '987': {
        content: '',
        submitted_by: '',
        answers: ["Pocahontas"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Animation, Family, Romance',
        release_year: '1995',
        notable_actor: '',
        director: 'Pocahontas, 1995 director',
    },
    '988': {
        content: '',
        submitted_by: '',
        answers: ["Gone Girl"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Mystery, Thriller, Drama',
        release_year: '2014',
        notable_actor: '',
        director: 'Gone Girl, 2014 director',
    },
    '989': {
        content: '',
        submitted_by: '',
        answers: ["The Simpsons: The Good, the Bart, and the Loki"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Family',
        release_year: '2021',
        notable_actor: '',
        director: 'The Simpsons: The Good, the Bart, and the Loki, 2021 director',
    },
    '990': {
        content: '',
        submitted_by: '',
        answers: ["Vesper"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Drama, Adventure',
        release_year: '2022',
        notable_actor: '',
        director: 'Vesper, 2022 director',
    },
    '991': {
        content: '',
        submitted_by: '',
        answers: ["Angel Has Fallen"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller',
        release_year: '2019',
        notable_actor: '',
        director: 'Angel Has Fallen, 2019 director',
    },
    '992': {
        content: '',
        submitted_by: '',
        answers: ["Carriers"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Drama, Horror, Science Fiction, Thriller',
        release_year: '2009',
        notable_actor: '',
        director: 'Carriers, 2009 director',
    },
    '993': {
        content: '',
        submitted_by: '',
        answers: ["The Imitation Game"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'History, Drama, Thriller, War',
        release_year: '2014',
        notable_actor: '',
        director: 'The Imitation Game, 2014 director',
    },
    '994': {
        content: '',
        submitted_by: '',
        answers: ["Mission: Impossible - Rogue Nation"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure',
        release_year: '2015',
        notable_actor: '',
        director: 'Mission: Impossible - Rogue Nation, 2015 director',
    },
    '995': {
        content: '',
        submitted_by: '',
        answers: ["Along with the Gods: The Two Worlds"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Fantasy, Thriller',
        release_year: '2017',
        notable_actor: '',
        director: 'Along with the Gods: The Two Worlds, 2017 director',
    },
    '996': {
        content: '',
        submitted_by: '',
        answers: ["Where the Crawdads Sing"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Mystery, Drama, Thriller',
        release_year: '2022',
        notable_actor: '',
        director: 'Where the Crawdads Sing, 2022 director',
    },
    '997': {
        content: '',
        submitted_by: '',
        answers: ["Wrath of the Titans"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Adventure',
        release_year: '2012',
        notable_actor: '',
        director: 'Wrath of the Titans, 2012 director',
    },
    '998': {
        content: '',
        submitted_by: '',
        answers: ["Sweet Girl"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller, Drama',
        release_year: '2021',
        notable_actor: '',
        director: 'Sweet Girl, 2021 director',
    },
    '999': {
        content: '',
        submitted_by: '',
        answers: ["The Ten Commandments"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, History',
        release_year: '1956',
        notable_actor: '',
        director: 'The Ten Commandments, 1956 director',
    },
    '1000': {
        content: '',
        submitted_by: '',
        answers: ["Empire of Light"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Romance',
        release_year: '2022',
        notable_actor: '',
        director: 'Empire of Light, 2022 director',
    },
    '1001': {
        content: '',
        submitted_by: '',
        answers: ["Tinker Bell and the Great Fairy Rescue"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Adventure, Family, Fantasy',
        release_year: '2010',
        notable_actor: '',
        director: 'Tinker Bell and the Great Fairy Rescue, 2010 director',
    },
    '1002': {
        content: '',
        submitted_by: '',
        answers: ["Underworld: Awakening"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Action, Horror',
        release_year: '2012',
        notable_actor: '',
        director: 'Underworld: Awakening, 2012 director',
    },
    '1003': {
        content: '',
        submitted_by: '',
        answers: ["Redeeming Love"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Romance, Drama, History',
        release_year: '2022',
        notable_actor: '',
        director: 'Redeeming Love, 2022 director',
    },
    '1004': {
        content: '',
        submitted_by: '',
        answers: ["The Exorcism of Emily Rose"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Crime, Drama, Horror, Thriller',
        release_year: '2005',
        notable_actor: '',
        director: 'The Exorcism of Emily Rose, 2005 director',
    },
    '1005': {
        content: '',
        submitted_by: '',
        answers: ["End of the Road"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Crime',
        release_year: '2022',
        notable_actor: '',
        director: 'End of the Road, 2022 director',
    },
    '1006': {
        content: '',
        submitted_by: '',
        answers: ["Halloween"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '1978',
        notable_actor: '',
        director: 'Halloween, 1978 director',
    },
    '1007': {
        content: '',
        submitted_by: '',
        answers: ["Nacho Libre"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Family',
        release_year: '2006',
        notable_actor: '',
        director: 'Nacho Libre, 2006 director',
    },
    '1008': {
        content: '',
        submitted_by: '',
        answers: ["Rush Hour 3"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Comedy, Crime',
        release_year: '2007',
        notable_actor: '',
        director: 'Rush Hour 3, 2007 director',
    },
    '1009': {
        content: '',
        submitted_by: '',
        answers: ["#Alive"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Action, Adventure, Thriller',
        release_year: '2020',
        notable_actor: '',
        director: '#Alive, 2020 director',
    },
    '1010': {
        content: '',
        submitted_by: '',
        answers: ["Copshop"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Crime, Action',
        release_year: '2021',
        notable_actor: '',
        director: 'Copshop, 2021 director',
    },
    '1011': {
        content: '',
        submitted_by: '',
        answers: ["Brazen"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Mystery, Drama',
        release_year: '2022',
        notable_actor: '',
        director: 'Brazen, 2022 director',
    },
    '1012': {
        content: '',
        submitted_by: '',
        answers: ["Baby's Day Out"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Comedy, Adventure, Crime, Drama',
        release_year: '1994',
        notable_actor: '',
        director: 'Baby\'s Day Out, 1994 director',
    },
    '1013': {
        content: '',
        submitted_by: '',
        answers: ["Speed"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Crime',
        release_year: '1994',
        notable_actor: '',
        director: 'Speed, 1994 director',
    },
    '1014': {
        content: '',
        submitted_by: '',
        answers: ["Frequency"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Thriller',
        release_year: '2000',
        notable_actor: '',
        director: 'Frequency, 2000 director',
    },
    '1015': {
        content: '',
        submitted_by: '',
        answers: ["300: Rise of an Empire"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Drama, War',
        release_year: '2014',
        notable_actor: '',
        director: '300: Rise of an Empire, 2014 director',
    },
    '1016': {
        content: '',
        submitted_by: '',
        answers: ["I Want to Eat Your Pancreas"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Drama, Romance',
        release_year: '2018',
        notable_actor: '',
        director: 'I Want to Eat Your Pancreas, 2018 director',
    },
    '1017': {
        content: '',
        submitted_by: '',
        answers: ["L\u00e9on: The Professional"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Crime, Drama, Action',
        release_year: '1994',
        notable_actor: '',
        director: 'Léon: The Professional, 1994 director',
    },
    '1018': {
        content: '',
        submitted_by: '',
        answers: ["Back to the Future Part II"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Comedy, Science Fiction',
        release_year: '1989',
        notable_actor: '',
        director: 'Back to the Future Part II, 1989 director',
    },
    '1019': {
        content: '',
        submitted_by: '',
        answers: ["Star Trek"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Action, Adventure',
        release_year: '2009',
        notable_actor: '',
        director: 'Star Trek, 2009 director',
    },
    '1020': {
        content: '',
        submitted_by: '',
        answers: ["The Sword in the Stone"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family',
        release_year: '1963',
        notable_actor: '',
        director: 'The Sword in the Stone, 1963 director',
    },
    '1021': {
        content: '',
        submitted_by: '',
        answers: ["Dual"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Comedy',
        release_year: '2022',
        notable_actor: '',
        director: 'Dual, 2022 director',
    },
    '1022': {
        content: '',
        submitted_by: '',
        answers: ["Planet of the Apes"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Science Fiction, Action, Adventure',
        release_year: '2001',
        notable_actor: '',
        director: 'Planet of the Apes, 2001 director',
    },
    '1023': {
        content: '',
        submitted_by: '',
        answers: ["Mission: Impossible III"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Thriller',
        release_year: '2006',
        notable_actor: '',
        director: 'Mission: Impossible III, 2006 director',
    },
    '1024': {
        content: '',
        submitted_by: '',
        answers: ["Hellboy"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Horror, Action, Adventure',
        release_year: '2019',
        notable_actor: '',
        director: 'Hellboy, 2019 director',
    },
    '1025': {
        content: '',
        submitted_by: '',
        answers: ["Pet Sematary"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Drama',
        release_year: '1989',
        notable_actor: '',
        director: 'Pet Sematary, 1989 director',
    },
    '1026': {
        content: '',
        submitted_by: '',
        answers: ["Star Wars: Episode II - Attack of the Clones"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Science Fiction',
        release_year: '2002',
        notable_actor: '',
        director: 'Star Wars: Episode II - Attack of the Clones, 2002 director',
    },
    '1027': {
        content: '',
        submitted_by: '',
        answers: ["Redemption Day"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action',
        release_year: '2021',
        notable_actor: '',
        director: 'Redemption Day, 2021 director',
    },
    '1028': {
        content: '',
        submitted_by: '',
        answers: ["Child's Play 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '1990',
        notable_actor: '',
        director: 'Child\'s Play 2, 1990 director',
    },
    '1029': {
        content: '',
        submitted_by: '',
        answers: ["Anastasia"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Fantasy, Adventure',
        release_year: '1997',
        notable_actor: '',
        director: 'Anastasia, 1997 director',
    },
    '1030': {
        content: '',
        submitted_by: '',
        answers: ["Insidious: The Last Key"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery, Thriller',
        release_year: '2018',
        notable_actor: '',
        director: 'Insidious: The Last Key, 2018 director',
    },
    '1031': {
        content: '',
        submitted_by: '',
        answers: ["101 Dalmatians II: Patch's London Adventure"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Adventure, Comedy, Animation',
        release_year: '2002',
        notable_actor: '',
        director: '101 Dalmatians II: Patch\'s London Adventure, 2002 director',
    },
    '1032': {
        content: '',
        submitted_by: '',
        answers: ["The Texas Chainsaw Massacre"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror',
        release_year: '2003',
        notable_actor: '',
        director: 'The Texas Chainsaw Massacre, 2003 director',
    },
    '1033': {
        content: '',
        submitted_by: '',
        answers: ["Virgin Territory"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Comedy, Romance',
        release_year: '2007',
        notable_actor: '',
        director: 'Virgin Territory, 2007 director',
    },
    '1034': {
        content: '',
        submitted_by: '',
        answers: ["Big"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Drama, Comedy, Romance, Family',
        release_year: '1988',
        notable_actor: '',
        director: 'Big, 1988 director',
    },
    '1035': {
        content: '',
        submitted_by: '',
        answers: ["The Wonder"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Mystery',
        release_year: '2022',
        notable_actor: '',
        director: 'The Wonder, 2022 director',
    },
    '1036': {
        content: '',
        submitted_by: '',
        answers: ["Once Upon a Time\u2026 in Hollywood"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Drama, Thriller',
        release_year: '2019',
        notable_actor: '',
        director: 'Once Upon a Time… in Hollywood, 2019 director',
    },
    '1037': {
        content: '',
        submitted_by: '',
        answers: ["Shark Tale"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Action, Comedy, Family',
        release_year: '2004',
        notable_actor: '',
        director: 'Shark Tale, 2004 director',
    },
    '1038': {
        content: '',
        submitted_by: '',
        answers: ["Princess Mononoke"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy, Animation',
        release_year: '1997',
        notable_actor: '',
        director: 'Princess Mononoke, 1997 director',
    },
    '1039': {
        content: '',
        submitted_by: '',
        answers: ["Gods of Egypt"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Fantasy',
        release_year: '2016',
        notable_actor: '',
        director: 'Gods of Egypt, 2016 director',
    },
    '1040': {
        content: '',
        submitted_by: '',
        answers: ["Dolittle"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Fantasy, Adventure',
        release_year: '2020',
        notable_actor: '',
        director: 'Dolittle, 2020 director',
    },
    '1041': {
        content: '',
        submitted_by: '',
        answers: ["Casino Royale"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Thriller',
        release_year: '2006',
        notable_actor: '',
        director: 'Casino Royale, 2006 director',
    },
    '1042': {
        content: '',
        submitted_by: '',
        answers: ["Onward"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Animation, Adventure, Comedy, Fantasy',
        release_year: '2020',
        notable_actor: '',
        director: 'Onward, 2020 director',
    },
    '1043': {
        content: '',
        submitted_by: '',
        answers: ["Edge of Tomorrow"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Science Fiction',
        release_year: '2014',
        notable_actor: '',
        director: 'Edge of Tomorrow, 2014 director',
    },
    '1044': {
        content: '',
        submitted_by: '',
        answers: ["Amsterdam"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Crime, Thriller, Mystery, History',
        release_year: '2022',
        notable_actor: '',
        director: 'Amsterdam, 2022 director',
    },
    '1045': {
        content: '',
        submitted_by: '',
        answers: ["Bridge to Terabithia"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Drama, Family',
        release_year: '2007',
        notable_actor: '',
        director: 'Bridge to Terabithia, 2007 director',
    },
    '1046': {
        content: '',
        submitted_by: '',
        answers: ["Saw II"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror',
        release_year: '2005',
        notable_actor: '',
        director: 'Saw II, 2005 director',
    },
    '1047': {
        content: '',
        submitted_by: '',
        answers: ["The Gentlemen"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Crime, Action',
        release_year: '2020',
        notable_actor: '',
        director: 'The Gentlemen, 2020 director',
    },
    '1048': {
        content: '',
        submitted_by: '',
        answers: ["Final Destination 5"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery',
        release_year: '2011',
        notable_actor: '',
        director: 'Final Destination 5, 2011 director',
    },
    '1049': {
        content: '',
        submitted_by: '',
        answers: ["Mother/Android"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Thriller',
        release_year: '2021',
        notable_actor: '',
        director: 'Mother/Android, 2021 director',
    },
    '1050': {
        content: '',
        submitted_by: '',
        answers: ["Regular Show: The Movie"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Science Fiction, TV Movie',
        release_year: '2015',
        notable_actor: '',
        director: 'Regular Show: The Movie, 2015 director',
    },
    '1051': {
        content: '',
        submitted_by: '',
        answers: ["Final Destination 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery',
        release_year: '2003',
        notable_actor: '',
        director: 'Final Destination 2, 2003 director',
    },
    '1052': {
        content: '',
        submitted_by: '',
        answers: ["The Patriot"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, History, War, Action',
        release_year: '2000',
        notable_actor: '',
        director: 'The Patriot, 2000 director',
    },
    '1053': {
        content: '',
        submitted_by: '',
        answers: ["Interceptor"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller, Adventure',
        release_year: '2022',
        notable_actor: '',
        director: 'Interceptor, 2022 director',
    },
    '1054': {
        content: '',
        submitted_by: '',
        answers: ["Apex"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller, Science Fiction',
        release_year: '2021',
        notable_actor: '',
        director: 'Apex, 2021 director',
    },
    '1055': {
        content: '',
        submitted_by: '',
        answers: ["Now You See Me 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Crime, Thriller, Action, Mystery',
        release_year: '2016',
        notable_actor: '',
        director: 'Now You See Me 2, 2016 director',
    },
    '1056': {
        content: '',
        submitted_by: '',
        answers: ["Fistful of Vengeance"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Fantasy',
        release_year: '2022',
        notable_actor: '',
        director: 'Fistful of Vengeance, 2022 director',
    },
    '1057': {
        content: '',
        submitted_by: '',
        answers: ["Fireproof"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '2008',
        notable_actor: '',
        director: 'Fireproof, 2008 director',
    },
    '1058': {
        content: '',
        submitted_by: '',
        answers: ["Hidden"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Horror',
        release_year: '2015',
        notable_actor: '',
        director: 'Hidden, 2015 director',
    },
    '1059': {
        content: '',
        submitted_by: '',
        answers: ["Den of Thieves"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller, Crime',
        release_year: '2018',
        notable_actor: '',
        director: 'Den of Thieves, 2018 director',
    },
    '1060': {
        content: '',
        submitted_by: '',
        answers: ["Chicken Run"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Family',
        release_year: '2000',
        notable_actor: '',
        director: 'Chicken Run, 2000 director',
    },
    '1061': {
        content: '',
        submitted_by: '',
        answers: ["The Girl with All the Gifts"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Horror, Science Fiction',
        release_year: '2016',
        notable_actor: '',
        director: 'The Girl with All the Gifts, 2016 director',
    },
    '1062': {
        content: '',
        submitted_by: '',
        answers: ["Snow White and the Huntsman"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy, Drama',
        release_year: '2012',
        notable_actor: '',
        director: 'Snow White and the Huntsman, 2012 director',
    },
    '1063': {
        content: '',
        submitted_by: '',
        answers: ["The Purge"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Horror, Thriller',
        release_year: '2013',
        notable_actor: '',
        director: 'The Purge, 2013 director',
    },
    '1064': {
        content: '',
        submitted_by: '',
        answers: ["Missing"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller',
        release_year: '2023',
        notable_actor: '',
        director: 'Missing, 2023 director',
    },
    '1065': {
        content: '',
        submitted_by: '',
        answers: ["Return of the Jedi"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Science Fiction',
        release_year: '1983',
        notable_actor: '',
        director: 'Return of the Jedi, 1983 director',
    },
    '1066': {
        content: '',
        submitted_by: '',
        answers: ["A Nightmare on Elm Street"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror',
        release_year: '1984',
        notable_actor: '',
        director: 'A Nightmare on Elm Street, 1984 director',
    },
    '1067': {
        content: '',
        submitted_by: '',
        answers: ["Rocky II"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '1979',
        notable_actor: '',
        director: 'Rocky II, 1979 director',
    },
    '1068': {
        content: '',
        submitted_by: '',
        answers: ["2001: A Space Odyssey"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Mystery, Adventure',
        release_year: '1968',
        notable_actor: '',
        director: '2001: A Space Odyssey, 1968 director',
    },
    '1069': {
        content: '',
        submitted_by: '',
        answers: ["Despicable Me 3"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Animation, Comedy, Family, Adventure',
        release_year: '2017',
        notable_actor: '',
        director: 'Despicable Me 3, 2017 director',
    },
    '1070': {
        content: '',
        submitted_by: '',
        answers: ["Full Metal Jacket"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, War',
        release_year: '1987',
        notable_actor: '',
        director: 'Full Metal Jacket, 1987 director',
    },
    '1071': {
        content: '',
        submitted_by: '',
        answers: ["Ticket to Paradise"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Romance, Comedy',
        release_year: '2022',
        notable_actor: '',
        director: 'Ticket to Paradise, 2022 director',
    },
    '1072': {
        content: '',
        submitted_by: '',
        answers: ["Friday the 13th"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror',
        release_year: '1980',
        notable_actor: '',
        director: 'Friday the 13th, 1980 director',
    },
    '1073': {
        content: '',
        submitted_by: '',
        answers: ["Wonder"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Family',
        release_year: '2017',
        notable_actor: '',
        director: 'Wonder, 2017 director',
    },
    '1074': {
        content: '',
        submitted_by: '',
        answers: ["Jack the Giant Slayer"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Action, Adventure, Family',
        release_year: '2013',
        notable_actor: '',
        director: 'Jack the Giant Slayer, 2013 director',
    },
    '1075': {
        content: '',
        submitted_by: '',
        answers: ["Cowboys & Aliens"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Science Fiction, Thriller, Western',
        release_year: '2011',
        notable_actor: '',
        director: 'Cowboys & Aliens, 2011 director',
    },
    '1076': {
        content: '',
        submitted_by: '',
        answers: ["Hotel Transylvania 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Family, Fantasy',
        release_year: '2015',
        notable_actor: '',
        director: 'Hotel Transylvania 2, 2015 director',
    },
    '1077': {
        content: '',
        submitted_by: '',
        answers: ["Army of Darkness"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Horror, Comedy',
        release_year: '1992',
        notable_actor: '',
        director: 'Army of Darkness, 1992 director',
    },
    '1078': {
        content: '',
        submitted_by: '',
        answers: ["Paul, Apostle of Christ"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, History',
        release_year: '2018',
        notable_actor: '',
        director: 'Paul, Apostle of Christ, 2018 director',
    },
    '1079': {
        content: '',
        submitted_by: '',
        answers: ["Pan's Labyrinth"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Drama, War',
        release_year: '2006',
        notable_actor: '',
        director: 'Pan\'s Labyrinth, 2006 director',
    },
    '1080': {
        content: '',
        submitted_by: '',
        answers: ["The Emoji Movie"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'Critic: 12%; User: 2.1',
        genre: 'Animation, Family, Comedy',
        release_year: '2017',
        notable_actor: 'T.J. Miller',
        director: 'Tony Leondis',
    },
    '1081': {
        content: '',
        submitted_by: '',
        answers: ["Tremors"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Comedy, Action',
        release_year: '1990',
        notable_actor: '',
        director: 'Tremors, 1990 director',
    },
    '1082': {
        content: '',
        submitted_by: '',
        answers: ["The BFG"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Family, Fantasy',
        release_year: '2016',
        notable_actor: '',
        director: 'The BFG, 2016 director',
    },
    '1083': {
        content: '',
        submitted_by: '',
        answers: ["The Dictator"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2012',
        notable_actor: '',
        director: 'The Dictator, 2012 director',
    },
    '1084': {
        content: '',
        submitted_by: '',
        answers: ["Nightbooks"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Family, Horror',
        release_year: '2021',
        notable_actor: '',
        director: 'Nightbooks, 2021 director',
    },
    '1085': {
        content: '',
        submitted_by: '',
        answers: ["Jolt"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller',
        release_year: '2021',
        notable_actor: '',
        director: 'Jolt, 2021 director',
    },
    '1086': {
        content: '',
        submitted_by: '',
        answers: ["The Protector"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Crime, Drama, Thriller',
        release_year: '2005',
        notable_actor: '',
        director: 'The Protector, 2005 director',
    },
    '1087': {
        content: '',
        submitted_by: '',
        answers: ["Zombieland"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Horror',
        release_year: '2009',
        notable_actor: '',
        director: 'Zombieland, 2009 director',
    },
    '1088': {
        content: '',
        submitted_by: '',
        answers: ["What Happened to Monday"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Thriller, Drama, Mystery, Action',
        release_year: '2017',
        notable_actor: '',
        director: 'What Happened to Monday, 2017 director',
    },
    '1089': {
        content: '',
        submitted_by: '',
        answers: ["The Good Nurse"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Crime, Mystery',
        release_year: '2022',
        notable_actor: '',
        director: 'The Good Nurse, 2022 director',
    },
    '1090': {
        content: '',
        submitted_by: '',
        answers: ["Zombieland: Double Tap"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Horror',
        release_year: '2019',
        notable_actor: '',
        director: 'Zombieland: Double Tap, 2019 director',
    },
    '1091': {
        content: '',
        submitted_by: '',
        answers: ["R.I.P.D."],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Action, Comedy, Crime',
        release_year: '2013',
        notable_actor: '',
        director: 'R.I.P.D., 2013 director',
    },
    '1092': {
        content: '',
        submitted_by: '',
        answers: ["Bones and All"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Horror, Romance',
        release_year: '2022',
        notable_actor: '',
        director: 'Bones and All, 2022 director',
    },
    '1093': {
        content: '',
        submitted_by: '',
        answers: ["Finding Dory"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Animation, Comedy, Family',
        release_year: '2016',
        notable_actor: '',
        director: 'Finding Dory, 2016 director',
    },
    '1094': {
        content: '',
        submitted_by: '',
        answers: ["Kiki's Delivery Service"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Fantasy, Adventure',
        release_year: '1989',
        notable_actor: '',
        director: 'Kiki\'s Delivery Service, 1989 director',
    },
    '1095': {
        content: '',
        submitted_by: '',
        answers: ["Willy Wonka & the Chocolate Factory"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Fantasy, Comedy',
        release_year: '1971',
        notable_actor: '',
        director: 'Willy Wonka & the Chocolate Factory, 1971 director',
    },
    '1096': {
        content: '',
        submitted_by: '',
        answers: ["Captain America: The Winter Soldier"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '2014',
        notable_actor: '',
        director: 'Captain America: The Winter Soldier, 2014 director',
    },
    '1097': {
        content: '',
        submitted_by: '',
        answers: ["Underworld: Rise of the Lycans"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Action, Thriller',
        release_year: '2009',
        notable_actor: '',
        director: 'Underworld: Rise of the Lycans, 2009 director',
    },
    '1098': {
        content: '',
        submitted_by: '',
        answers: ["Mortal Kombat Legends: Battle of the Realms"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Action, Fantasy',
        release_year: '2021',
        notable_actor: '',
        director: 'Mortal Kombat Legends: Battle of the Realms, 2021 director',
    },
    '1099': {
        content: '',
        submitted_by: '',
        answers: ["Spectre"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Thriller',
        release_year: '2015',
        notable_actor: '',
        director: 'Spectre, 2015 director',
    },
    '1100': {
        content: '',
        submitted_by: '',
        answers: ["Antlers"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Horror, Mystery',
        release_year: '2021',
        notable_actor: '',
        director: 'Antlers, 2021 director',
    },
    '1101': {
        content: '',
        submitted_by: '',
        answers: ["Drag Me to Hell"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2009',
        notable_actor: '',
        director: 'Drag Me to Hell, 2009 director',
    },
    '1102': {
        content: '',
        submitted_by: '',
        answers: ["Paul"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Comedy, Science Fiction',
        release_year: '2011',
        notable_actor: '',
        director: 'Paul, 2011 director',
    },
    '1103': {
        content: '',
        submitted_by: '',
        answers: ["Deuce Bigalow: Male Gigolo"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '1999',
        notable_actor: '',
        director: 'Deuce Bigalow: Male Gigolo, 1999 director',
    },
    '1104': {
        content: '',
        submitted_by: '',
        answers: ["The Cat in the Hat"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Adventure, Family, Fantasy',
        release_year: '2003',
        notable_actor: '',
        director: 'The Cat in the Hat, 2003 director',
    },
    '1105': {
        content: '',
        submitted_by: '',
        answers: ["The Butterfly Effect"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Thriller',
        release_year: '2004',
        notable_actor: '',
        director: 'The Butterfly Effect, 2004 director',
    },
    '1106': {
        content: '',
        submitted_by: '',
        answers: ["Death Race 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller, Science Fiction',
        release_year: '2010',
        notable_actor: '',
        director: 'Death Race 2, 2010 director',
    },
    '1107': {
        content: '',
        submitted_by: '',
        answers: ["Mulan II"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Family, Action',
        release_year: '2004',
        notable_actor: '',
        director: 'Mulan II, 2004 director',
    },
    '1108': {
        content: '',
        submitted_by: '',
        answers: ["Hostel"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror',
        release_year: '2005',
        notable_actor: '',
        director: 'Hostel, 2005 director',
    },
    '1109': {
        content: '',
        submitted_by: '',
        answers: ["Teenage Mutant Ninja Turtles: Out of the Shadows"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Comedy, Family, Science Fiction',
        release_year: '2016',
        notable_actor: '',
        director: 'Teenage Mutant Ninja Turtles: Out of the Shadows, 2016 director',
    },
    '1110': {
        content: '',
        submitted_by: '',
        answers: ["Happy Feet Two"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Family',
        release_year: '2011',
        notable_actor: '',
        director: 'Happy Feet Two, 2011 director',
    },
    '1111': {
        content: '',
        submitted_by: '',
        answers: ["Sherlock Holmes"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Crime, Mystery',
        release_year: '2009',
        notable_actor: '',
        director: 'Sherlock Holmes, 2009 director',
    },
    '1112': {
        content: '',
        submitted_by: '',
        answers: ["Salt"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Mystery, Thriller',
        release_year: '2010',
        notable_actor: '',
        director: 'Salt, 2010 director',
    },
    '1113': {
        content: '',
        submitted_by: '',
        answers: ["Upside-Down Magic"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Family, TV Movie',
        release_year: '2020',
        notable_actor: '',
        director: 'Upside-Down Magic, 2020 director',
    },
    '1114': {
        content: '',
        submitted_by: '',
        answers: ["Nobody Sleeps in the Woods Tonight"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2020',
        notable_actor: '',
        director: 'Nobody Sleeps in the Woods Tonight, 2020 director',
    },
    '1115': {
        content: '',
        submitted_by: '',
        answers: ["Space Jam"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'tbd',
        genre: 'Family, Animation, Comedy, Science Fiction',
        release_year: '1996',
        notable_actor: 'tbd',
        director: 'Space Jam, 1996 director',
    },
    '1116': {
        content: '',
        submitted_by: '',
        answers: ["Attack on Titan"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Fantasy, Action, Science Fiction',
        release_year: '2015',
        notable_actor: '',
        director: 'Attack on Titan, 2015 director',
    },
    '1117': {
        content: '',
        submitted_by: '',
        answers: ["Thir13en Ghosts"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2001',
        notable_actor: '',
        director: 'Thir13en Ghosts, 2001 director',
    },
    '1118': {
        content: '',
        submitted_by: '',
        answers: ["Ocean's Eight"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Crime, Comedy, Action',
        release_year: '2018',
        notable_actor: '',
        director: 'Ocean\'s Eight, 2018 director',
    },
    '1119': {
        content: '',
        submitted_by: '',
        answers: ["Odd Thomas"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Mystery, Thriller',
        release_year: '2013',
        notable_actor: '',
        director: 'Odd Thomas, 2013 director',
    },
    '1120': {
        content: '',
        submitted_by: '',
        answers: ["Journey to the Center of the Earth"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Science Fiction, Adventure, Comedy, Family',
        release_year: '2008',
        notable_actor: '',
        director: 'Journey to the Center of the Earth, 2008 director',
    },
    '1121': {
        content: '',
        submitted_by: '',
        answers: ["A Day to Die"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller, Crime',
        release_year: '2022',
        notable_actor: '',
        director: 'A Day to Die, 2022 director',
    },
    '1122': {
        content: '',
        submitted_by: '',
        answers: ["Godzilla"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Action, Thriller',
        release_year: '1998',
        notable_actor: '',
        director: 'Godzilla, 1998 director',
    },
    '1123': {
        content: '',
        submitted_by: '',
        answers: ["Aliens vs Predator: Requiem"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Action, Science Fiction, Thriller, Horror',
        release_year: '2007',
        notable_actor: '',
        director: 'Aliens vs Predator: Requiem, 2007 director',
    },
    '1124': {
        content: '',
        submitted_by: '',
        answers: ["Beetlejuice"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Comedy',
        release_year: '1988',
        notable_actor: '',
        director: 'Beetlejuice, 1988 director',
    },
    '1125': {
        content: '',
        submitted_by: '',
        answers: ["Pete's Dragon"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Family, Fantasy',
        release_year: '2016',
        notable_actor: '',
        director: 'Pete\'s Dragon, 2016 director',
    },
    '1126': {
        content: '',
        submitted_by: '',
        answers: ["Blonde"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '2022',
        notable_actor: '',
        director: 'Blonde, 2022 director',
    },
    '1127': {
        content: '',
        submitted_by: '',
        answers: ["The Lego Movie"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Adventure, Comedy, Fantasy',
        release_year: '2014',
        notable_actor: '',
        director: 'The Lego Movie, 2014 director',
    },
    '1128': {
        content: '',
        submitted_by: '',
        answers: ["Final Destination 3"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery',
        release_year: '2006',
        notable_actor: '',
        director: 'Final Destination 3, 2006 director',
    },
    '1129': {
        content: '',
        submitted_by: '',
        answers: ["Cosmic Sin"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '2021',
        notable_actor: '',
        director: 'Cosmic Sin, 2021 director',
    },
    '1130': {
        content: '',
        submitted_by: '',
        answers: ["Colombiana"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller, Crime, Drama',
        release_year: '2011',
        notable_actor: '',
        director: 'Colombiana, 2011 director',
    },
    '1131': {
        content: '',
        submitted_by: '',
        answers: ["The Pianist"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, War',
        release_year: '2002',
        notable_actor: '',
        director: 'The Pianist, 2002 director',
    },
    '1132': {
        content: '',
        submitted_by: '',
        answers: ["Shaolin Soccer"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Comedy',
        release_year: '2001',
        notable_actor: '',
        director: 'Shaolin Soccer, 2001 director',
    },
    '1133': {
        content: '',
        submitted_by: '',
        answers: ["Treasure Planet"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Animation, Family, Fantasy, Science Fiction',
        release_year: '2002',
        notable_actor: '',
        director: 'Treasure Planet, 2002 director',
    },
    '1134': {
        content: '',
        submitted_by: '',
        answers: ["Fifty Shades of Black"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Romance, Comedy',
        release_year: '2016',
        notable_actor: '',
        director: 'Fifty Shades of Black, 2016 director',
    },
    '1135': {
        content: '',
        submitted_by: '',
        answers: ["We're the Millers"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Crime',
        release_year: '2013',
        notable_actor: '',
        director: 'We\'re the Millers, 2013 director',
    },
    '1136': {
        content: '',
        submitted_by: '',
        answers: ["The Empire Strikes Back"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Science Fiction',
        release_year: '1980',
        notable_actor: '',
        director: 'The Empire Strikes Back, 1980 director',
    },
    '1137': {
        content: '',
        submitted_by: '',
        answers: ["Limitless"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Mystery, Science Fiction',
        release_year: '2011',
        notable_actor: '',
        director: 'Limitless, 2011 director',
    },
    '1138': {
        content: '',
        submitted_by: '',
        answers: ["Ghost Ship"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery, Thriller',
        release_year: '2002',
        notable_actor: '',
        director: 'Ghost Ship, 2002 director',
    },
    '1139': {
        content: '',
        submitted_by: '',
        answers: ["Friends with Benefits"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Romance, Comedy',
        release_year: '2011',
        notable_actor: '',
        director: 'Friends with Benefits, 2011 director',
    },
    '1140': {
        content: '',
        submitted_by: '',
        answers: ["Bohemian Rhapsody"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Music, Drama, History',
        release_year: '2018',
        notable_actor: '',
        director: 'Bohemian Rhapsody, 2018 director',
    },
    '1141': {
        content: '',
        submitted_by: '',
        answers: ["Texas Chainsaw 3D"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2013',
        notable_actor: '',
        director: 'Texas Chainsaw 3D, 2013 director',
    },
    '1142': {
        content: '',
        submitted_by: '',
        answers: ["Heaven Is for Real"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '2014',
        notable_actor: '',
        director: 'Heaven Is for Real, 2014 director',
    },
    '1143': {
        content: '',
        submitted_by: '',
        answers: ["Cinderella III: A Twist in Time"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Romance, Family, Fantasy',
        release_year: '2007',
        notable_actor: '',
        director: 'Cinderella III: A Twist in Time, 2007 director',
    },
    '1144': {
        content: '',
        submitted_by: '',
        answers: ["Pearl Harbor"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, War, History, Romance, Drama',
        release_year: '2001',
        notable_actor: '',
        director: 'Pearl Harbor, 2001 director',
    },
    '1145': {
        content: '',
        submitted_by: '',
        answers: ["About Time"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Romance, Fantasy',
        release_year: '2013',
        notable_actor: '',
        director: 'About Time, 2013 director',
    },
    '1146': {
        content: '',
        submitted_by: '',
        answers: ["Fortress"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller, Crime',
        release_year: '2021',
        notable_actor: '',
        director: 'Fortress, 2021 director',
    },
    '1147': {
        content: '',
        submitted_by: '',
        answers: ["Brian Banks"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '2019',
        notable_actor: '',
        director: 'Brian Banks, 2019 director',
    },
    '1148': {
        content: '',
        submitted_by: '',
        answers: ["The Tax Collector"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Crime, Drama',
        release_year: '2020',
        notable_actor: '',
        director: 'The Tax Collector, 2020 director',
    },
    '1149': {
        content: '',
        submitted_by: '',
        answers: ["Friday"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '1995',
        notable_actor: '',
        director: 'Friday, 1995 director',
    },
    '1150': {
        content: '',
        submitted_by: '',
        answers: ["The Call of the Wild"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Family, Drama',
        release_year: '2020',
        notable_actor: '',
        director: 'The Call of the Wild, 2020 director',
    },
    '1151': {
        content: '',
        submitted_by: '',
        answers: ["Mannequin"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Comedy, Romance',
        release_year: '1987',
        notable_actor: '',
        director: 'Mannequin, 1987 director',
    },
    '1152': {
        content: '',
        submitted_by: '',
        answers: ["Black Hawk Down"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'tbd',
        genre: 'Action, History, War',
        release_year: '2001',
        notable_actor: 'tbd',
        director: 'Black Hawk Down, 2001 director',
    },
    '1153': {
        content: '',
        submitted_by: '',
        answers: ["The In Between"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Romance, Science Fiction, Drama',
        release_year: '2022',
        notable_actor: '',
        director: 'The In Between, 2022 director',
    },
    '1154': {
        content: '',
        submitted_by: '',
        answers: ["Upgrade"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller, Science Fiction',
        release_year: '2018',
        notable_actor: '',
        director: 'Upgrade, 2018 director',
    },
    '1155': {
        content: '',
        submitted_by: '',
        answers: ["Braveheart"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Drama, History, War',
        release_year: '1995',
        notable_actor: '',
        director: 'Braveheart, 1995 director',
    },
    '1156': {
        content: '',
        submitted_by: '',
        answers: ["Dumb and Dumber To"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2014',
        notable_actor: '',
        director: 'Dumb and Dumber To, 2014 director',
    },
    '1157': {
        content: '',
        submitted_by: '',
        answers: ["Vanquish"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Crime, Thriller',
        release_year: '2021',
        notable_actor: '',
        director: 'Vanquish, 2021 director',
    },
    '1158': {
        content: '',
        submitted_by: '',
        answers: ["Fractured"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller',
        release_year: '2019',
        notable_actor: '',
        director: 'Fractured, 2019 director',
    },
    '1159': {
        content: '',
        submitted_by: '',
        answers: ["Joseph: King of Dreams"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Animation',
        release_year: '2000',
        notable_actor: '',
        director: 'Joseph: King of Dreams, 2000 director',
    },
    '1160': {
        content: '',
        submitted_by: '',
        answers: ["6 Underground"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Comedy, Thriller',
        release_year: '2019',
        notable_actor: '',
        director: '6 Underground, 2019 director',
    },
    '1161': {
        content: '',
        submitted_by: '',
        answers: ["I Spit on Your Grave 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Horror, Crime',
        release_year: '2013',
        notable_actor: '',
        director: 'I Spit on Your Grave 2, 2013 director',
    },
    '1162': {
        content: '',
        submitted_by: '',
        answers: ["American Pie Presents: Band Camp"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2005',
        notable_actor: '',
        director: 'American Pie Presents: Band Camp, 2005 director',
    },
    '1163': {
        content: '',
        submitted_by: '',
        answers: ["Into the Wild"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Drama',
        release_year: '2007',
        notable_actor: '',
        director: 'Into the Wild, 2007 director',
    },
    '1164': {
        content: '',
        submitted_by: '',
        answers: ["Jurassic Hunt"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Science Fiction, Thriller',
        release_year: '2021',
        notable_actor: '',
        director: 'Jurassic Hunt, 2021 director',
    },
    '1165': {
        content: '',
        submitted_by: '',
        answers: ["22 vs. Earth"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Comedy, Fantasy, Animation',
        release_year: '2021',
        notable_actor: '',
        director: '22 vs. Earth, 2021 director',
    },
    '1166': {
        content: '',
        submitted_by: '',
        answers: ["Justice League Dark"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Action, Fantasy',
        release_year: '2017',
        notable_actor: '',
        director: 'Justice League Dark, 2017 director',
    },
    '1167': {
        content: '',
        submitted_by: '',
        answers: ["Freddy vs. Jason"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror',
        release_year: '2003',
        notable_actor: '',
        director: 'Freddy vs. Jason, 2003 director',
    },
    '1168': {
        content: '',
        submitted_by: '',
        answers: ["12 Angry Men"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '1957',
        notable_actor: '',
        director: '12 Angry Men, 1957 director',
    },
    '1169': {
        content: '',
        submitted_by: '',
        answers: ["The Matrix Reloaded"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Thriller, Science Fiction',
        release_year: '2003',
        notable_actor: '',
        director: 'The Matrix Reloaded, 2003 director',
    },
    '1170': {
        content: '',
        submitted_by: '',
        answers: ["Fantasia"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family',
        release_year: '1940',
        notable_actor: '',
        director: 'Fantasia, 1940 director',
    },
    '1171': {
        content: '',
        submitted_by: '',
        answers: ["Last Shift"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery',
        release_year: '2014',
        notable_actor: '',
        director: 'Last Shift, 2014 director',
    },
    '1172': {
        content: '',
        submitted_by: '',
        answers: ["The Human Centipede (First Sequence)"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror',
        release_year: '2009',
        notable_actor: '',
        director: 'The Human Centipede (First Sequence), 2009 director',
    },
    '1173': {
        content: '',
        submitted_by: '',
        answers: ["Pamela, A Love Story"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Documentary',
        release_year: '2023',
        notable_actor: '',
        director: 'Pamela, A Love Story, 2023 director',
    },
    '1174': {
        content: '',
        submitted_by: '',
        answers: ["SAS: Red Notice"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Action, Thriller',
        release_year: '2021',
        notable_actor: '',
        director: 'SAS: Red Notice, 2021 director',
    },
    '1175': {
        content: '',
        submitted_by: '',
        answers: ["Indiana Jones and the Kingdom of the Crystal Skull"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action',
        release_year: '2008',
        notable_actor: '',
        director: 'Indiana Jones and the Kingdom of the Crystal Skull, 2008 director',
    },
    '1176': {
        content: '',
        submitted_by: '',
        answers: ["28 Days Later"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller, Science Fiction',
        release_year: '2002',
        notable_actor: '',
        director: '28 Days Later, 2002 director',
    },
    '1177': {
        content: '',
        submitted_by: '',
        answers: ["Selena Gomez: My Mind & Me"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Documentary, Music',
        release_year: '2022',
        notable_actor: '',
        director: 'Selena Gomez: My Mind & Me, 2022 director',
    },
    '1178': {
        content: '',
        submitted_by: '',
        answers: ["Ghostland"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery, Thriller',
        release_year: '2018',
        notable_actor: '',
        director: 'Ghostland, 2018 director',
    },
    '1179': {
        content: '',
        submitted_by: '',
        answers: ["Kubo and the Two Strings"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Adventure, Family',
        release_year: '2016',
        notable_actor: '',
        director: 'Kubo and the Two Strings, 2016 director',
    },
    '1180': {
        content: '',
        submitted_by: '',
        answers: ["Hunter Killer"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller',
        release_year: '2018',
        notable_actor: '',
        director: 'Hunter Killer, 2018 director',
    },
    '1181': {
        content: '',
        submitted_by: '',
        answers: ["American Graffiti"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Drama',
        release_year: '1973',
        notable_actor: '',
        director: 'American Graffiti, 1973 director',
    },
    '1182': {
        content: '',
        submitted_by: '',
        answers: ["The Human Centipede 2 (Full Sequence)"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Horror, Crime',
        release_year: '2011',
        notable_actor: '',
        director: 'The Human Centipede 2 (Full Sequence), 2011 director',
    },
    '1183': {
        content: '',
        submitted_by: '',
        answers: ["Bruce Almighty"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Comedy',
        release_year: '2003',
        notable_actor: '',
        director: 'Bruce Almighty, 2003 director',
    },
    '1184': {
        content: '',
        submitted_by: '',
        answers: ["Orphan"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller, Mystery',
        release_year: '2009',
        notable_actor: '',
        director: 'Orphan, 2009 director',
    },
    '1185': {
        content: '',
        submitted_by: '',
        answers: ["The SpongeBob Movie: Sponge Out of Water"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Adventure, Comedy, Fantasy, Animation',
        release_year: '2015',
        notable_actor: '',
        director: 'The SpongeBob Movie: Sponge Out of Water, 2015 director',
    },
    '1186': {
        content: '',
        submitted_by: '',
        answers: ["Crawl"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Horror, Action',
        release_year: '2019',
        notable_actor: '',
        director: 'Crawl, 2019 director',
    },
    '1187': {
        content: '',
        submitted_by: '',
        answers: ["Jason Bourne"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'tbd',
        genre: 'Action, Thriller, Mystery',
        release_year: '2016',
        notable_actor: 'tbd',
        director: 'Jason Bourne, 2016 director',
    },
    '1188': {
        content: '',
        submitted_by: '',
        answers: ["No Escape"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller',
        release_year: '2015',
        notable_actor: '',
        director: 'No Escape, 2015 director',
    },
    '1189': {
        content: '',
        submitted_by: '',
        answers: ["Tears of the Sun"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'War, Action, Drama',
        release_year: '2003',
        notable_actor: '',
        director: 'Tears of the Sun, 2003 director',
    },
    '1190': {
        content: '',
        submitted_by: '',
        answers: ["A Walk Among the Tombstones"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Crime, Drama, Mystery, Thriller',
        release_year: '2014',
        notable_actor: '',
        director: 'A Walk Among the Tombstones, 2014 director',
    },
    '1191': {
        content: '',
        submitted_by: '',
        answers: ["The Princess Diaries"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Family, Romance',
        release_year: '2001',
        notable_actor: '',
        director: 'The Princess Diaries, 2001 director',
    },
    '1192': {
        content: '',
        submitted_by: '',
        answers: ["The Lobster"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Drama, Romance',
        release_year: '2015',
        notable_actor: '',
        director: 'The Lobster, 2015 director',
    },
    '1193': {
        content: '',
        submitted_by: '',
        answers: ["Bordello of Blood"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Comedy',
        release_year: '1996',
        notable_actor: '',
        director: 'Bordello of Blood, 1996 director',
    },
    '1194': {
        content: '',
        submitted_by: '',
        answers: ["50 First Dates"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Romance',
        release_year: '2004',
        notable_actor: '',
        director: '50 First Dates, 2004 director',
    },
    '1195': {
        content: '',
        submitted_by: '',
        answers: ["Men in Black II"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Comedy, Science Fiction',
        release_year: '2002',
        notable_actor: '',
        director: 'Men in Black II, 2002 director',
    },
    '1196': {
        content: '',
        submitted_by: '',
        answers: ["Blood and Bone"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Drama, Thriller',
        release_year: '2009',
        notable_actor: '',
        director: 'Blood and Bone, 2009 director',
    },
    '1197': {
        content: '',
        submitted_by: '',
        answers: ["American Gangster"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'tbd',
        genre: 'Drama, Crime',
        release_year: '2007',
        notable_actor: 'tbd',
        director: 'American Gangster, 2007 director',
    },
    '1198': {
        content: '',
        submitted_by: '',
        answers: ["The Searchers"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Western',
        release_year: '1956',
        notable_actor: '',
        director: 'The Searchers, 1956 director',
    },
    '1199': {
        content: '',
        submitted_by: '',
        answers: ["Jigsaw"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Crime, Mystery, Thriller',
        release_year: '2017',
        notable_actor: '',
        director: 'Jigsaw, 2017 director',
    },
    '1200': {
        content: '',
        submitted_by: '',
        answers: ["Baby Driver"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Crime',
        release_year: '2017',
        notable_actor: '',
        director: 'Baby Driver, 2017 director',
    },
    '1201': {
        content: '',
        submitted_by: '',
        answers: ["Noah"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Adventure',
        release_year: '2014',
        notable_actor: '',
        director: 'Noah, 2014 director',
    },
    '1202': {
        content: '',
        submitted_by: '',
        answers: ["21 Jump Street"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Comedy, Crime',
        release_year: '2012',
        notable_actor: '',
        director: '21 Jump Street, 2012 director',
    },
    '1203': {
        content: '',
        submitted_by: '',
        answers: ["Armageddon"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller, Science Fiction, Adventure',
        release_year: '1998',
        notable_actor: '',
        director: 'Armageddon, 1998 director',
    },
    '1204': {
        content: '',
        submitted_by: '',
        answers: ["10 Things I Hate About You"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Romance, Drama',
        release_year: '1999',
        notable_actor: '',
        director: '10 Things I Hate About You, 1999 director',
    },
    '1205': {
        content: '',
        submitted_by: '',
        answers: ["I Came By"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller',
        release_year: '2022',
        notable_actor: '',
        director: 'I Came By, 2022 director',
    },
    '1206': {
        content: '',
        submitted_by: '',
        answers: ["Silent Hill"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery',
        release_year: '2006',
        notable_actor: '',
        director: 'Silent Hill, 2006 director',
    },
    '1207': {
        content: '',
        submitted_by: '',
        answers: ["Whitney Houston: I Wanna Dance with Somebody"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Music, History, Drama',
        release_year: '2022',
        notable_actor: '',
        director: 'Whitney Houston: I Wanna Dance with Somebody, 2022 director',
    },
    '1208': {
        content: '',
        submitted_by: '',
        answers: ["Mr. & Mrs. Smith"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'tbd',
        genre: 'Action, Comedy, Drama, Thriller',
        release_year: '2005',
        notable_actor: 'tbd',
        director: 'Mr. & Mrs. Smith, 2005 director',
    },
    '1209': {
        content: '',
        submitted_by: '',
        answers: ["Scoob!"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Family, Mystery',
        release_year: '2020',
        notable_actor: '',
        director: 'Scoob!, 2020 director',
    },
    '1210': {
        content: '',
        submitted_by: '',
        answers: ["42"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '2013',
        notable_actor: '',
        director: '42, 2013 director',
    },
    '1211': {
        content: '',
        submitted_by: '',
        answers: ["Whisper of the Heart"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Drama, Family',
        release_year: '1995',
        notable_actor: '',
        director: 'Whisper of the Heart, 1995 director',
    },
    '1212': {
        content: '',
        submitted_by: '',
        answers: ["mother!"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Horror, Mystery',
        release_year: '2017',
        notable_actor: '',
        director: 'mother!, 2017 director',
    },
    '1213': {
        content: '',
        submitted_by: '',
        answers: ["Rocky Balboa"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '2006',
        notable_actor: '',
        director: 'Rocky Balboa, 2006 director',
    },
    '1214': {
        content: '',
        submitted_by: '',
        answers: ["Elysium"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'sdf',
        genre: 'Science Fiction, Action, Drama, Thriller',
        release_year: '2013',
        notable_actor: 'sdf',
        director: 'Elysium, 2013 director',
    },
    '1215': {
        content: '',
        submitted_by: '',
        answers: ["Rambo III"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'sf',
        genre: 'Action, Adventure, Thriller, War',
        release_year: '1988',
        notable_actor: 'sdf',
        director: 'Rambo III, 1988 director',
    },
    '1216': {
        content: '',
        submitted_by: '',
        answers: ["The Transporter Refueled"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller, Crime',
        release_year: '2015',
        notable_actor: '',
        director: 'The Transporter Refueled, 2015 director',
    },
    '1217': {
        content: '',
        submitted_by: '',
        answers: ["The Pursuit of Happyness"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '2006',
        notable_actor: '',
        director: 'The Pursuit of Happyness, 2006 director',
    },
    '1218': {
        content: '',
        submitted_by: '',
        answers: ["Yes Man"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Romance',
        release_year: '2008',
        notable_actor: '',
        director: 'Yes Man, 2008 director',
    },
    '1219': {
        content: '',
        submitted_by: '',
        answers: ["Bad Boys II"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Comedy, Thriller, Crime',
        release_year: '2003',
        notable_actor: '',
        director: 'Bad Boys II, 2003 director',
    },
    '1220': {
        content: '',
        submitted_by: '',
        answers: ["Don't Look Up"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Comedy, Drama',
        release_year: '2021',
        notable_actor: '',
        director: 'Don\'t Look Up, 2021 director',
    },
    '1221': {
        content: '',
        submitted_by: '',
        answers: ["War Dogs"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'sdf',
        genre: 'Comedy, Crime, Drama',
        release_year: '2016',
        notable_actor: 'sdf',
        director: 'War Dogs, 2016 director',
    },
    '1222': {
        content: '',
        submitted_by: '',
        answers: ["The Other Guys"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'tbdt',
        genre: 'Action, Comedy, Crime',
        release_year: '2010',
        notable_actor: 'tbd',
        director: 'The Other Guys, 2010 director',
    },
    '1223': {
        content: '',
        submitted_by: '',
        answers: ["Wrong Turn 6: Last Resort"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror',
        release_year: '2014',
        notable_actor: '',
        director: 'Wrong Turn 6: Last Resort, 2014 director',
    },
    '1224': {
        content: '',
        submitted_by: '',
        answers: ["True Lies"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller',
        release_year: '1994',
        notable_actor: '',
        director: 'True Lies, 1994 director',
    },
    '1225': {
        content: '',
        submitted_by: '',
        answers: ["Solace"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Crime, Mystery',
        release_year: '2015',
        notable_actor: '',
        director: 'Solace, 2015 director',
    },
    '1226': {
        content: '',
        submitted_by: '',
        answers: ["The Harder They Fall"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Western',
        release_year: '2021',
        notable_actor: '',
        director: 'The Harder They Fall, 2021 director',
    },
    '1227': {
        content: '',
        submitted_by: '',
        answers: ["Oblivion"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Science Fiction, Adventure, Mystery',
        release_year: '2013',
        notable_actor: '',
        director: 'Oblivion, 2013 director',
    },
    '1228': {
        content: '',
        submitted_by: '',
        answers: ["The Magnificent Seven"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'tbd',
        genre: 'Western, Action, Adventure',
        release_year: '1960',
        notable_actor: 'tbd',
        director: 'The Magnificent Seven, 1960 director',
    },
    '1229': {
        content: '',
        submitted_by: '',
        answers: ["He's All That"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Romance',
        release_year: '2021',
        notable_actor: '',
        director: 'He\'s All That, 2021 director',
    },
    '1230': {
        content: '',
        submitted_by: '',
        answers: ["Survival Island"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Mystery, Drama, Romance',
        release_year: '2006',
        notable_actor: '',
        director: 'Survival Island, 2006 director',
    },
    '1231': {
        content: '',
        submitted_by: '',
        answers: ["Undisputed"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Crime',
        release_year: '2002',
        notable_actor: '',
        director: 'Undisputed, 2002 director',
    },
    '1232': {
        content: '',
        submitted_by: '',
        answers: ["Point Break"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller, Crime',
        release_year: '1991',
        notable_actor: '',
        director: 'Point Break, 1991 director',
    },
    '1233': {
        content: '',
        submitted_by: '',
        answers: ["Unknown"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Mystery, Thriller',
        release_year: '2011',
        notable_actor: '',
        director: 'Unknown, 2011 director',
    },
    '1234': {
        content: '',
        submitted_by: '',
        answers: ["Seal Team"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family',
        release_year: '2021',
        notable_actor: '',
        director: 'Seal Team, 2021 director',
    },
    '1235': {
        content: '',
        submitted_by: '',
        answers: ["9"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Animation, Science Fiction, Thriller',
        release_year: '2009',
        notable_actor: '',
        director: '9, 2009 director',
    },
    '1236': {
        content: '',
        submitted_by: '',
        answers: ["Vertigo"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Mystery, Romance, Thriller',
        release_year: '1958',
        notable_actor: '',
        director: 'Vertigo, 1958 director',
    },
    '1237': {
        content: '',
        submitted_by: '',
        answers: ["A Nightmare on Elm Street Part 2: Freddy's Revenge"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror',
        release_year: '1985',
        notable_actor: '',
        director: 'A Nightmare on Elm Street Part 2: Freddy\'s Revenge, 1985 director',
    },
    '1238': {
        content: '',
        submitted_by: '',
        answers: ["Toy Story That Time Forgot"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Adventure, Comedy',
        release_year: '2014',
        notable_actor: '',
        director: 'Toy Story That Time Forgot, 2014 director',
    },
    '1239': {
        content: '',
        submitted_by: '',
        answers: ["Love, Rosie"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Romance, Drama',
        release_year: '2014',
        notable_actor: '',
        director: 'Love, Rosie, 2014 director',
    },
    '1240': {
        content: '',
        submitted_by: '',
        answers: ["Ghost in the Shell"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'tbd',
        genre: 'Action, Animation, Science Fiction',
        release_year: '1995',
        notable_actor: 'tbd',
        director: 'Ghost in the Shell, 1995 director',
    },
    '1241': {
        content: '',
        submitted_by: '',
        answers: ["The 5th Wave"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Adventure, Action',
        release_year: '2016',
        notable_actor: '',
        director: 'The 5th Wave, 2016 director',
    },
    '1242': {
        content: '',
        submitted_by: '',
        answers: ["Robin Hood"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family',
        release_year: '1973',
        notable_actor: '',
        director: 'Robin Hood, 1973 director',
    },
    '1243': {
        content: '',
        submitted_by: '',
        answers: ["The Little Rascals"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Romance, Comedy, Family',
        release_year: '1994',
        notable_actor: '',
        director: 'The Little Rascals, 1994 director',
    },
    '1244': {
        content: '',
        submitted_by: '',
        answers: ["My Little Pony: A New Generation"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'tbd',
        genre: 'Animation, Family, Fantasy, Comedy, Adventure',
        release_year: '2021',
        notable_actor: 'tbd',
        director: 'My Little Pony: A New Generation, 2021 director',
    },
    '1245': {
        content: '',
        submitted_by: '',
        answers: ["My Sister's Keeper"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '2009',
        notable_actor: '',
        director: 'My Sister\'s Keeper, 2009 director',
    },
    '1246': {
        content: '',
        submitted_by: '',
        answers: ["National Lampoon's Vacation"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Adventure',
        release_year: '1983',
        notable_actor: '',
        director: 'National Lampoon\'s Vacation, 1983 director',
    },
    '1247': {
        content: '',
        submitted_by: '',
        answers: ["Insidious: Chapter 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2013',
        notable_actor: '',
        director: 'Insidious: Chapter 2, 2013 director',
    },
    '1248': {
        content: '',
        submitted_by: '',
        answers: ["Independence Day"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'tbd',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '1996',
        notable_actor: 'tbd',
        director: 'Independence Day, 1996 director',
    },
    '1249': {
        content: '',
        submitted_by: '',
        answers: ["Belle"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Science Fiction, Family',
        release_year: '2021',
        notable_actor: '',
        director: 'Belle, 2021 director',
    },
    '1250': {
        content: '',
        submitted_by: '',
        answers: ["Skyline"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Adventure, Action, Thriller',
        release_year: '2010',
        notable_actor: '',
        director: 'Skyline, 2010 director',
    },
    '1251': {
        content: '',
        submitted_by: '',
        answers: ["Hypnotic"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Drama, Horror',
        release_year: '2021',
        notable_actor: '',
        director: 'Hypnotic, 2021 director',
    },
    '1252': {
        content: '',
        submitted_by: '',
        answers: ["Splice"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Science Fiction, Thriller',
        release_year: '2009',
        notable_actor: '',
        director: 'Splice, 2009 director',
    },
    '1253': {
        content: '',
        submitted_by: '',
        answers: ["A Walk to Remember"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Romance',
        release_year: '2002',
        notable_actor: '',
        director: 'A Walk to Remember, 2002 director',
    },
    '1254': {
        content: '',
        submitted_by: '',
        answers: ["Crush"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Romance',
        release_year: '2022',
        notable_actor: '',
        director: 'Crush, 2022 director',
    },
    '1255': {
        content: '',
        submitted_by: '',
        answers: ["Ready or Not"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Horror, Thriller',
        release_year: '2019',
        notable_actor: '',
        director: 'Ready or Not, 2019 director',
    },
    '1256': {
        content: '',
        submitted_by: '',
        answers: ["Secret Society of Second Born Royals"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Comedy, Fantasy',
        release_year: '2020',
        notable_actor: '',
        director: 'Secret Society of Second Born Royals, 2020 director',
    },
    '1257': {
        content: '',
        submitted_by: '',
        answers: ["Those Who Wish Me Dead"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Action',
        release_year: '2021',
        notable_actor: '',
        director: 'Those Who Wish Me Dead, 2021 director',
    },
    '1258': {
        content: '',
        submitted_by: '',
        answers: ["Step Up All In"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Romance, Drama, Music',
        release_year: '2014',
        notable_actor: '',
        director: 'Step Up All In, 2014 director',
    },
    '1259': {
        content: '',
        submitted_by: '',
        answers: ["The Fault in Our Stars"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'tbd',
        genre: 'Romance, Drama',
        release_year: '2014',
        notable_actor: 'tbd',
        director: 'The Fault in Our Stars, 2014 director',
    },
    '1260': {
        content: '',
        submitted_by: '',
        answers: ["The Expendables 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Thriller',
        release_year: '2012',
        notable_actor: '',
        director: 'The Expendables 2, 2012 director',
    },
    '1261': {
        content: '',
        submitted_by: '',
        answers: ["The Fox and the Hound 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Animation, Comedy, Family',
        release_year: '2006',
        notable_actor: '',
        director: 'The Fox and the Hound 2, 2006 director',
    },
    '1262': {
        content: '',
        submitted_by: '',
        answers: ["Cast Away"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Drama',
        release_year: '2000',
        notable_actor: '',
        director: 'Cast Away, 2000 director',
    },
    '1263': {
        content: '',
        submitted_by: '',
        answers: ["Skylines"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Action',
        release_year: '2020',
        notable_actor: '',
        director: 'Skylines, 2020 director',
    },
    '1264': {
        content: '',
        submitted_by: '',
        answers: ["Shaun of the Dead"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'tbd',
        genre: 'Horror, Comedy',
        release_year: '2004',
        notable_actor: 'tbd',
        director: 'Shaun of the Dead, 2004 director',
    },
    '1265': {
        content: '',
        submitted_by: '',
        answers: ["5 Centimeters per Second"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Drama, Family, Romance',
        release_year: '2007',
        notable_actor: '',
        director: '5 Centimeters per Second, 2007 director',
    },
    '1266': {
        content: '',
        submitted_by: '',
        answers: ["Do Revenge"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2022',
        notable_actor: '',
        director: 'Do Revenge, 2022 director',
    },
    '1267': {
        content: '',
        submitted_by: '',
        answers: ["Annihilation"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Horror',
        release_year: '2018',
        notable_actor: '',
        director: 'Annihilation, 2018 director',
    },
    '1268': {
        content: '',
        submitted_by: '',
        answers: ["The Mummy: Tomb of the Dragon Emperor"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Fantasy',
        release_year: '2008',
        notable_actor: '',
        director: 'The Mummy: Tomb of the Dragon Emperor, 2008 director',
    },
    '1269': {
        content: '',
        submitted_by: '',
        answers: ["Casper"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'tbd',
        genre: 'Fantasy, Comedy, Family',
        release_year: '1995',
        notable_actor: 'tbd',
        director: 'Casper, 1995 director',
    },
    '1270': {
        content: '',
        submitted_by: '',
        answers: ["Smurfs: The Lost Village"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Adventure, Animation, Comedy',
        release_year: '2017',
        notable_actor: '',
        director: 'Smurfs: The Lost Village, 2017 director',
    },
    '1271': {
        content: '',
        submitted_by: '',
        answers: ["Sucker Punch"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Fantasy, Thriller',
        release_year: '2011',
        notable_actor: '',
        director: 'Sucker Punch, 2011 director',
    },
    '1272': {
        content: '',
        submitted_by: '',
        answers: ["Demonic"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Horror, Science Fiction',
        release_year: '2021',
        notable_actor: '',
        director: 'Demonic, 2021 director',
    },
    '1273': {
        content: '',
        submitted_by: '',
        answers: ["Cirque du Freak: The Vampire's Assistant"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy, Action, Thriller',
        release_year: '2009',
        notable_actor: '',
        director: 'Cirque du Freak: The Vampire\'s Assistant, 2009 director',
    },
    '1274': {
        content: '',
        submitted_by: '',
        answers: ["Escape Room: Tournament of Champions"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller, Mystery',
        release_year: '2021',
        notable_actor: '',
        director: 'Escape Room: Tournament of Champions, 2021 director',
    },
    '1275': {
        content: '',
        submitted_by: '',
        answers: ["Spies in Disguise"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Action, Adventure, Comedy, Family',
        release_year: '2019',
        notable_actor: '',
        director: 'Spies in Disguise, 2019 director',
    },
    '1276': {
        content: '',
        submitted_by: '',
        answers: ["Hulk"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Adventure, Action',
        release_year: '2003',
        notable_actor: '',
        director: 'Hulk, 2003 director',
    },
    '1277': {
        content: '',
        submitted_by: '',
        answers: ["Candyman"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery, Thriller',
        release_year: '2021',
        notable_actor: '',
        director: 'Candyman, 2021 director',
    },
    '1278': {
        content: '',
        submitted_by: '',
        answers: ["The Visit"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Mystery, Thriller, Horror',
        release_year: '2015',
        notable_actor: '',
        director: 'The Visit, 2015 director',
    },
    '1279': {
        content: '',
        submitted_by: '',
        answers: ["Focus"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Romance, Comedy, Crime, Drama',
        release_year: '2015',
        notable_actor: '',
        director: 'Focus, 2015 director',
    },
    '1280': {
        content: '',
        submitted_by: '',
        answers: ["Hard Target 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller',
        release_year: '2016',
        notable_actor: '',
        director: 'Hard Target 2, 2016 director',
    },
    '1281': {
        content: '',
        submitted_by: '',
        answers: ["Awake"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Science Fiction, Thriller',
        release_year: '2021',
        notable_actor: '',
        director: 'Awake, 2021 director',
    },
    '1282': {
        content: '',
        submitted_by: '',
        answers: ["Ella Enchanted"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Fantasy, Comedy',
        release_year: '2004',
        notable_actor: '',
        director: 'Ella Enchanted, 2004 director',
    },
    '1283': {
        content: '',
        submitted_by: '',
        answers: ["The Da Vinci Code"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'tbd',
        genre: 'Thriller, Mystery',
        release_year: '2006',
        notable_actor: 'bsd',
        director: 'The Da Vinci Code, 2006 director',
    },
    '1284': {
        content: '',
        submitted_by: '',
        answers: ["A Clockwork Orange"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'tbd',
        genre: 'Science Fiction, Drama',
        release_year: '1971',
        notable_actor: 'tbd',
        director: 'A Clockwork Orange, 1971 director',
    },
    '1285': {
        content: '',
        submitted_by: '',
        answers: ["Tomb Raider"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'tbd',
        genre: 'Action, Adventure, Fantasy',
        release_year: '2018',
        notable_actor: 'tbd',
        director: 'Tomb Raider, 2018 director',
    },
    '1286': {
        content: '',
        submitted_by: '',
        answers: ["The Prot\u00e9g\u00e9"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller',
        release_year: '2021',
        notable_actor: '',
        director: 'The Protégé, 2021 director',
    },
    '1287': {
        content: '',
        submitted_by: '',
        answers: ["Charlie's Angels: Full Throttle"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Comedy',
        release_year: '2003',
        notable_actor: '',
        director: 'Charlie\'s Angels: Full Throttle, 2003 director',
    },
    '1288': {
        content: '',
        submitted_by: '',
        answers: ["Over the Hedge"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Comedy, Animation',
        release_year: '2006',
        notable_actor: '',
        director: 'Over the Hedge, 2006 director',
    },
    '1289': {
        content: '',
        submitted_by: '',
        answers: ["I See You"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller',
        release_year: '2019',
        notable_actor: '',
        director: 'I See You, 2019 director',
    },
    '1290': {
        content: '',
        submitted_by: '',
        answers: ["Sinister"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller, Mystery',
        release_year: '2012',
        notable_actor: '',
        director: 'Sinister, 2012 director',
    },
    '1291': {
        content: '',
        submitted_by: '',
        answers: ["The Prestige"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Mystery, Science Fiction',
        release_year: '2006',
        notable_actor: '',
        director: 'The Prestige, 2006 director',
    },
    '1292': {
        content: '',
        submitted_by: '',
        answers: ["His House"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2020',
        notable_actor: '',
        director: 'His House, 2020 director',
    },
    '1293': {
        content: '',
        submitted_by: '',
        answers: ["The Handmaiden"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Drama, Romance',
        release_year: '2016',
        notable_actor: '',
        director: 'The Handmaiden, 2016 director',
    },
    '1294': {
        content: '',
        submitted_by: '',
        answers: ["Horton Hears a Who!"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Family, Adventure, Fantasy',
        release_year: '2008',
        notable_actor: '',
        director: 'Horton Hears a Who!, 2008 director',
    },
    '1295': {
        content: '',
        submitted_by: '',
        answers: ["Cherry"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Crime',
        release_year: '2021',
        notable_actor: '',
        director: 'Cherry, 2021 director',
    },
    '1296': {
        content: '',
        submitted_by: '',
        answers: ["House of Wax"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2005',
        notable_actor: '',
        director: 'House of Wax, 2005 director',
    },
    '1297': {
        content: '',
        submitted_by: '',
        answers: ["Only the Brave"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Action, History',
        release_year: '2017',
        notable_actor: '',
        director: 'Only the Brave, 2017 director',
    },
    '1298': {
        content: '',
        submitted_by: '',
        answers: ["Dumb and Dumber"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'sdf',
        genre: 'Comedy',
        release_year: '1994',
        notable_actor: 'sdf',
        director: 'Dumb and Dumber, 1994 director',
    },
    '1299': {
        content: '',
        submitted_by: '',
        answers: ["Alone"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Horror',
        release_year: '2020',
        notable_actor: '',
        director: 'Alone, 2020 director',
    },
    '1300': {
        content: '',
        submitted_by: '',
        answers: ["Man on Fire"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Drama, Thriller',
        release_year: '2004',
        notable_actor: '',
        director: 'Man on Fire, 2004 director',
    },
    '1301': {
        content: '',
        submitted_by: '',
        answers: ["The Mechanic"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller, Crime',
        release_year: '2011',
        notable_actor: '',
        director: 'The Mechanic, 2011 director',
    },
    '1302': {
        content: '',
        submitted_by: '',
        answers: ["Pain & Gain"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Crime, Comedy',
        release_year: '2013',
        notable_actor: '',
        director: 'Pain & Gain, 2013 director',
    },
    '1303': {
        content: '',
        submitted_by: '',
        answers: ["Escape Room"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller, Mystery',
        release_year: '2019',
        notable_actor: '',
        director: 'Escape Room, 2019 director',
    },
    '1304': {
        content: '',
        submitted_by: '',
        answers: ["No Country for Old Men"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'dsf',
        genre: 'Crime, Drama, Thriller',
        release_year: '2007',
        notable_actor: 'sdf',
        director: 'No Country for Old Men, 2007 director',
    },
    '1305': {
        content: '',
        submitted_by: '',
        answers: ["Once Upon a Deadpool"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Action, Adventure',
        release_year: '2018',
        notable_actor: '',
        director: 'Once Upon a Deadpool, 2018 director',
    },
    '1306': {
        content: '',
        submitted_by: '',
        answers: ["Father Stu"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '2022',
        notable_actor: '',
        director: 'Father Stu, 2022 director',
    },
    '1307': {
        content: '',
        submitted_by: '',
        answers: ["Trollhunters: Rise of the Titans"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Fantasy, Family, Action, Adventure',
        release_year: '2021',
        notable_actor: '',
        director: 'Trollhunters: Rise of the Titans, 2021 director',
    },
    '1308': {
        content: '',
        submitted_by: '',
        answers: ["Halloween"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2018',
        notable_actor: '',
        director: 'Halloween, 2018 director',
    },
    '1309': {
        content: '',
        submitted_by: '',
        answers: ["The Girl Next Door"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Romance',
        release_year: '2004',
        notable_actor: '',
        director: 'The Girl Next Door, 2004 director',
    },
    '1310': {
        content: '',
        submitted_by: '',
        answers: ["King Kong"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy',
        release_year: '1976',
        notable_actor: '',
        director: 'King Kong, 1976 director',
    },
    '1311': {
        content: '',
        submitted_by: '',
        answers: ["The Smurfs 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Family, Comedy, Animation',
        release_year: '2013',
        notable_actor: '',
        director: 'The Smurfs 2, 2013 director',
    },
    '1312': {
        content: '',
        submitted_by: '',
        answers: ["Elite Squad"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Action, Crime',
        release_year: '2007',
        notable_actor: '',
        director: 'Elite Squad, 2007 director',
    },
    '1313': {
        content: '',
        submitted_by: '',
        answers: ["Machine Gun Preacher"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller, Crime',
        release_year: '2011',
        notable_actor: '',
        director: 'Machine Gun Preacher, 2011 director',
    },
    '1314': {
        content: '',
        submitted_by: '',
        answers: ["Jennifer's Body"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Comedy',
        release_year: '2009',
        notable_actor: '',
        director: 'Jennifer\'s Body, 2009 director',
    },
    '1315': {
        content: '',
        submitted_by: '',
        answers: ["Hardcore Henry"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '2015',
        notable_actor: '',
        director: 'Hardcore Henry, 2015 director',
    },
    '1316': {
        content: '',
        submitted_by: '',
        answers: ["Hitman's Wife's Bodyguard"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Comedy, Crime, Thriller',
        release_year: '2021',
        notable_actor: '',
        director: 'Hitman\'s Wife\'s Bodyguard, 2021 director',
    },
    '1317': {
        content: '',
        submitted_by: '',
        answers: ["Hummingbird"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller',
        release_year: '2013',
        notable_actor: '',
        director: 'Hummingbird, 2013 director',
    },
    '1318': {
        content: '',
        submitted_by: '',
        answers: ["Sicario: Day of the Soldado"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Crime, Drama, Thriller',
        release_year: '2018',
        notable_actor: '',
        director: 'Sicario: Day of the Soldado, 2018 director',
    },
    '1319': {
        content: '',
        submitted_by: '',
        answers: ["The Legend of Tarzan"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Action, Adventure',
        release_year: '2016',
        notable_actor: '',
        director: 'The Legend of Tarzan, 2016 director',
    },
    '1320': {
        content: '',
        submitted_by: '',
        answers: ["Batman Returns"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Fantasy',
        release_year: '1992',
        notable_actor: '',
        director: 'Batman Returns, 1992 director',
    },
    '1321': {
        content: '',
        submitted_by: '',
        answers: ["Edward Scissorhands"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Drama, Romance',
        release_year: '1990',
        notable_actor: '',
        director: 'Edward Scissorhands, 1990 director',
    },
    '1322': {
        content: '',
        submitted_by: '',
        answers: ["Never Back Down 2: The Beatdown"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Action',
        release_year: '2011',
        notable_actor: '',
        director: 'Never Back Down 2: The Beatdown, 2011 director',
    },
    '1323': {
        content: '',
        submitted_by: '',
        answers: ["The Social Network"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '2010',
        notable_actor: '',
        director: 'The Social Network, 2010 director',
    },
    '1324': {
        content: '',
        submitted_by: '',
        answers: ["Midway"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Drama, War, History, Adventure',
        release_year: '2019',
        notable_actor: '',
        director: 'Midway, 2019 director',
    },
    '1325': {
        content: '',
        submitted_by: '',
        answers: ["Pretty Woman"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Romance',
        release_year: '1990',
        notable_actor: '',
        director: 'Pretty Woman, 1990 director',
    },
    '1326': {
        content: '',
        submitted_by: '',
        answers: ["Bird Box"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Thriller, Drama, Science Fiction',
        release_year: '2018',
        notable_actor: '',
        director: 'Bird Box, 2018 director',
    },
    '1327': {
        content: '',
        submitted_by: '',
        answers: ["The Heat"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Comedy, Crime',
        release_year: '2013',
        notable_actor: '',
        director: 'The Heat, 2013 director',
    },
    '1328': {
        content: '',
        submitted_by: '',
        answers: ["King Arthur: Legend of the Sword"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Drama, Fantasy',
        release_year: '2017',
        notable_actor: '',
        director: 'King Arthur: Legend of the Sword, 2017 director',
    },
    '1329': {
        content: '',
        submitted_by: '',
        answers: ["Diary of a Wimpy Kid"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Family',
        release_year: '2021',
        notable_actor: '',
        director: 'Diary of a Wimpy Kid, 2021 director',
    },
    '1330': {
        content: '',
        submitted_by: '',
        answers: ["The Chronicles of Narnia: The Voyage of the Dawn Treader"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Family, Fantasy',
        release_year: '2010',
        notable_actor: '',
        director: 'The Chronicles of Narnia: The Voyage of the Dawn Treader, 2010 director',
    },
    '1331': {
        content: '',
        submitted_by: '',
        answers: ["War Room"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Romance',
        release_year: '2015',
        notable_actor: '',
        director: 'War Room, 2015 director',
    },
    '1332': {
        content: '',
        submitted_by: '',
        answers: ["Kingdom of Heaven"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Action, Adventure, History, War',
        release_year: '2005',
        notable_actor: '',
        director: 'Kingdom of Heaven, 2005 director',
    },
    '1333': {
        content: '',
        submitted_by: '',
        answers: ["The Prince of Egypt"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Animation, Drama, Family',
        release_year: '1998',
        notable_actor: '',
        director: 'The Prince of Egypt, 1998 director',
    },
    '1334': {
        content: '',
        submitted_by: '',
        answers: ["Sin City: A Dame to Kill For"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Crime, Action, Thriller',
        release_year: '2014',
        notable_actor: '',
        director: 'Sin City: A Dame to Kill For, 2014 director',
    },
    '1335': {
        content: '',
        submitted_by: '',
        answers: ["Underworld: Evolution"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Action, Thriller',
        release_year: '2006',
        notable_actor: '',
        director: 'Underworld: Evolution, 2006 director',
    },
    '1336': {
        content: '',
        submitted_by: '',
        answers: ["Marry Me"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Romance, Comedy, Music',
        release_year: '2022',
        notable_actor: '',
        director: 'Marry Me, 2022 director',
    },
    '1337': {
        content: '',
        submitted_by: '',
        answers: ["The Curious Case of Benjamin Button"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'tbd',
        genre: 'Drama, Fantasy, Romance',
        release_year: '2008',
        notable_actor: 'tbd',
        director: 'The Curious Case of Benjamin Button, 2008 director',
    },
    '1338': {
        content: '',
        submitted_by: '',
        answers: ["Don't Be a Menace to South Central While Drinking Your Juice in the Hood"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '1996',
        notable_actor: '',
        director: 'Don\'t Be a Menace to South Central While Drinking Your Juice in the Hood, 1996 director',
    },
    '1339': {
        content: '',
        submitted_by: '',
        answers: ["Underworld"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Fantasy, Action, Thriller',
        release_year: '2003',
        notable_actor: '',
        director: 'Underworld, 2003 director',
    },
    '1340': {
        content: '',
        submitted_by: '',
        answers: ["Scott Pilgrim vs. the World"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Comedy, Romance',
        release_year: '2010',
        notable_actor: '',
        director: 'Scott Pilgrim vs. the World, 2010 director',
    },
    '1341': {
        content: '',
        submitted_by: '',
        answers: ["Saw III"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller, Crime',
        release_year: '2006',
        notable_actor: '',
        director: 'Saw III, 2006 director',
    },
    '1342': {
        content: '',
        submitted_by: '',
        answers: ["Mortal Engines"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Science Fiction',
        release_year: '2018',
        notable_actor: '',
        director: 'Mortal Engines, 2018 director',
    },
    '1343': {
        content: '',
        submitted_by: '',
        answers: ["Life of Pi"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Drama',
        release_year: '2012',
        notable_actor: '',
        director: 'Life of Pi, 2012 director',
    },
    '1344': {
        content: '',
        submitted_by: '',
        answers: ["Armageddon Time"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama',
        release_year: '2022',
        notable_actor: '',
        director: 'Armageddon Time, 2022 director',
    },
    '1345': {
        content: '',
        submitted_by: '',
        answers: ["Her"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Romance, Science Fiction, Drama',
        release_year: '2013',
        notable_actor: '',
        director: 'Her, 2013 director',
    },
    '1346': {
        content: '',
        submitted_by: '',
        answers: ["Last Night in Soho"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery, Thriller',
        release_year: '2021',
        notable_actor: '',
        director: 'Last Night in Soho, 2021 director',
    },
    '1347': {
        content: '',
        submitted_by: '',
        answers: ["The Final Destination"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Mystery',
        release_year: '2009',
        notable_actor: '',
        director: 'The Final Destination, 2009 director',
    },
    '1348': {
        content: '',
        submitted_by: '',
        answers: ["Pitch Perfect"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Music, Romance',
        release_year: '2012',
        notable_actor: '',
        director: 'Pitch Perfect, 2012 director',
    },
    '1349': {
        content: '',
        submitted_by: '',
        answers: ["Silverado"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Western',
        release_year: '1985',
        notable_actor: '',
        director: 'Silverado, 1985 director',
    },
    '1350': {
        content: '',
        submitted_by: '',
        answers: ["Neon Genesis Evangelion: The End of Evangelion"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Science Fiction, Action',
        release_year: '1997',
        notable_actor: '',
        director: 'Neon Genesis Evangelion: The End of Evangelion, 1997 director',
    },
    '1351': {
        content: '',
        submitted_by: '',
        answers: ["Gunpowder Milkshake"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Crime, Thriller',
        release_year: '2021',
        notable_actor: '',
        director: 'Gunpowder Milkshake, 2021 director',
    },
    '1352': {
        content: '',
        submitted_by: '',
        answers: ["Inside Man"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Crime, Drama, Thriller',
        release_year: '2006',
        notable_actor: '',
        director: 'Inside Man, 2006 director',
    },
    '1353': {
        content: '',
        submitted_by: '',
        answers: ["Home on the Range"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family',
        release_year: '2004',
        notable_actor: '',
        director: 'Home on the Range, 2004 director',
    },
    '1354': {
        content: '',
        submitted_by: '',
        answers: ["The Goonies"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Comedy, Family',
        release_year: '1985',
        notable_actor: '',
        director: 'The Goonies, 1985 director',
    },
    '1355': {
        content: '',
        submitted_by: '',
        answers: ["From Russia with Love"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller, Adventure',
        release_year: '1963',
        notable_actor: '',
        director: 'From Russia with Love, 1963 director',
    },
    '1356': {
        content: '',
        submitted_by: '',
        answers: ["RED"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Comedy, Crime, Thriller',
        release_year: '2010',
        notable_actor: '',
        director: 'RED, 2010 director',
    },
    '1357': {
        content: '',
        submitted_by: '',
        answers: ["Puss in Book: Trapped in an Epic Tale"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Fantasy, Animation, Comedy, Adventure',
        release_year: '2017',
        notable_actor: '',
        director: 'Puss in Book: Trapped in an Epic Tale, 2017 director',
    },
    '1358': {
        content: '',
        submitted_by: '',
        answers: ["Cloudy with a Chance of Meatballs 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Comedy',
        release_year: '2013',
        notable_actor: '',
        director: 'Cloudy with a Chance of Meatballs 2, 2013 director',
    },
    '1359': {
        content: '',
        submitted_by: '',
        answers: ["Piranha 3D"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Horror',
        release_year: '2010',
        notable_actor: '',
        director: 'Piranha 3D, 2010 director',
    },
    '1360': {
        content: '',
        submitted_by: '',
        answers: ["Bloodshot"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Science Fiction, Adventure',
        release_year: '2020',
        notable_actor: '',
        director: 'Bloodshot, 2020 director',
    },
    '1361': {
        content: '',
        submitted_by: '',
        answers: ["Batman: Death in the Family"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Action',
        release_year: '2020',
        notable_actor: '',
        director: 'Batman: Death in the Family, 2020 director',
    },
    '1362': {
        content: '',
        submitted_by: '',
        answers: ["Blitz"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Crime, Action, Thriller',
        release_year: '2011',
        notable_actor: '',
        director: 'Blitz, 2011 director',
    },
    '1363': {
        content: '',
        submitted_by: '',
        answers: ["White House Down"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Drama, Thriller',
        release_year: '2013',
        notable_actor: '',
        director: 'White House Down, 2013 director',
    },
    '1364': {
        content: '',
        submitted_by: '',
        answers: ["Prisoners"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Thriller, Crime',
        release_year: '2013',
        notable_actor: '',
        director: 'Prisoners, 2013 director',
    },
    '1365': {
        content: '',
        submitted_by: '',
        answers: ["Interview with the Vampire"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Drama, Fantasy',
        release_year: '1994',
        notable_actor: '',
        director: 'Interview with the Vampire, 1994 director',
    },
    '1366': {
        content: '',
        submitted_by: '',
        answers: ["Phineas and Ferb: The Movie: Candace Against the Universe"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Animation, Science Fiction, Comedy, TV Movie',
        release_year: '2020',
        notable_actor: '',
        director: 'Phineas and Ferb: The Movie: Candace Against the Universe, 2020 director',
    },
    '1367': {
        content: '',
        submitted_by: '',
        answers: ["Akira"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Science Fiction, Action',
        release_year: '1988',
        notable_actor: '',
        director: 'Akira, 1988 director',
    },
    '1368': {
        content: '',
        submitted_by: '',
        answers: ["Damage"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Romance',
        release_year: '1992',
        notable_actor: '',
        director: 'Damage, 1992 director',
    },
    '1369': {
        content: '',
        submitted_by: '',
        answers: ["The Hunt"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller, Horror',
        release_year: '2020',
        notable_actor: '',
        director: 'The Hunt, 2020 director',
    },
    '1370': {
        content: '',
        submitted_by: '',
        answers: ["Stuart Little"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Fantasy, Comedy, Adventure',
        release_year: '1999',
        notable_actor: '',
        director: 'Stuart Little, 1999 director',
    },
    '1371': {
        content: '',
        submitted_by: '',
        answers: ["Point Break"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Crime, Thriller',
        release_year: '2015',
        notable_actor: '',
        director: 'Point Break, 2015 director',
    },
    '1372': {
        content: '',
        submitted_by: '',
        answers: ["The Craft: Legacy"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Drama, Fantasy',
        release_year: '2020',
        notable_actor: '',
        director: 'The Craft: Legacy, 2020 director',
    },
    '1373': {
        content: '',
        submitted_by: '',
        answers: ["Robots"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Family, Science Fiction',
        release_year: '2005',
        notable_actor: '',
        director: 'Robots, 2005 director',
    },
    '1374': {
        content: '',
        submitted_by: '',
        answers: ["Total Recall"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Science Fiction, Thriller',
        release_year: '2012',
        notable_actor: '',
        director: 'Total Recall, 2012 director',
    },
    '1375': {
        content: '',
        submitted_by: '',
        answers: ["District 9"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'sf',
        genre: 'Science Fiction',
        release_year: '2009',
        notable_actor: 'sdf',
        director: 'District 9, 2009 director',
    },
    '1376': {
        content: '',
        submitted_by: '',
        answers: ["The Princess Diaries 2: Royal Engagement"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Drama, Family, Romance',
        release_year: '2004',
        notable_actor: '',
        director: 'The Princess Diaries 2: Royal Engagement, 2004 director',
    },
    '1377': {
        content: '',
        submitted_by: '',
        answers: ["Believe Me: The Abduction of Lisa McVey"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, TV Movie',
        release_year: '2021',
        notable_actor: '',
        director: 'Believe Me: The Abduction of Lisa McVey, 2021 director',
    },
    '1378': {
        content: '',
        submitted_by: '',
        answers: ["National Treasure: Book of Secrets"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Mystery, Thriller',
        release_year: '2007',
        notable_actor: '',
        director: 'National Treasure: Book of Secrets, 2007 director',
    },
    '1379': {
        content: '',
        submitted_by: '',
        answers: ["The Wind Rises"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Animation, War, Romance',
        release_year: '2013',
        notable_actor: '',
        director: 'The Wind Rises, 2013 director',
    },
    '1380': {
        content: '',
        submitted_by: '',
        answers: ["Descendants 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Music, Fantasy, TV Movie, Adventure, Action, Romance',
        release_year: '2017',
        notable_actor: '',
        director: 'Descendants 2, 2017 director',
    },
    '1381': {
        content: '',
        submitted_by: '',
        answers: ["Ong Bak: Muay Thai Warrior"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller, Crime, Adventure',
        release_year: '2003',
        notable_actor: '',
        director: 'Ong Bak: Muay Thai Warrior, 2003 director',
    },
    '1382': {
        content: '',
        submitted_by: '',
        answers: ["The Silence"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Drama, Thriller, Fantasy',
        release_year: '2019',
        notable_actor: '',
        director: 'The Silence, 2019 director',
    },
    '1383': {
        content: '',
        submitted_by: '',
        answers: ["Once Upon a Time in America"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Crime',
        release_year: '1984',
        notable_actor: '',
        director: 'Once Upon a Time in America, 1984 director',
    },
    '1384': {
        content: '',
        submitted_by: '',
        answers: ["The Lego Batman Movie"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Action, Comedy, Family',
        release_year: '2017',
        notable_actor: '',
        director: 'The Lego Batman Movie, 2017 director',
    },
    '1385': {
        content: '',
        submitted_by: '',
        answers: ["Ri\u00a2hie Ri\u00a2h"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Family',
        release_year: '1994',
        notable_actor: '',
        director: 'Ri¢hie Ri¢h, 1994 director',
    },
    '1386': {
        content: '',
        submitted_by: '',
        answers: ["The Angry Birds Movie 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Comedy, Adventure, Family',
        release_year: '2019',
        notable_actor: '',
        director: 'The Angry Birds Movie 2, 2019 director',
    },
    '1387': {
        content: '',
        submitted_by: '',
        answers: ["You People"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Romance, Comedy',
        release_year: '2023',
        notable_actor: '',
        director: 'You People, 2023 director',
    },
    '1388': {
        content: '',
        submitted_by: '',
        answers: ["The Interview"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Comedy',
        release_year: '2014',
        notable_actor: '',
        director: 'The Interview, 2014 director',
    },
    '1389': {
        content: '',
        submitted_by: '',
        answers: ["A Dog's Purpose"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Comedy, Fantasy, Family, Drama',
        release_year: '2017',
        notable_actor: '',
        director: 'A Dog\'s Purpose, 2017 director',
    },
    '1390': {
        content: '',
        submitted_by: '',
        answers: ["Deep Impact"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Drama, Science Fiction',
        release_year: '1998',
        notable_actor: '',
        director: 'Deep Impact, 1998 director',
    },
    '1391': {
        content: '',
        submitted_by: '',
        answers: ["RED 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Comedy, Crime, Thriller',
        release_year: '2013',
        notable_actor: '',
        director: 'RED 2, 2013 director',
    },
    '1392': {
        content: '',
        submitted_by: '',
        answers: ["Infinity Pool"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Science Fiction, Thriller',
        release_year: '2023',
        notable_actor: '',
        director: 'Infinity Pool, 2023 director',
    },
    '1393': {
        content: '',
        submitted_by: '',
        answers: ["The Purge: Election Year"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Horror, Thriller',
        release_year: '2016',
        notable_actor: '',
        director: 'The Purge: Election Year, 2016 director',
    },
    '1394': {
        content: '',
        submitted_by: '',
        answers: ["The Grand Budapest Hotel"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Drama',
        release_year: '2014',
        notable_actor: '',
        director: 'The Grand Budapest Hotel, 2014 director',
    },
    '1395': {
        content: '',
        submitted_by: '',
        answers: ["Public Enemies"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Crime',
        release_year: '2009',
        notable_actor: '',
        director: 'Public Enemies, 2009 director',
    },
    '1396': {
        content: '',
        submitted_by: '',
        answers: ["Justice League: Doom"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Animation, Science Fiction',
        release_year: '2012',
        notable_actor: '',
        director: 'Justice League: Doom, 2012 director',
    },
    '1397': {
        content: '',
        submitted_by: '',
        answers: ["Goosebumps"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Horror, Comedy',
        release_year: '2015',
        notable_actor: '',
        director: 'Goosebumps, 2015 director',
    },
    '1398': {
        content: '',
        submitted_by: '',
        answers: ["Eyes Wide Shut"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Thriller, Mystery',
        release_year: '1999',
        notable_actor: '',
        director: 'Eyes Wide Shut, 1999 director',
    },
    '1399': {
        content: '',
        submitted_by: '',
        answers: ["The Hitman's Bodyguard"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Comedy',
        release_year: '2017',
        notable_actor: '',
        director: 'The Hitman\'s Bodyguard, 2017 director',
    },
    '1400': {
        content: '',
        submitted_by: '',
        answers: ["The Kissing Booth 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Romance',
        release_year: '2020',
        notable_actor: '',
        director: 'The Kissing Booth 2, 2020 director',
    },
    '1401': {
        content: '',
        submitted_by: '',
        answers: ["Clue"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Thriller, Crime, Mystery',
        release_year: '1985',
        notable_actor: '',
        director: 'Clue, 1985 director',
    },
    '1402': {
        content: '',
        submitted_by: '',
        answers: ["Woody Woodpecker"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Family, Animation',
        release_year: '2017',
        notable_actor: '',
        director: 'Woody Woodpecker, 2017 director',
    },
    '1403': {
        content: '',
        submitted_by: '',
        answers: ["Predestination"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Science Fiction, Thriller',
        release_year: '2014',
        notable_actor: '',
        director: 'Predestination, 2014 director',
    },
    '1404': {
        content: '',
        submitted_by: '',
        answers: ["Hot Girls Wanted"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Documentary',
        release_year: '2015',
        notable_actor: '',
        director: 'Hot Girls Wanted, 2015 director',
    },
    '1405': {
        content: '',
        submitted_by: '',
        answers: ["Cats & Dogs 3: Paws Unite"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, Comedy, Action',
        release_year: '2020',
        notable_actor: '',
        director: 'Cats & Dogs 3: Paws Unite, 2020 director',
    },
    '1406': {
        content: '',
        submitted_by: '',
        answers: ["The Loud House Movie"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Family, Comedy, Adventure, Fantasy',
        release_year: '2021',
        notable_actor: '',
        director: 'The Loud House Movie, 2021 director',
    },
    '1407': {
        content: '',
        submitted_by: '',
        answers: ["Dune"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Science Fiction, Adventure',
        release_year: '1984',
        notable_actor: '',
        director: 'Dune, 1984 director',
    },
    '1408': {
        content: '',
        submitted_by: '',
        answers: ["The Transporter"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'tbd',
        genre: 'Action, Crime, Thriller',
        release_year: '2002',
        notable_actor: 'tdbd',
        director: 'The Transporter, 2002 director',
    },
    '1409': {
        content: '',
        submitted_by: '',
        answers: ["Central Intelligence"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Comedy, Crime, Mystery',
        release_year: '2016',
        notable_actor: '',
        director: 'Central Intelligence, 2016 director',
    },
    '1410': {
        content: '',
        submitted_by: '',
        answers: ["School of Rock"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'tbd',
        genre: 'Comedy, Music, Family',
        release_year: '2003',
        notable_actor: 'tbd',
        director: 'School of Rock, 2003 director',
    },
    '1411': {
        content: '',
        submitted_by: '',
        answers: ["The NeverEnding Story"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy, Family, Drama',
        release_year: '1984',
        notable_actor: '',
        director: 'The NeverEnding Story, 1984 director',
    },
    '1412': {
        content: '',
        submitted_by: '',
        answers: ["CHiPS"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Comedy, Crime',
        release_year: '2017',
        notable_actor: '',
        director: 'CHiPS, 2017 director',
    },
    '1413': {
        content: '',
        submitted_by: '',
        answers: ["A Good Day to Die Hard"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Thriller',
        release_year: '2013',
        notable_actor: '',
        director: 'A Good Day to Die Hard, 2013 director',
    },
    '1414': {
        content: '',
        submitted_by: '',
        answers: ["Never Back Down"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Action',
        release_year: '2008',
        notable_actor: '',
        director: 'Never Back Down, 2008 director',
    },
    '1415': {
        content: '',
        submitted_by: '',
        answers: ["Paranormal Activity: The Marked Ones"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2014',
        notable_actor: '',
        director: 'Paranormal Activity: The Marked Ones, 2014 director',
    },
    '1416': {
        content: '',
        submitted_by: '',
        answers: ["National Treasure"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Action, Thriller, Mystery',
        release_year: '2004',
        notable_actor: '',
        director: 'National Treasure, 2004 director',
    },
    '1417': {
        content: '',
        submitted_by: '',
        answers: ["Enchanted"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Family, Fantasy, Romance',
        release_year: '2007',
        notable_actor: '',
        director: 'Enchanted, 2007 director',
    },
    '1418': {
        content: '',
        submitted_by: '',
        answers: ["Jiu Jitsu"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Science Fiction, Fantasy',
        release_year: '2020',
        notable_actor: '',
        director: 'Jiu Jitsu, 2020 director',
    },
    '1419': {
        content: '',
        submitted_by: '',
        answers: ["Space Sweepers"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Fantasy, Science Fiction',
        release_year: '2021',
        notable_actor: '',
        director: 'Space Sweepers, 2021 director',
    },
    '1420': {
        content: '',
        submitted_by: '',
        answers: ["Fear Street: 1978"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Mystery, Horror',
        release_year: '2021',
        notable_actor: '',
        director: 'Fear Street: 1978, 2021 director',
    },
    '1421': {
        content: '',
        submitted_by: '',
        answers: ["Jason Goes to Hell: The Final Friday"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror',
        release_year: '1993',
        notable_actor: '',
        director: 'Jason Goes to Hell: The Final Friday, 1993 director',
    },
    '1422': {
        content: '',
        submitted_by: '',
        answers: ["La La Land"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'tbd',
        genre: 'Comedy, Drama, Romance, Music',
        release_year: '2016',
        notable_actor: 'tbd',
        director: 'La La Land, 2016 director',
    },
    '1423': {
        content: '',
        submitted_by: '',
        answers: ["As Above, So Below"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2014',
        notable_actor: '',
        director: 'As Above, So Below, 2014 director',
    },
    '1424': {
        content: '',
        submitted_by: '',
        answers: ["A Walk in the Clouds"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Drama, Romance',
        release_year: '1995',
        notable_actor: '',
        director: 'A Walk in the Clouds, 1995 director',
    },
    '1425': {
        content: '',
        submitted_by: '',
        answers: ["Peninsula"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Adventure, Action, Thriller',
        release_year: '2020',
        notable_actor: '',
        director: 'Peninsula, 2020 director',
    },
    '1426': {
        content: '',
        submitted_by: '',
        answers: ["Conan the Barbarian"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Adventure, Fantasy, Action',
        release_year: '2011',
        notable_actor: '',
        director: 'Conan the Barbarian, 2011 director',
    },
    '1427': {
        content: '',
        submitted_by: '',
        answers: ["The Texas Chainsaw Massacre: The Beginning"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Horror, Thriller',
        release_year: '2006',
        notable_actor: '',
        director: 'The Texas Chainsaw Massacre: The Beginning, 2006 director',
    },
    '1428': {
        content: '',
        submitted_by: '',
        answers: ["Vacation Friends"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2021',
        notable_actor: '',
        director: 'Vacation Friends, 2021 director',
    },
    '1429': {
        content: '',
        submitted_by: '',
        answers: ["The Lion Guard: Return of the Roar"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Family, TV Movie, Animation',
        release_year: '2015',
        notable_actor: '',
        director: 'The Lion Guard: Return of the Roar, 2015 director',
    },
    '1430': {
        content: '',
        submitted_by: '',
        answers: ["We Bare Bears: The Movie"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Animation, Adventure, Family, Comedy, TV Movie',
        release_year: '2020',
        notable_actor: '',
        director: 'We Bare Bears: The Movie, 2020 director',
    },
    '1431': {
        content: '',
        submitted_by: '',
        answers: ["Teenage Mutant Ninja Turtles"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: 'dsf',
        genre: 'Science Fiction, Action, Adventure, Comedy, Family',
        release_year: '1990',
        notable_actor: 'sdf',
        director: 'Teenage Mutant Ninja Turtles, 1990 director',
    },
    '1432': {
        content: '',
        submitted_by: '',
        answers: ["Fantastic Four"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Action, Adventure, Science Fiction',
        release_year: '2015',
        notable_actor: '',
        director: 'Fantastic Four, 2015 director',
    },
    '1433': {
        content: '',
        submitted_by: '',
        answers: ["Mean Girls 2"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '2011',
        notable_actor: '',
        director: 'Mean Girls 2, 2011 director',
    },
    '1434': {
        content: '',
        submitted_by: '',
        answers: ["Airplane!"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy',
        release_year: '1980',
        notable_actor: '',
        director: 'Airplane!, 1980 director',
    },
    '1435': {
        content: '',
        submitted_by: '',
        answers: ["She's Out of My League"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Romance, Comedy',
        release_year: '2010',
        notable_actor: '',
        director: 'She\'s Out of My League, 2010 director',
    },
    '1436': {
        content: '',
        submitted_by: '',
        answers: ["You Don't Mess with the Zohan"],
        affiliate_links: [],
        franchise: 'None',
        metacritic_score: '',
        genre: 'Comedy, Action',
        release_year: '2008',
        notable_actor: '',
        director: 'You Don\'t Mess with the Zohan, 2008 director',
    },
};
