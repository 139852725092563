// @flow

import { useDispatch, useSelector } from 'react-redux';
import type { TDispatch } from '../store/store';

import React from 'react';
import { setModal } from '../store/slices/uxSlice';
import type { State } from '../store/types';
import { useNavigate } from 'react-router-dom';
import { useSettings } from '../settings/useSettings';

const HeaderRow = (): React$Element<'header'> => {
    const dispatch: TDispatch = useDispatch();
    const navigate = useNavigate();
    const userState = useSelector((state: State) => state.user);
    const settings = useSettings();

    return (
        <header className="Header">
            <h1 className={'Logo'}>
                <a className="logo-link" href={'/'}>
                    <span>
                        <img
                            src={`/img/${settings.website_url}.webp`}
                            style={{
                                height:
                                    settings.puzzle_type === 'gtm' || settings.puzzle_type === 'gtmc' ? '27px' : '20px',
                                width:
                                    settings.puzzle_type === 'gtm' || settings.puzzle_type === 'gtmc'
                                        ? '27px'
                                        : settings.puzzle_type === 'gtl'
                                        ? '20px'
                                        : '32px',
                            }}
                            alt={'logo'}
                        ></img>
                        &nbsp;
                    </span>
                    {settings.website_title}
                    <sup style={{ fontSize: '11px' }}>™</sup>
                </a>
            </h1>
            <button
                type="button"
                className="love-btn"
                onClick={() => {
                    dispatch(setModal('love'));
                }}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                    ></path>
                </svg>
            </button>
            <button
                type="button"
                className="stats-btn"
                onClick={() => {
                    dispatch(setModal('stats'));
                }}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
                    ></path>
                </svg>
            </button>
            <button
                type="button"
                className="about-btn"
                onClick={() => {
                    dispatch(setModal('about'));
                }}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                </svg>
            </button>
            <button
                type="button"
                className="how-to-play-btn"
                onClick={() => {
                    dispatch(setModal('howtoplay'));
                }}
            >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                </svg>
            </button>
            <button
                type="button"
                className="setting-btn"
                onClick={() => {
                    navigate(settings.paths.setting);
                }}
            >
                {/* check if userState.user , if so, show loggedin icon, else show loggedout icon */}
                {userState.user ? (
                    <img
                        src={'/img/settings_logged_in.svg'}
                        alt={'settings'}
                        height={27}
                        width={24}
                        style={{ opacity: '0.8', color: '#fff' }}
                    ></img>
                ) : (
                    <img
                        src={'/img/settings_logged_out.svg'}
                        alt={'settings'}
                        height={27}
                        width={24}
                        style={{ opacity: '0.8', fill: 'red' }}
                    ></img>
                )}
            </button>
        </header>
    );
};

export default HeaderRow;
